import { sortByString, ViewModelBase } from "@shoothill/core";
import { UserModel } from "Views/Admin/Users/UserModel";
import { action, computed, makeObservable, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { ICommandAsync, RelayCommandAsync, APIClient, ICommand, RelayCommand } from "../../../Application";
import { GetAllUsersEndpoint } from "./Endpoints/GetlAllUsersEndpoint";
import { Logger } from "../../../index";
import { DeleteUserEndpoint } from "./Endpoints/DeleteUserEndpoint";
import { UnlockUserEndpoint } from "./Endpoints/UnlockUserEndpoint";

export default class UserViewModel extends ViewModelBase<UserModel> {
    public users: UserModel[] = [];
    public apiClient = new APIClient();

    public userToDelete: UserModel | null = null;
    public userToUnlock: UserModel | null = null;
    public showDialog: boolean = false;
    public showUnlockDialog: boolean = false;
    public showLockDialog: boolean = false;
    public userCount: number = 0;
    public ascOrder = true;
    public resetLoginAttemptsError = "";
    public isTableLoading: boolean = false;
    public selectedRows: string[] = [];

    constructor() {
        super(new UserModel(null));
        makeObservable(this, {
            userCount: observable,
            users: observable,
            ascOrder: observable,
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            showDialog: observable,
            showUnlockDialog: observable,
            showLockDialog: observable,
            set: action,
            loadUsersAsync: action,
            getUsers: computed,
            setIsTableLoading: action,
            getUserCount: computed,
            setOrderAsc: action,
            getOrderAsc: action,
            resetFailedLoginAttempts: action,
            getUserName: action,
        });
        let _ = this.loadUsersAsync();
    }

    public copyEmailToClipboardCommand: ICommand = new RelayCommand((userModel: UserModel) => {
        console.log("TODO");
    });

    public newUserCommand: ICommand = new RelayCommand(() => {
        this.history.push(`/admin/userslist/user/new`);
    });

    public editUserCommand: ICommand = new RelayCommand((userModel: UserModel) => {
        this.history.push(`/admin/userslist/user/${userModel.id}`);
    });

    public deleteUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (userModel: UserModel) => {
        this.userToDelete = userModel;
        this.showDialog = true;
    });

    public hideDialogCommand: ICommand = new RelayCommand(async () => {
        this.showDialog = false;
    });

    public deleteUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        //TODO: Confirm Delete
        await this.apiClient.sendAsync(new DeleteUserEndpoint(this.userToDelete!.id));
        if (this.apiClient.IsRequestSuccessful) {
            // CoreStoreInstance.ShowInfoBar("User was successfully deleted", "success");
            // this.userToDelete!.setValue("isDeleted", true);
            this.hideDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToDelete = null;
        }
    });

    public unlockUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (userModel: UserModel) => {
        this.userToUnlock = userModel;
        this.showUnlockDialog = true;
    });

    public lockUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (userModel: UserModel) => {
        this.userToUnlock = userModel;
        this.showLockDialog = true;
    });

    public hideUnlockDialogCommand: ICommand = new RelayCommand(async () => {
        this.showUnlockDialog = false;
    });

    public hideLockDialogCommand: ICommand = new RelayCommand(async () => {
        this.showLockDialog = false;
    });

    public getUserName = (id: string | null) => {
        if (id) {
            const userDetails = this.users.find((i) => i.id == id);
            const userName = userDetails ? userDetails.firstName + " " + userDetails.lastName : "";
            return userName;
        } else {
            return "-";
        }
    };

    public unlockUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new UnlockUserEndpoint(this.userToUnlock!.id, false));
        if (this.apiClient.IsRequestSuccessful) {
            this.hideUnlockDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToUnlock = null;
        }
    });

    public lockUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new UnlockUserEndpoint(this.userToUnlock!.id, true));
        if (this.apiClient.IsRequestSuccessful) {
            this.hideLockDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToUnlock = null;
        }
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public async loadUsersAsync(): Promise<void> {
        const getAllUsersEndpoint = new GetAllUsersEndpoint(this);
        await this.apiClient.sendAsync(getAllUsersEndpoint);
        Logger.logDebug("loadUsersAsync: ", this.apiClient.Response<[]>());
    }

    public setUsers = (users: any) => {
        this.users = users;
    };

    public async resetFailedLoginAttempts(): Promise<void> {
        await this.apiClient.command.Post(
            AppUrls.Server.Admin.ResetFailedLoginAttemptsCount,
            {
                id: this.getValue("id"),
            },
            async (response) => {
                this.resetLoginAttemptsError = "";
            },
            () => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            },
        );
    }

    get getUsers(): UserModel[] {
        this.users.slice().sort((a: UserModel, b: UserModel) => {
            if (this.ascOrder) {
                return sortByString(a.firstName, b.firstName);
            } else {
                return sortByString(b.firstName, a.firstName);
            }
        });

        return this.users.filter((a) => !a.isDeleted && a.isAdminUser);
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    public getUser = (id: Guid) => {
        if (id) {
            return this.users.find((u) => u.id === id);
        } else {
            return new UserModel(null);
        }
    };

    get getUserCount(): number {
        return this.userCount;
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    public getOrderAsc(): boolean {
        return this.ascOrder;
    }
}
