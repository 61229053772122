import * as React from "react";
import { SVGProps } from "react";

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16.254" height="16.254" viewBox="0 0 16.254 16.254" {...props}>
        <g id="pencil" transform="translate(-1133.358 -345.437)">
            <g id="Path_6889" data-name="Path 6889" transform="translate(1146.372 345.437) rotate(45)" fill="none">
                <path d="M0,0H4.582V15.274l-2.2,3.13L0,15.274Z" stroke="none" />
                <path
                    d="M 3.582273244857788 0.9999542236328125 L 1.000153303146362 1.000022888183594 L 0.9997882843017578 14.93709850311279 L 2.346799850463867 16.71046447753906 L 3.581907749176025 14.95686054229736 L 3.582273244857788 0.9999542236328125 M 4.58229923248291 -7.05718994140625e-05 L 4.581899642944336 15.27368831634521 L 2.377269506454468 18.40381813049316 L -0.0002202987670898438 15.27380847930908 L 0.000179290771484375 4.76837158203125e-05 L 4.58229923248291 -7.05718994140625e-05 Z"
                    stroke="none"
                    fill="#6a6a6a"
                />
            </g>
            <g id="Path_6890" data-name="Path 6890" transform="translate(1146.372 345.437) rotate(45)" fill="#6a6a6a">
                <path
                    d="M 0.4997928738594055 14.41931915283203 L 0.5001660585403442 0.500039279460907 L 4.08228588104248 0.4999454915523529 L 4.081912994384766 14.41922569274902 L 0.4997928738594055 14.41931915283203 Z"
                    stroke="none"
                />
                <path
                    d="M 3.582272529602051 0.9999589920043945 L 1.000152587890625 1.000025749206543 L 0.9998061656951904 13.9193058013916 L 3.581926345825195 13.91923904418945 L 3.582272529602051 0.9999589920043945 M 4.58229923248291 -6.771087646484375e-05 L 4.581899642944336 14.91921234130859 L -0.0002202987670898438 14.91933250427246 L 0.000179290771484375 5.245208740234375e-05 L 4.58229923248291 -6.771087646484375e-05 Z"
                    stroke="none"
                    fill="#6a6a6a"
                />
            </g>
        </g>
    </svg>
);
