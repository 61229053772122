import * as MobX from "mobx";

import { CoreStoreInstance, FieldType, sortByString, ViewModelBase } from "@shoothill/core";
import { RoleModel, UserModel } from "../../../Application/Models";
import { action, computed, makeObservable, observable } from "mobx";

import { RoleStore } from "Stores/Domain/Admin";
import { container } from "tsyringe";
import { RelayCommandAsync, ICommandAsync, ICommand, RelayCommand } from "Application/Commands";
import { APIClient, IKeyState } from "../../../Application";
import { DeleteUserEndpoint } from "../Users/Endpoints/DeleteUserEndpoint";
import { DeleteRoleEndpoint } from "./Endpoints/DeleteRoleEndpoint";

export default class RoleViewModel extends ViewModelBase<RoleModel> {
    private roleStore = container.resolve(RoleStore);

    public roleToDelete: RoleModel = {} as RoleModel;
    public apiClient = new APIClient();
    public selectedRows: string[] = [];
    public roleCount: number = 0;
    public ascOrder: boolean = true;
    public showDialog: boolean = false;
    //public Valid: boolean = false;

    constructor() {
        super(new RoleModel(""));
        makeObservable(this, {
            roleCount: observable,
            ascOrder: observable,
            set: action,
            getRoleCount: computed,
            setOrderAsc: action,
            getOrderAsc: computed,
            showDialog: observable,
        });
        this.Roles;
    }

    public editRoleCommand: ICommand = new RelayCommand((roleModel: RoleModel) => {
        this.history.push(`/admin/roleslist/role/${roleModel.id}`);
    });

    public newRoleCommand: ICommand = new RelayCommand(() => {
        this.history.push("/admin/roleslist/role/new");
    });

    public deleteUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (item: RoleModel) => {
        this.roleToDelete = item;
        this.showDialog = true;
    });

    public hideDialogCommand: ICommand = new RelayCommand(async () => {
        this.showDialog = false;
    });

    public deleteRoleCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new DeleteRoleEndpoint(this.roleToDelete.id));
        if (this.apiClient.IsRequestSuccessful) {
            CoreStoreInstance.ShowInfoBar("Role was successfully deleted", "success");
            this.roleToDelete.setValue("isDeleted", true);
            this.hideDialogCommand.execute();
        }
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public get Roles() {
        return this.roleStore.getRoles;
    }

    get getRoleCount(): number {
        return this.roleCount;
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    get getOrderAsc(): boolean {
        return this.ascOrder;
    }
}
