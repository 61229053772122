import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../../AppUrls";

export class Response {}

export class DeleteUserEndpoint extends EndpointWithoutRequest<Response> {
    private userId: Guid | null;

    constructor(userId: Guid | null) {
        super();
        this.userId = userId;
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Account.User.DeleteUser + "/" + userId);
    }

    public async HandleResponseAsync(response: Response[]): Promise<void> {}
}
