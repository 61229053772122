import { Box } from "./Box";
import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../Styles";
import { ICommand } from "../../Application";

const StyledClearText = styled(Box)`
    /*align-self: flex-start;*/ //EN: Probably need this for text areas
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    ::before {
        content: "\\2715";
    }
    &:hover {
        color: ${theme.palette.secondary.main};
    }
`;
interface Props {
    command: ICommand;
}
export const ClearText: React.FC<Props> = (props: Props) => {
    return <StyledClearText onClick={() => props.command.execute()} />;
};
