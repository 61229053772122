import { makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { LoginModel, LoginModelValidator } from "./LoginModel";
import { ICommand, RelayCommand } from "../../../Application/Commands";
import { APIClient } from "../../../Application";
import { AccountStore } from "../../../Stores/Domain";
import { singleton, container } from "tsyringe";
import { POSTLoginEndpoint } from "./POSTLoginEndpoint";
import { AppUrls } from "../../../AppUrls";

@singleton()
export class LoginViewModel extends ViewModelBase<LoginModel> {
    public apiClient = new APIClient();
    public postLoginEndpoint = new POSTLoginEndpoint(this);
    public signInViewActive: boolean = true;
    public signUpViewActive: boolean = false;

    constructor() {
        super(new LoginModel());

        this.setValidator(new LoginModelValidator());

        makeObservable(this, {
            signInViewActive: observable,
            signUpViewActive: observable,
        });
    }

    public accountStore = container.resolve(AccountStore);

    public updateEmailCommand: ICommand = new RelayCommand((value: string) => this.updateField("emailAddress", value));

    public updatePasswordCommand: ICommand = new RelayCommand((value: string) => this.updateField("password", value));

    public rememberMeCommand: ICommand = new RelayCommand((value: string) => this.updateField("rememberMe", value));

    public loginCommand: ICommand = new RelayCommand(async () => {
        if (this.isModelValid()) {
            await this.apiClient.sendAsync(this.postLoginEndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                if (this.accountStore.IsLoggedIn && this.accountStore.CoinsPlotId && this.accountStore.CoinsDevelopmentId) {
                    this.history.push(AppUrls.Client.Home);
                }
                if (this.accountStore.IsLoggedIn && !this.accountStore.CoinsPlotId && !this.accountStore.CoinsDevelopmentId) {
                    this.history.push(AppUrls.Client.Admin.AdminHome);
                }
            } else {
                // this.accountStore.loginValidationMessage = "Invalid email or password";
                // this.apiClient.ValidationMessage;
            }
        }
    });

    public get setLoginValidationMessage() {
        return this.accountStore.loginValidationMessage ? this.accountStore.loginValidationMessage : "";
    }

    private updateField = (fieldName: keyof FieldType<LoginModel>, value: any) => {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    };
}
