import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, setPageTitle, Label } from "Application";
import { ChoicesViewModel } from "./ChoicesViewModel";
import { CoinsPageContainer, CoinsPageContainerWithTabs, Paragraph } from "../../Styles/BaseStyles";
import styled from "@emotion/styled";
import { ChoiceView } from "./Choice/ChoiceView";
import { uniqueId } from "lodash-es";
import { Route, Routes } from "react-router-dom";
import { UserDetails } from "../Admin/Users/UserDetail/UserDetails";

interface TabButtonProps {
    active: boolean;
}
const TabButton = styled(Box)<TabButtonProps>`
    display: flex;
    width: 200px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${(props) => (props.active ? "#806302" : "#F47E44")};
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
`;

const MobileTabButton = styled(TabButton)`
    width: 135px;
    height: 36px;
`;

export const ChoicesView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ChoicesViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Choices");
    }, []);

    const tabs = () => {
        return (
            <Box display={"flex"}>
                <TabButton active={viewModel.choicesAndExtrasActive} onClick={() => viewModel.setChoicesAndExtrasActive(true)}>
                    <Label color={"white"} fontStyle={"h3"}>
                        Choices & Extras
                    </Label>
                </TabButton>
                <TabButton active={viewModel.selectedChoiceExtrasActive} onClick={() => viewModel.setSelectedChoiceExtrasActive(true)}>
                    <Label color={"white"} fontStyle={"h3"}>
                        Selected Choices
                    </Label>
                </TabButton>
            </Box>
        );
    };

    const tabsMobile = () => {
        return (
            <Box display={"flex"}>
                <MobileTabButton active={viewModel.choicesAndExtrasActive} onClick={() => viewModel.setChoicesAndExtrasActive(true)}>
                    <Label color={"white"} fontStyle={"h3"} style={{ fontSize: "12px" }}>
                        Choices & Extras
                    </Label>
                </MobileTabButton>
                <MobileTabButton active={viewModel.selectedChoiceExtrasActive} onClick={() => viewModel.setSelectedChoiceExtrasActive(true)}>
                    <Label color={"white"} fontStyle={"h3"} style={{ fontSize: "12px" }}>
                        Selected Choices
                    </Label>
                </MobileTabButton>
            </Box>
        );
    };

    const index = (
        <>
            <CoinsPageContainerWithTabs title={"Choices & Extras"} tabs={tabs()} ml={"30px"} mr={"20px"}>
                <Box showIf={viewModel.choicesAndExtrasActive} style={{ width: "100%" }}>
                    <Paragraph>Choose how you want your house to look.</Paragraph>
                    <Paragraph>Select an area of the house to select your options and choose your upgrades. Review selected choices and edit if required.</Paragraph>
                </Box>
                <Box showIf={viewModel.selectedChoiceExtrasActive}>Selected Choices</Box>
            </CoinsPageContainerWithTabs>
            <Box
                showIf={viewModel.choicesAndExtrasActive}
                grid
                dc={"1fr 1fr 1fr 1fr"}
                style={{ width: "100%", maxWidth: "1316px", gap: "20px", paddingBottom: "30px" }}
                mt={"20px"}
                ml={"30px"}
                mr={"30px"}
            >
                {viewModel.choiceViewModels.map((viewModel) => (
                    <ChoiceView key={uniqueId()} viewModel={viewModel} />
                ))}
            </Box>
            <Box showIf={viewModel.selectedChoiceExtrasActive}>Selected Choices</Box>
        </>
    );

    const indexMobile = (
        <>
            <CoinsPageContainerWithTabs title={"Choices & Extras"} tabs={tabsMobile()} ml={"10px"} mr={"10px"}>
                <Box showIf={viewModel.choicesAndExtrasActive} style={{ width: "100%", paddingBottom: "30px" }} mt={"22px"} ml={"10px"} mr={"10px"}>
                    <p style={{ padding: "0", fontSize: "11px", marginBottom: "10px" }}>Choose how you want your house to look.</p>
                    <p style={{ padding: "0", fontSize: "11px" }}>
                        Select an area of the house to select your options and choose your upgrades. Review selected choices and edit if required.
                    </p>
                </Box>
                <Box showIf={viewModel.selectedChoiceExtrasActive}>Selected Choices</Box>
            </CoinsPageContainerWithTabs>
            <Box
                showIf={viewModel.choicesAndExtrasActive}
                grid
                dc={"1fr 1fr"}
                style={{ width: "100%", gap: "5px", paddingBottom: "30px", maxWidth: "93vw" }}
                mt={"20px"}
                ml={"10px"}
                mr={"15px"}
            >
                {viewModel.choiceViewModels.map((viewModel) => (
                    <ChoiceView key={uniqueId()} viewModel={viewModel} />
                ))}
            </Box>
            <Box showIf={viewModel.selectedChoiceExtrasActive}>Selected Choices</Box>
        </>
    );

    return (
        <>
            <div style={{ marginTop: viewModel.isMobile ? "20px" : "30px" }}>
                <Routes>
                    <Route index element={viewModel.isMobile ? indexMobile : index} />
                    {viewModel.choiceViewModels.map((viewModel) => (
                        <Route key={uniqueId()} path={viewModel.getValue("path") + "/:selection"} element={<ChoiceView key={uniqueId()} viewModel={viewModel} />} />
                    ))}
                    {/*<Route path={"sittingroom"} element={<UserDetails />} />*/}
                </Routes>
            </div>
        </>
    );
});
