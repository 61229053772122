import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { YourhomeModel, YourhomeModelValidator } from "./YourhomeModel";
import { Logger } from "index";
import { GETPlotDetailEndpoint } from "./Endpoints/GETPlotDetailEndpoint";
import { AccountStore } from "../../Stores/Domain";
import { container } from "tsyringe";

export class YourhomeViewModel extends ViewModelBase<YourhomeModel> {
    public apiClient = new APIClient();
    private accountStore = container.resolve(AccountStore);

    constructor(model = new YourhomeModel()) {
        super(model);
        this.setValidator(new YourhomeModelValidator());
        makeObservable(this, {});
        this.apiClient.sendAsync(new GETPlotDetailEndpoint(this, this.accountStore.CoinsPlotId!), this.model);
    }

    public getNonCashIncentives = () => {
        let incentives = "";
        if (this.model.nonCashIncentivesDescription !== null && this.model.nonCashIncentivesDescription !== "") {
            incentives = "Description: " + this.model.nonCashIncentivesDescription;
        }
        if (this.model.nonCashIncentivesQuantity !== null && this.model.nonCashIncentivesQuantity !== "") {
            incentives += " Qty: " + this.model.nonCashIncentivesQuantity;
        }
        return incentives;
    };

    //#region Update Commands

    //#endregion Commands
}
