import { EndpointWithoutRequest, Http } from "Application";
import { UserModel } from "../../UserModel";
import { Moment } from "moment";
import PotalUsersViewModel from "../../PortalUsers/PortalUsersViewModel";

export class Response {
    public id: Guid | null = null;
    public lastName: string = "";
    public email: string = "";
    public password: string = "";
    public role: string = "";
    public concurrencyToken: string = "";
    public coinsDevelopmentId: string | null = null;
    public coinsPlotId: string | null = null;
    public isDeleted: boolean = false;
    public lastLoginDate: Moment | null = null;
    public userRoles = [];
    public isAdminUser: boolean = false;
    public accessFailedCount: number = 0;
    public createdBy: string | null = null;
}

export class GetAllUsersEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PotalUsersViewModel;

    constructor(viewModel: PotalUsersViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path("/api/user/getall");
    }

    public async HandleResponseAsync(response: Response[]): Promise<UserModel[]> {
        let users: any = [];
        response.forEach((user, index) => {
            users.push(user);
        });
        this.viewModel.setUsers(users);
        return users;
    }
}
