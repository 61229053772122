import { observable, action, computed, makeObservable } from "mobx";
import { ICommand } from "./ICommand";

export class NullCommand implements ICommand {
    private readonly _execute: (params?: any) => any;
    private readonly _canExecute?: (params?: any) => boolean;

    constructor() {
        this._execute = () => console.log("Dummy command execute");
        this._canExecute = () => true;
        makeObservable<NullCommand, "_execute" | "_canExecute">(this, {
            _execute: observable,
            _canExecute: observable,
        });
    }

    execute = (...params: any[]): any => {
        const canExecute = this.canExecute(...params);
        if (canExecute) return this._execute(...params);

        return false;
    };

    canExecute = (...params: any[]): boolean => {
        if (this._canExecute) {
            return this._canExecute(...params);
        }

        return true;
    };
}
