import styled from "@emotion/styled";
import { Box } from "./General";
import { theme } from "../Styles";
import React from "react";
import { container } from "tsyringe";
import { DomainStore } from "../Stores/Domain/DomainStore";
import { Label, RelayCommand } from "../Application";
import { getHistory, isNullOrEmpty } from "@shoothill/core";
import { RightChevronSVG } from "../Assets/Icons/RightChevronSVG";
import { RightBeak } from "../Styles/BaseStyles";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const StyledBlock = styled(Box)`
    display: flex;
    transition: all 0.3s ease;
    //margin: 0 50px 0 50px;
    background-color: #4a4a4a;
    gap: 0;
    position: relative;
    cursor: pointer;
`;
const IconContainer = styled(Box)`
    margin-top: 0;
    margin-left: -35px;
    display: flex;
    align-items: center;
`;
interface BlockProps {
    icon?: JSX.Element;
    title: string;
    image?: string;
    description?: string;
    subTitle?: string;
    lastUpdated?: string;
    path: string;
    applyTransform?: boolean;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
}
export const HorizontalBlock: React.FC<BlockProps> = (props) => {
    const domainStore = container.resolve(DomainStore);
    const isMobile = useMediaQuery("(max-width: 767px)");
    const getStyles = () => {
        if (props.applyTransform) {
            return { transform: "scale(1)", transformOrigin: "10px 8px" };
        } else {
            return {};
        }
    };
    const gotoPageCommand = new RelayCommand(() => {
        domainStore.setSideBarClosed(false);
        getHistory().push(props.path);
    });
    return (
        <StyledBlock id={"block"} onClick={gotoPageCommand.execute} style={props.style!}>
            <img src={props.image} className="img-fluid" alt="image" style={{ width: "120px", height: "88px", objectFit: "cover", background: "#eee" }} />
            <Box flexBox style={{ gap: 0 }}>
                {props.icon && <IconContainer style={getStyles()}>{props.icon}</IconContainer>}
                <Box flexBox flexDirection={"column"} ml={"8px"} justifyContent={"center"} style={{ gap: 0 }}>
                    <Label
                        fontStyle={"h4"}
                        style={{
                            fontWeight: "bold",
                            fontSize: isMobile ? "22px" : "24px",
                            lineHeight: "24px",
                            marginTop: "0",
                            letterSpacing: "0px",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        {props.title}
                    </Label>
                    {/* <Label
                        fontStyle={"h4"}
                        style={{ letterSpacing: "0px", marginTop: "1px", color: "#FFFFFF", textTransform: "uppercase", fontSize: "12px", lineHeight: "18px", fontWeight: 300 }}
                    >
                        {props.subTitle}
                    </Label> */}
                    {!isNullOrEmpty(props.subTitle) && (
                        <Label
                            fontStyle={"h6"}
                            style={{ letterSpacing: "0px", fontSize: "12px", lineHeight: "14px", color: "#FFFFFF", textTransform: "uppercase", margin: "4px 0 0" }}
                        >
                            {props.subTitle}
                        </Label>
                    )}
                    <Box showIf={props.view !== "homeView"} mr={3}>
                        <Label style={{ color: "#fff", fontWeight: "400", fontSize: "12px", lineHeight: "14px" }} fontStyle={"h5"}>
                            {props.description}
                        </Label>
                        {!isNullOrEmpty(props.lastUpdated) && (
                            <Label fontStyle={"h6"} color={"blue"}>
                                {props.lastUpdated == "--" ? props.lastUpdated : `Last updated ${props.lastUpdated}`}
                            </Label>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* <Box style={{ position: "absolute", right: 0 }}>
                <RightChevronSVG style={{ position: "absolute", right: "-23px", zIndex: 2, top: "2px", transform: "scale(1.1)" }} />
                <RightBeak style={{ position: "absolute" }} size={"22px"} />
            </Box> */}
        </StyledBlock>
    );
};
HorizontalBlock.defaultProps = {
    applyTransform: true,
    view: "panelView",
};
