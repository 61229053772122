import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { DashboardViewModel } from "../DashboardViewModel";
import { runInAction } from "mobx";
import { container } from "tsyringe";

class Response {
    public coinsPlotId: string | null = "";
    public developmentName: string | null = "";
    public houseTypeName: string | null = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    public firstName: string | null = "";
    public lastName: string | null = "";
    public buildCompletedCount: number | null = 0;
    public buildOverallCount: number | null = 0;
    public salesCompletedCount: number | null = 0;
    public salesOverallCount: number | null = 0;
    public documentCount: number | null = 0;
    public snagsCount: number | null = 0;
    public reportedSnagsCount: number | null = 0;
}

export class GETDashboardEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: DashboardViewModel;
    constructor(viewModel: DashboardViewModel, coinsPlotId: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/dashboard/getdashboard/" + coinsPlotId);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.fromResponse(response);
        });
        //Return your model
        return {};
    }
}
