import dotenv from "dotenv";
import React, { useEffect, useState } from "react";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import "reflect-metadata";
import { App } from "./App";
import { CoreStoreInstance, getBaseUrl, createLogger, LoggerType, LogLevel } from "@shoothill/core";
import { AppErrorBoundary } from "AppErrorBoundary";
import * as MobX from "mobx";
import * as History from "history";
import { Startup } from "./Startup";
import { config } from "./config";

dotenv.config();

MobX.configure({ enforceActions: "observed" });

const baseUrl: string = getBaseUrl();
export const GlobalHistory: History.History = History.createBrowserHistory({
    //basename: baseUrl,
});

export const Logger = createLogger(LoggerType.Console);
//(window as any).GlobalHistory = GlobalHistory;

GlobalHistory.listen((location: any) => {
    if (CoreStoreInstance) {
        CoreStoreInstance.PageView(location.pathname);
    }

    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === "POP") {
            return;
        }
        //Hide info bar when we are navigating between pages
        CoreStoreInstance.HideInfoBar();
        // In all other cases, check fragment/scroll to top
        let hash = window.location.hash;
        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
});

export const Init: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    /*const logLevelQuery = new URLSearchParams(search).get("debug");*/
    if (queryParams.get("debug")) {
        config.logLevel = LogLevel.Debug;
    }
    Logger.logLevel = config.logLevel;
    Logger.logInformation("Starting up", Logger.logLevel);

    useEffect(() => {
        //let _ = viewModel.getInitialState();
        const startup = new Startup();
        const doAsync = async () => {
            await startup.init();
            setIsLoading(false);
        };
        const _ = doAsync();
    }, []);

    const getRender = () => {
        if (isLoading) {
            //Display loading screen so all the stores, globals etc are ready before loading the main app
            return <ThemedLoader delayBeforeOpen={0} isOpen={true} />;
        } else {
            return (
                <AppErrorBoundary>
                    <ThemedLoader delayBeforeOpen={0} isOpen={isLoading} />
                    <App />
                </AppErrorBoundary>
            );
        }
    };

    return getRender();
};

//ReactDOM.render(<Init />, document.getElementById("root"));
const container: HTMLElement = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<Init />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { ThemedLoader } from "./Styles/ThemedPrimitives/Style1/ThemedLoader";
