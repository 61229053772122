import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { AppUrls } from "../AppUrls";
import { Outlet, useLocation } from "react-router";

// @ts-ignore
interface Props extends RouteProps {
    isAllowed: () => boolean;
    redirectPath?: string;
    children?: any;
}

// https://tylermcginnis.com/react-router-protected-routes-authentication/

// const PrivateRoute = (props: Props) => {
export const PrivateRoute: React.FC<Props> = (props) => {
    let location = useLocation();

    if (!props.isAllowed()) {
        return <Navigate to={props.redirectPath as string} state={{ from: location }} replace />;
    }

    return props.children ? props.children : <Outlet />;
};
PrivateRoute.defaultProps = {
    redirectPath: AppUrls.Client.Account.Login,
};
