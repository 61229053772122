import React, { useContext } from "react";
import { Routes, useLocation } from "react-router-dom";
import { Logger } from "../index";
export const DebugRouter = ({ children }: { children: any }) => {
    const location = useLocation();

    if (process.env.NODE_ENV === "development") {
        Logger.logDebug(`Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`);
    }

    return children;
};
