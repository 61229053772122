import { ChoiceViewModel } from "./ChoiceViewModel";
import React from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, DashedLine, EditSelect, Label } from "../../../Components";
import { theme } from "../../../Styles";
import { LargePlus } from "../../../Components/LargePlus";
import { AnimatePresence, motion } from "framer-motion";
import Basin from "../../../Assets/Choices/basin.png";
import styled from "@emotion/styled/dist/emotion-styled.cjs";

const ChoiceTile = styled(Box)`
    background: #fff;
    border-radius: 10px;
    margin: 0 24px;
    height: 100px;
    &.no-option {
        height: 50px;
    }
`;

interface ChoicePanelProps {
    viewModel: ChoiceViewModel;
}
export const ChoicePanel: React.FC<ChoicePanelProps> = observer((props: ChoicePanelProps) => {
    const { viewModel } = props;
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Box mt={3}>
            <Box
                position={"relative"}
                flexBox
                alignItems={"center"}
                justifyContent={"space-between"}
                height={"50px"}
                mr={"0"}
                pr={10}
                pl={"30px"}
                backgroundColor={theme.palette.primary.main}
            >
                <Label fontStyle={"h3"}>Basins</Label>
                <Box flexBox>
                    <Label>{viewModel.getValue("numberExtrasAdded")} extras added</Label>
                    <Label style={{ marginRight: viewModel.isMobile ? "0px" : "20px" }}>{viewModel.getValue("numberChoicesMade")} option selected</Label>
                </Box>
                <Box position={"absolute"} right={"0px"} onClick={() => setExpanded(!expanded)}>
                    <LargePlus isExpanded={expanded} />
                </Box>
            </Box>
            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.div
                        initial={{
                            height: 0,
                            opacity: 0,
                        }}
                        style={{ backgroundColor: theme.palette.primary.main, paddingBottom: "30px" }}
                        animate={{
                            height: "auto",
                            opacity: 1,
                            transition: {
                                height: {
                                    duration: 0.5,
                                },
                                opacity: {
                                    duration: 0.5,
                                    delay: 0,
                                },
                            },
                        }}
                        exit={{
                            height: 0,
                            opacity: 0,
                            transition: {
                                height: {
                                    duration: 0.4,
                                },
                                opacity: {
                                    duration: 0.4,
                                },
                            },
                        }}
                        key="test"
                        className="text-lg font-light"
                    >
                        <Box backgroundColor={"#00000029"} pl={"30px"} py={"20px"} mb={"30px"}>
                            <Label>Please select your options...</Label>
                        </Box>
                        <ChoiceTile>
                            <Box flexBox justifyContent={"space-between"} alignItems={"center"}>
                                <img src={Basin} height={"100px"} alt={"basin"} style={{ borderRight: "2px solid #F47E44" }} />
                                <EditSelect command={props.viewModel.updateChoicesMadeCommand} value={() => viewModel.getValue("variant")} options={viewModel.choices} />
                                <Box mr={3}>
                                    <CheckBox
                                        labelColor={"black"}
                                        paletteColor={"secondary"}
                                        displayName={"Included"}
                                        value={() => props.viewModel.getValue("included")}
                                        command={props.viewModel.updateCostCommand}
                                    />
                                </Box>
                            </Box>
                        </ChoiceTile>
                        <DashedLine />
                        <ChoiceTile>
                            <Box flexBox justifyContent={"space-between"} alignItems={"center"}>
                                <img src={Basin} height={"100px"} alt={"basin"} style={{ borderRight: "2px solid #F47E44" }} />
                                <Box mr={3}>
                                    <CheckBox
                                        labelColor={"black"}
                                        paletteColor={"secondary"}
                                        displayName={"Included"}
                                        value={() => props.viewModel.getValue("included")}
                                        command={props.viewModel.updateCostCommand}
                                    />
                                </Box>
                            </Box>
                        </ChoiceTile>
                        <DashedLine />
                        <ChoiceTile>
                            <Box flexBox justifyContent={"space-between"} alignItems={"center"}>
                                <img src={Basin} height={"100px"} alt={"basin"} style={{ borderRight: "2px solid #F47E44" }} />
                                <Box mr={3}>
                                    <CheckBox
                                        labelColor={"black"}
                                        paletteColor={"secondary"}
                                        displayName={"£55.00"}
                                        value={() => props.viewModel.getValue("included")}
                                        command={props.viewModel.updateCostCommand}
                                    />
                                </Box>
                            </Box>
                        </ChoiceTile>
                        <DashedLine />
                        <ChoiceTile>
                            <Box flexBox justifyContent={"space-between"} alignItems={"center"}>
                                <img src={Basin} height={"100px"} alt={"basin"} style={{ borderRight: "2px solid #F47E44" }} />
                                <Box mr={3}>
                                    <CheckBox
                                        labelColor={"black"}
                                        paletteColor={"secondary"}
                                        displayName={"£POA"}
                                        value={() => props.viewModel.getValue("included")}
                                        command={props.viewModel.updateCostCommand}
                                    />
                                </Box>
                            </Box>
                        </ChoiceTile>
                        <DashedLine />
                        <ChoiceTile className={"no-option"}>
                            <Box flexBox justifyContent={"flex-end"} alignItems={"center"}>
                                <Box mt={3} mr={3}>
                                    <CheckBox
                                        labelColor={"black"}
                                        paletteColor={"secondary"}
                                        displayName={"None of the above"}
                                        value={() => props.viewModel.getValue("included")}
                                        command={props.viewModel.updateCostCommand}
                                    />
                                </Box>
                            </Box>
                        </ChoiceTile>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    );
});
