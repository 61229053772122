import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class StagesModel extends ModelBase<StagesModel> {
    public status: string = "Dummy Text Data";
    public title: string = "Dummy Text Data";
    public description: string = "Dummy Text Data";

    constructor() {
        super();
        makeObservable(this, {
            status: observable,
            title: observable,
            description: observable,
        });
    }
}

export class StagesModelValidator extends Validator<StagesModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
