import React, { useContext } from "react";
import { UserDetails } from "./Users/UserDetail/UserDetails";
import { Admin } from "./Admin";
import { PrivateRoute } from "Components/PrivateRoute";
import { container } from "tsyringe";
import { AccountStore } from "../../Stores/Domain";
import { Routes } from "react-router-dom";
import { Route } from "react-router";
import { RoleDetails } from "./Roles/RoleDetails";
import { AppUrls } from "../../AppUrls";

import { BlankViewModel } from "Application/ViewModels/BlankViewModel";
import { PortalUserDetails } from "./Users/PortalUsers/PortalUserDetail/PortalUserDetails";

const LogViewer = React.lazy(() => import("Views/LogViewer/LogViewer"));

export const AdminRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    const viewModel = new BlankViewModel();

    return (
        <div>
            <Routes>
                <Route
                    element={
                        <PrivateRoute
                            isAllowed={() => {
                                // return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                                return accountStore.IsLoggedIn;
                            }}
                        />
                    }
                >
                    <Route path={"dashboard/*"} element={<Admin />} />
                    <Route path={"userslist/user/:id"} element={<UserDetails />} />
                    <Route path={"portaluserslist/user/:id"} element={<PortalUserDetails />} />
                    <Route path={"roleslist/role/:id"} element={<RoleDetails />} />
                    <Route
                        path={AppUrls.Client.Admin.LogViewer}
                        element={
                            <React.Suspense fallback={<>...</>}>
                                <LogViewer />
                            </React.Suspense>
                        }
                    />
                </Route>
            </Routes>
        </div>
    );
};
