import { TestModel, TestModelValidator } from "./TestModel";
import { makeObservable, observable } from "mobx";
import { FieldType, HttpClient, KeyValuePair, Validation, ViewModelBase } from "@shoothill/core";
import { ICommand, ICommandAsync, RelayCommand, RelayCommandAsync } from "../Commands";
import { IKeyState } from "../index";
import { container } from "tsyringe";
import { IColumn } from "@fluentui/react";

//extend viewmodel base and passing your model as the generic type
export class TestViewModel extends ViewModelBase<TestModel> {
    private static _instance: TestViewModel;
    private httpClient = container.resolve(HttpClient);
    private bind = this.getContext();
    public errorMessage: string = "";
    public validMessage: string = "";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TestModel(), false);
        this.setValidator(new TestModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });
    }

    public validateModelCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        this.isModelValid();
    });

    public updateFirstNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("firstName", value);
    });
    public updateLastNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("lastName", value);
    });
    public updateTeamsCommand: ICommand = new RelayCommand((value: string[]) => {
        this.updateField("teams", value);
    });
    public updateTeamCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("team", value);
    });
    public updateAgeCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.updateField("age", value.key);
    });
    public updateDOBCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("dob", value);
    });
    public updateMemoCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("memo", value);
    });
    public updateTermsCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("terms", value);
    });

    private updateField(fieldName: keyof FieldType<TestModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateAddCommand: ICommand = new RelayCommand(
        async () => {
            await this.updateField("primaryCounter", (this.model.primaryCounter += 1));
        },
        () => {
            return this.model.primaryCounter < 10;
        },
    );
    public updateSubtractCommand: ICommand = new RelayCommand(
        async () => {
            await this.updateField("primaryCounter", (this.model.primaryCounter -= 1));
        },
        () => {
            return this.model.primaryCounter > 0;
        },
    );

    public get getTeams() {
        return [
            {
                key: "id_0",
                text: "Blackpool",
            },
            {
                key: "id_1",
                text: "Man City",
            },
            {
                key: "id_2",
                text: "Shrewsbury",
            },
            {
                key: "id_3",
                text: "Wolves",
            },
            {
                key: "id_4",
                text: "Worcester",
            },
            {
                key: "id_5",
                text: "Worthing",
            },
        ] as KeyValuePair[];
    }

    public get getDataTableColumns(): IColumn[] {
        return [
            { key: "column1", name: "Team", fieldName: "text", isResizable: true },
            { key: "column2", name: "Value", fieldName: "key", isResizable: true },
        ] as IColumn[];
    }

    public TestBearer = async () => {
        let response = await this.httpClient.Get("api/account/testbearer");
    };

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
            this.validMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
            this.validMessage = "";
        }
    };

    public goToAdmin = () => {
        this.history.push(`/admin`);
    };

    //public afterUpdate: undefined;
    //public beforeUpdate: undefined;

    afterUpdate(fieldName: keyof FieldType<TestModel>, value: any): void {
        //Do something like call an api or whatever
        //Can use this instead of mobx reaction
        return;
    }
    beforeUpdate(fieldName: keyof FieldType<TestModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "firstName") {
            value = "boo";
        }
        return value;
    }
}
