import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Show, theme, ThemedText } from "Application";
import { mergeStyleSets, TooltipHost } from "@fluentui/react";
import { nanoid } from "nanoid";
import { ExclamationIconSVG } from "../Assets/Icons/ExclamationIconSVG";
import { TickIcon } from "../Assets/Icons/TickIconSVG";

const styles = mergeStyleSets({
    root: {
        fontWeight: "600",
        fontSize: "14px",
    },
});

interface Props {
    haveError: boolean;
    displayName?: string;
    validationMessage: () => string;
    style?: any;
}
export const ErrorExclamationView: React.FC<Props> = observer((props: Props) => {
    const errorLabel = () => {
        if (props.haveError) {
            return (
                <Box display={"flex"}>
                    <Box>
                        <Box mb={1} mr={1}>
                            {props.displayName ? (
                                <ThemedText styles={styles} color={"error"}>
                                    {props.displayName}
                                </ThemedText>
                            ) : (
                                <ThemedText styles={styles} color={"error"}>
                                    {props.displayName}
                                </ThemedText>
                            )}
                        </Box>
                    </Box>
                    <TooltipHost
                        tooltipProps={{
                            calloutProps: {
                                gapSpace: 0,
                                styles: {
                                    beak: { background: theme.palette.error.main },
                                    beakCurtain: { background: theme.palette.error.main },
                                    calloutMain: { background: theme.palette.error.main },
                                },
                            },
                        }}
                        content={
                            <ThemedText fontStyle={"h5"} color={"white"}>
                                {(props.validationMessage() as string) || "There has been an error"}
                            </ThemedText>
                        }
                        id={nanoid()}
                        styles={{ root: { display: "inline-block" } }}
                    >
                        <Box mt={"0px"}>
                            <ExclamationIconSVG color={"white"} backgroundcolor={theme.palette.error.main} />
                        </Box>
                    </TooltipHost>
                </Box>
            );
        } else {
            return (
                <Box display={"flex"}>
                    <ThemedText mb={1} mr={1} styles={styles}>
                        {props.displayName}
                    </ThemedText>
                    {/* <Show if={props.validationMessage?.() !== undefined && props.validationMessage() === ""}>
                        <TickIcon />
                    </Show>*/}
                </Box>
            );
        }
    };

    return errorLabel();
});
