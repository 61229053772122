import { makeObservable, observable, observe } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, RelayCommand } from "Application";
import { SalesprogressModel, SalesprogressModelValidator } from "./SalesprogressModel";
import { Logger } from "index";
import { StagesModel } from "./Stages/StagesModel";
import { StagesViewModel } from "./Stages/StagesViewModel";
import { container } from "tsyringe";
import { AccountStore } from "../../Stores/Domain";
import { GETSalesProgressEndpoint } from "./Endpoints/GETSalesProgressEndpoint";

export class SalesprogressViewModel extends ViewModelBase<SalesprogressModel> {
    private accountStore = container.resolve(AccountStore);
    public apiClient = new APIClient();
    public stagesViewModels = observable<StagesViewModel>([]);

    constructor(model = new SalesprogressModel()) {
        super(model);
        this.setValidator(new SalesprogressModelValidator());
        makeObservable(this, {});
        this.apiClient.sendAsync(new GETSalesProgressEndpoint(this, this.accountStore.CoinsPlotId!), this.model);

        /*let m = new StagesModel();
        m.title = "Enquiries Raised";
        m.updatedDate = new Date("28/07/2022");
        m.description =
            "Your home is now a bare but beautiful canvas, ready for the ‘Second Fix’. At this stage, it’s all hands on deck to fit your kitchen, bathrooms, wardrobes and cupboards. Your sockets will go in and your new appliances will fit seamlessly into their new homes. No detail will be spared, from doorbells and dimmer switches to curtains and coffee machines. We’ll paint your house from top to bottom and either lay your carpets, fit your flooring or do a bit of both.";
        this.model.stagesModels.push(m);
        let m2 = new StagesModel();
        m2.title = "Enquiries Raised";
        m2.updatedDate = new Date("28/07/2022");
        m2.description =
            "Your home is now a bare but beautiful canvas, ready for the ‘Second Fix’. At this stage, it’s all hands on deck to fit your kitchen, bathrooms, wardrobes and cupboards. Your sockets will go in and your new appliances will fit seamlessly into their new homes. No detail will be spared, from doorbells and dimmer switches to curtains and coffee machines. We’ll paint your house from top to bottom and either lay your carpets, fit your flooring or do a bit of both.";
        this.model.stagesModels.push(m2);
        let m3 = new StagesModel();
        m3.title = "Exchange";
        m3.updatedDate = new Date("28/07/2022");
        m3.description =
            "Your home is now a bare but beautiful canvas, ready for the ‘Second Fix’. At this stage, it’s all hands on deck to fit your kitchen, bathrooms, wardrobes and cupboards. Your sockets will go in and your new appliances will fit seamlessly into their new homes. No detail will be spared, from doorbells and dimmer switches to curtains and coffee machines. We’ll paint your house from top to bottom and either lay your carpets, fit your flooring or do a bit of both.";
        this.model.stagesModels.push(m3);*/
    }

    public dispose = (): void => {
        this.stagesModelsObserverDispose();
    };

    public get salesProgressList() {
        const project = this.stagesViewModels.filter((item) => item.model.isVisible);
        return project;
    }

    private stagesModelsObserverDispose = observe(this.model.stagesModels, (childModelChanges: any) => {
        Logger.logDebug(`StagesViewModel.childModelsObserverDispose`);
        for (const addedChildModel of childModelChanges.added) {
            this.stagesViewModels.push(new StagesViewModel(addedChildModel));
        }

        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.stagesViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);

            if (childViewModelToRemove) {
                this.stagesViewModels.remove(childViewModelToRemove);
            }
        }
    });

    //#region Update Commands
    public addStagesCommand = new RelayCommand(() => {
        this.model.stagesModels.push(new StagesModel());
    });

    //#endregion Commands
}
