import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Box, Button, ICommand, RelayCommand, ThemedText } from "Application";
import logo from "Assets/logo.svg";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";

interface ILogoutModelViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
    rootLogo: IStyle;
    rootUserImage: IStyle;
    rootText: IStyle;
    rootButton: IStyle;
}

const styleDefinitions: ILogoutModelViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "385px",
        padding: "40px 40px 40px",
        width: "100%",
    },
    rootLogo: {
        maxWidth: "168px",
        margin: "0 auto 30px",
        img: {
            width: "100%",
        },
    },
    rootUserImage: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > div": {
            width: "100px",
            marginBottom: "10px",
        },
    },
    rootText: {
        textAlign: "center",
        span: {
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0.54px",
            color: "#191919",
        },
    },
    header: {
        fontSize: "18px",
        textAlign: "center",
        display: "block",
        color: "#DC3545",
        fontWeight: 600,
        padding: "10px 0",
    },
    body: {
        fontSize: "12px",
        lineHeight: "18px",
        color: "#171716",
        margin: "15px 0 22px",
    },
    footer: {
        display: "flex",
        padding: "30px 30px",
    },
    rootButton: {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginTop: "25px",
        button: {
            padding: "10px",
            height: "32px",
            width: "168px",
            margin: "0 auto",
        },
    },
};

interface LogoutModelViewProps {
    onClose: () => void;
}

export const LogoutModelView: React.FC<LogoutModelViewProps> = observer(({ onClose }) => {
    const styles = mergeStyleSets(styleDefinitions);
    const accountStore = container.resolve(AccountStore);

    const logoutCommand: ICommand = new RelayCommand(() => {
        accountStore.Logout();
    });

    const closeLogoutModalCommand = new RelayCommand(() => {
        onClose();
    });

    return (
        <Box className={styles.root}>
            <Box className={styles.rootText}>
                <ThemedText fontStyle={"h2"}>Are you sure you want to log out? </ThemedText>
            </Box>
            <Box className={styles.rootButton}>
                <Button
                    paletteColor="error"
                    displayName="YES, LOG ME OUT"
                    command={logoutCommand}
                    style={{
                        maxWidth: "100%",
                        background: "#F47E44",
                        width: "100%",
                        borderRadius: 0,
                        height: "30px",
                        font: "normal normal 300 12px/14px Lato",
                        letterSpacing: "0.36px",
                        color: "#FFFFFF",
                        textTransform: "uppercase",
                    }}
                />
                <Button
                    displayName={"NO, KEEP ME LOGGED IN"}
                    command={closeLogoutModalCommand}
                    style={{
                        background: "#4A4A4A",
                        maxWidth: "100%",
                        width: "100%",
                        borderRadius: 0,
                        height: "30px",
                        font: "normal normal 300 12px/14px Lato",
                        letterSpacing: "0.36px",
                        color: "#FFFFFF",
                        textTransform: "uppercase",
                    }}
                />
            </Box>
        </Box>
    );
});
