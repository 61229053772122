import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "../../Components/InfoBar";
import { HeaderStyles } from "../Header/HeaderStyles";
import { Outlet } from "react-router";
import { AutoGrid, Box } from "../../Components";
import { mergeStyleSets } from "@fluentui/react";
import { pxToRem } from "../../Application";
import image from "Assets/logInImage.png";
import { observer } from "mobx-react-lite";
import { BlankViewModel } from "../../Application/ViewModels/BlankViewModel";

export const styles = mergeStyleSets({
    root: {
        display: "flex",
        height: "100vh",
        backgroundColor: "#fff",
    },
    content: {
        //boxShadow: `3px 0px 6px #00000029`,
        display: "flex",
        flex: "1 0 0",
        overflow: "hidden",
    },
    image: {
        backgroundImage: `url(${image})`,
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flex: "1 0 0",
        width: "50%",
    },
});
//EN: use empty generic type to get default react props
export const SplitLayout: React.FC<{}> = observer((props) => {
    const viewModel = new BlankViewModel();
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                    {!viewModel.isMobile ? (
                        <Box>
                            <InfoBar />
                            <Box className={styles.root}>
                                <Box className={styles.content}>
                                    <Outlet />
                                </Box>
                                <Box id={"laptop"} className={styles.image}></Box>
                            </Box>
                            {/*<Footer />*/}
                        </Box>
                    ) : (
                        <Box style={{ marginTop: 0 }} justifyContent={"center"} minHeight={"100vh"} background={"#fff"}>
                            {/* <Box id={"laptop"} style={{ height: "196px", width: "100%" }} className={styles.image}></Box> */}
                            <Outlet />
                        </Box>
                    )}
                </>
            )}
        </>
    );
});
