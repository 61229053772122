import { makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { BuildStageModel, BuildStageModelValidator } from "./BuildStagesModel";

export class BuildStagesViewModel extends ViewModelBase<BuildStageModel> {
    public apiClient = new APIClient();
    public isEdit: boolean = false;
    public timeOut: any = null;
    public description = this.model.description;
    public imageUrl = this.model.imageUrl;
    constructor(model = new BuildStageModel()) {
        super(model);
        this.setValidator(new BuildStageModelValidator());
        makeObservable(this, { description: observable, isEdit: observable, imageUrl: observable });
    }
    public updateTermsAndConditionText = (value: string): void => {
        this.updateField("description", value);
    };
    // public renderDescription = () => {

    // };

    public updateStageCodeCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("stageCode", value);
    });

    public updateDescriptionCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("description", value);
    });

    public updateIsVisibleCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("isVisible", value);
    });

    public updateField(fieldName: keyof FieldType<BuildStageModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
        this.isEdit = true;
    }
}
