import { FieldType, ViewModelBase, KeyValuePair, CoreStoreInstance } from "@shoothill/core";
import { UserModelValidator, APIClient, ICommand, ICommandAsync, RelayCommand, RelayCommandAsync, IKeyState } from "Application";
import { UserModel } from "Views/Admin/Users/UserModel";
import { action, makeObservable, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { Logger } from "../../../../index";
import { AccountStore, RoleStore } from "../../../../Stores/Domain";
import { GetUserByIdEndpoint } from "../Endpoints/GetUserByIdEndpoint";
import { SaveUserEndpoint } from "../Endpoints/SaveUserEndpoint";
import { GETDevelopemntsEndpoint } from "../Endpoints/GETDevelopmentsEndpoint";
import { GETPlotEndpoint } from "../Endpoints/GETPlotEndpoint";
import { POSTUserPlotEndpoint } from "../Endpoints/POSTUserPlotEndpoint";
import UserViewModel from "../UserViewModel";
import { ForgotPasswordEndpoint } from "Views/Login/SignIn/ForgotPassword/Endpoints/ForgotPasswordEndpoint";

export default class UserDetailsViewModel extends ViewModelBase<UserModel> {
    private roleStore = container.resolve(RoleStore);

    private accountStore = container.resolve(AccountStore);
    private useData = new UserViewModel();
    public apiClient = new APIClient();
    public resetLoginAttemptsError = "";
    public isTableLoading: boolean = false;
    public developmentOptions: KeyValuePair[] = [];
    public plotOptions: KeyValuePair[] = [];
    public editMail = "";
    public firstName = "";
    public lastName = "";
    public newUser = true;
    private forgotPasswordEndpoint = new ForgotPasswordEndpoint();

    constructor() {
        super(new UserModel(null));
        this.setValidator(new UserModelValidator());
        makeObservable(this, {
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            developmentOptions: observable,
            plotOptions: observable,
            editMail: observable,
            firstName: observable,
            lastName: observable,
            set: action,
            loadUserAsync: action,
            resetFailedLoginAttempts: action,
        });
        this.apiClient.sendAsync(new GETDevelopemntsEndpoint(this));
        /*const doAsync = async () => {
            if (isNullOrEmpty(this.model.developmentId)) {
                await this.apiClient.sendAsync(new GETPlotEndpoint(this.accountStore.CoinsDevelopmentId!, this));
            } else {
                await this.apiClient.sendAsync(new GETPlotEndpoint(this.developmentOptions[0].key, this));
            }
        };
        const _ = doAsync();*/
    }

    public capitalizeFirstLetter(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public updateFirstNameCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", this.capitalizeFirstLetter(value), keyState);
    });
    public updateLastNameCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", this.capitalizeFirstLetter(value), keyState);
    });
    public updateEmailAddressCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("emailAddress", value);
        this.checkDuplicateEmail("emailAddress", value);
    });
    public updateSelectRolesCommand: ICommand = new RelayCommand((value: string[]) => {
        this.updateField("userRoles", value);
    });
    public updateDevelopmentIdCommand = new RelayCommand((value: string) => {
        this.setValue("developmentId", value);
        /*if(this.model.id) {
            this.accountStore.CoinsDevelopmentId = value;
        }*/
        this.apiClient.sendAsync(new GETPlotEndpoint(value, this));
    });
    public updateCoinsPlotIdCommand = new RelayCommand((value: string) => {
        this.setValue("plotId", value);
        /*if(this.model.id) {
            this.accountStore.CoinsPlotId = value;
        }*/
    });
    private updateField(fieldName: keyof FieldType<UserModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
    private checkDuplicateEmail(fieldName: keyof FieldType<UserModel>, value: any, keyState?: IKeyState) {
        const emails = this.useData.getUsers.filter((el) => el.emailAddress === value);
        if (((this.editMail !== "" && this.editMail !== this.getValue(fieldName)) || (this.editMail == "" && this.newUser)) && emails.length > 0) {
            this.setError(fieldName, "This email address is already in use,please use a different email address");
            return false;
        }
        return true;
    }

    private checkValidation() {
        const isValid = this.isModelValid();
        const checkEmail = this.checkDuplicateEmail("emailAddress", this.getValue("emailAddress"));
        return isValid && checkEmail;
    }

    public saveUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.checkValidation()) {
            let _ = await this.apiClient.sendAsync(new SaveUserEndpoint(), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                const userModel = this.apiClient.Response<UserModel>();
                userModel.plotId = this.model.plotId;
                userModel.userPlotId = this.model.userPlotId;
                userModel.developmentId = this.model.developmentId;
                if (this.accountStore.CoinsDevelopmentId) {
                    let _ = await this.apiClient.sendAsync(new POSTUserPlotEndpoint(), userModel);
                }
                CoreStoreInstance.ShowInfoBar("User Saved Successfully", "success");
                this.history.push(AppUrls.Client.Admin.AdminHome);
            }
        }
    });

    public forgotPasswordCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(this.forgotPasswordEndpoint, this.model);
        if (this.apiClient.IsRequestSuccessful) {
            this.history.push(AppUrls.Client.Admin.AdminHome);
        }
    });

    public cancelSaveUserCommand: ICommand = new RelayCommand(async () => {
        this.history.push(AppUrls.Client.Admin.AdminHome);
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }
    public getRoles = () => {
        const options = this.roleStore.getRoles.map((role) => ({
            key: role.id,
            text: role.name,
        })) as KeyValuePair[];
        const result = options.filter((item) => item.text !== "user");
        return result;
    };

    public async loadUserAsync(userId: string): Promise<void> {
        const getUser = new GetUserByIdEndpoint(this, userId);
        await this.apiClient.sendAsync(getUser);
        Logger.logDebug("loadUsersAsync: ", this.apiClient.Response<UserModel[]>());
        // await this.apiClient.sendAsync(new GETPlotEndpoint(this.model.developmentId!, this));
        if (this.model.developmentId) {
            await this.apiClient.sendAsync(new GETPlotEndpoint(this.model.developmentId!, this));
        }
        this.updateField("plotId", this.model.plotId);
        this.firstName = this.model.firstName;
        this.lastName = this.model.lastName;
        this.editMail = this.model.emailAddress;
        this.newUser = false;
    }

    public async resetFailedLoginAttempts(): Promise<void> {
        await this.apiClient.command.Post(
            AppUrls.Server.Admin.ResetFailedLoginAttemptsCount,
            {
                id: this.getValue("id"),
            },
            async (response) => {
                this.resetLoginAttemptsError = "";
            },
            () => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            },
        );
    }
}
