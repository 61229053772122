import { ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application/Commands";
import { makeObservable } from "mobx";
import { HeaderLinkModel } from "./HeaderLinkModel";

export class HeaderLinkViewModel extends ViewModelBase<HeaderLinkModel> {
    constructor(displayName: string, routePath: string) {
        super(new HeaderLinkModel(displayName), true, false);
        this.setValue("routePath", routePath);

        this.history.listen((update: any) => {
            this.setActiveLink(update.location);
        });

        this.setActiveLink(this.history.location);
        makeObservable(this, {});
    }

    private setActiveLink = (location: any) => {
        if (this.getValue("routePath") === location.pathname || this.getValue("routePath") === location.pathname.split("/")[1]) {
            this.setValue("isActive", true);
        } else {
            this.setValue("isActive", false);
        }
    };
    // #region Actions

    public navigateToLinkCommand = new RelayCommand((url) => {
        this.setActiveOption();
        this.history.push(url);
    });

    public setActiveOption = () => {
        this.setValue("isActive", true);
    };

    public clearActiveOption = () => {
        this.setValue("isActive", false);
    };

    // #endregion Actions

    // #endregion Computeds
}
