import { Endpoint, Http } from "Application";
import { BuildStageModel } from "../BuildStage/BuildStagesModel";
import { runInAction } from "mobx";
import { BuildStageAdminViewModel } from "../BuildStageAdminViewModel";

class BuildStageAdminRequest {
    public id: string | null = null;
    public title: string = "";
    public number: string = "";
    public description: string = "";
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";
}
class Request {
    public putUpsertBuildProgressRequests: BuildStageAdminRequest[] = [];
}
class Response {
    public buildProgressItems: BuildStageAdminResponse[] = [];
}
class BuildStageAdminResponse {
    public id: string | null = null;
    public title: string = "";
    public number: string = "";
    public description: string = "";
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";
}
export class POSTBuildStageAdminEndpoint extends Endpoint<BuildStageAdminRequest, Response> {
    private viewModel: BuildStageAdminViewModel;
    constructor(viewModel: BuildStageAdminViewModel) {
        super();
        this.verb(Http.Put);
        this.path("/api/buildProgress/upsert");
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const serviceModels: BuildStageAdminRequest[] = [];
        this.viewModel.buildViewModels.forEach((item) => {
            const m = new BuildStageAdminRequest();
            m.id = item.model.id;
            m.title = item.model.sequence;
            m.number = item.model.stageCode;
            m.description = item.model.description;
            m.isCompleted = item.model.isCompleted;
            m.isVisible = item.model.isVisible;
            // m.imageUrl = item.model.imageUrl;
            serviceModels.push(m);
        });
        const request = new Request();
        request.putUpsertBuildProgressRequests = serviceModels;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            let responseData: BuildStageModel[] = [];
            for (const item of response.buildProgressItems) {
                let m = new BuildStageModel();
                m.id = item.id;
                m.isCompleted = item.isCompleted;
                m.isVisible = item.isVisible;
                m.description = item.description;
                m.stageCode = item.number;
                m.sequence = item.title;
                m.imageUrl = item.imageUrl;
                responseData.push(m);
            }
            this.viewModel.model.stagesModels.replace(responseData);
            this.viewModel.isCancel = false;
            this.viewModel.isSave = false;
        });
        return {};
    }
}
