import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/react";
import React, { MouseEventHandler } from "react";
import { Box, Label, theme } from "Application";
import { BlankViewModel } from "Application/ViewModels/BlankViewModel";
import { fontFamily } from "styled-system";

export const StaticBGImg = styled.img`
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
    z-index: -1;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 1024px) {
        /* Specific to this particular image */
        left: 50%;
        margin-left: -512px; /* 50% */
    }
`;

export const FieldLabel = styled.label`
    font-family: ${theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight};
    margin-bottom: 5px;
`;

export const LinkBox = styled.div`
    padding: 5px;
    cursor: pointer;
`;

export const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const Dot = styled.div<any>`
    background-color: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    /* Animation */
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${(props) => props.delay};
`;

export const HRLine = styled.hr`
    border: none;
    border-top: 2px solid #71727a;
    color: #71727a;
    background-color: #fff;
    height: 1px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const SwirlyHeader = styled.div`
    background-color: ${theme.palette.secondary.main};
    height: 36px;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    border-radius: 0 0 100px 0;
    a {
        color: ${theme.palette.common.blue};
        :visited {
            color: ${theme.palette.common.blue};
        }
    }
    @media only screen and (min-width: 420px) {
        height: 50px;
    }
`;
interface CoinsPageContainerProps {
    title: string;
    children: any;
    style?: any;
    ml: string;
    mr: string;
}
export const CoinsPageContainer: React.FC<CoinsPageContainerProps> = (props) => {
    const viewModel = new BlankViewModel();
    return (
        <Box
            id={"coinspagecontainer"}
            style={props.style || { boxShadow: "0 3px 6px #00000040", minHeight: "90vh" }}
            ml={props.ml}
            mr={props.mr}
            maxWidth={"1366px"}
            backgroundColor={theme.palette.primary.main} /*height={"90vh"}*/
        >
            <SwirlyHeader>
                <Label pl={viewModel.isMobile ? 2 : 6} fontStyle={"h3"} color={"white"}>
                    {props.title}
                </Label>
            </SwirlyHeader>
            <Box p={viewModel.isMobile ? 2 : 6}>{props.children}</Box>
        </Box>
    );
};

interface CoinsPageContainerWithTabsProps {
    title: string;
    tabs: any;
    children: any;
    ml?: string;
    mr?: string;
}
export const CoinsPageContainerWithTabs: React.FC<CoinsPageContainerWithTabsProps> = (props) => {
    const viewModel = new BlankViewModel();
    return (
        <Box id={"coinsPageContainerWithTabs"} ml={props.ml} mr={props.mr} style={{ boxShadow: "0 3px 6px #00000040" /*, minHeight: "90vh"*/ }}>
            <Box>
                <SwirlyHeader>
                    <Box>{props.tabs}</Box>
                </SwirlyHeader>
            </Box>
            <Box p={viewModel.isMobile ? 2 : 6}>{props.children}</Box>
        </Box>
    );
};

interface CoinsPageNoTitleContainerProps {
    children: any;
    style?: any;
    ml: string;
    mr: string;
}
export const CoinsPageNoTitleContainer: React.FC<CoinsPageNoTitleContainerProps> = (props) => {
    const viewModel = new BlankViewModel();
    return (
        <Box
            id={"coinspagecontainer"}
            style={props.style || { boxShadow: "0 3px 6px #00000040", minHeight: "90vh" }}
            ml={props.ml}
            mr={props.mr}
            maxWidth={"1366px"}
            backgroundColor={theme.palette.primary.main} /*height={"90vh"}*/
        >
            <Box p={viewModel.isMobile ? 2 : 6}>{props.children}</Box>
        </Box>
    );
};

interface ParagraphProps {
    children: any;
    style?: any;
}

export const Paragraph: React.FC<ParagraphProps> = (props) => {
    const viewModel = new BlankViewModel();
    return <p style={props.style || { fontSize: "0.875rem", fontFamily: theme.defaultFontStyle.fontFamily }}>{props.children}</p>;
};

interface BeakProps {
    color?: string;
    borderColor?: string;
    size?: string;
}
export const LeftBeak = styled.div<BeakProps>`
    width: 0;
    height: 0;
    border-top: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-bottom: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-right: ${(props: BeakProps) => props.size ?? "10px"} solid ${(props: BeakProps) => props.color ?? theme.palette.primary.main};
`;
export const RightBeak = styled.div<BeakProps>`
    width: 0;
    height: 0;
    border-top: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-bottom: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-left: ${(props: BeakProps) => props.size ?? "10px"} solid ${(props: BeakProps) => props.color ?? theme.palette.primary.main};
`;
export const UpBeak = styled.div<BeakProps>`
    width: 0;
    height: 0;
    border-left: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-right: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-bottom: ${(props: BeakProps) => props.size ?? "10px"} solid ${(props: BeakProps) => props.color ?? theme.palette.primary.main};
`;
export const DownBeak = styled.div<BeakProps>`
    width: 0;
    height: 0;
    border-left: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-right: ${(props: BeakProps) => props.size ?? "10px"} solid transparent;
    border-top: ${(props: BeakProps) => props.size ?? "10px"} solid ${(props: BeakProps) => props.color ?? theme.palette.primary.main};
`;
