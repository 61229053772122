import React, { useEffect, useState } from "react";
import RoleViewModel from "Views/Admin/Roles/RoleViewModel";
import { observer } from "mobx-react-lite";
import { ThemedDataTable } from "../../../Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { AutoGrid, Box, NullCommand, ThemedButton, ThemedText } from "Application";
import { IColumn } from "@fluentui/react";
import { EditIcon } from "../../../Assets/Icons/EditIcon";
import { DeleteIcon } from "../../../Assets";
import { RoleModel } from "./RoleModel";
import { ThemedDialog } from "../../../Styles/ThemedPrimitives/Style1/ThemedDialog";

export const RolesList: React.FC = observer(() => {
    const [viewModel] = useState(() => new RoleViewModel());
    useEffect(() => {}, []);

    const getDataTableColumns = (): IColumn[] => {
        return [
            { key: "nameCol", name: "Role Name", fieldName: "name", isSorted: true },
            {
                key: "actionsCol",
                name: "Actions",
                onRender: (item: RoleModel) => {
                    return (
                        <AutoGrid dc={"1fr 1fr 1fr"}>
                            <Box title={"Edit User"}>
                                <EditIcon onClick={() => viewModel.editRoleCommand.execute(item)} />
                            </Box>
                            <Box title={"Delete User"}>
                                <DeleteIcon color={"red"} onClick={() => viewModel.deleteUserWithConfirmDialogCommand.execute(item)} />
                            </Box>
                        </AutoGrid>
                    );
                },
            },
        ] as IColumn[];
    };

    return (
        <>
            <Box p={3}>
                <Box flexGrow={1} textAlign="right">
                    <ThemedButton paletteColor={"primary"} command={viewModel.newRoleCommand} displayName={"New Role"} />
                </Box>
                <ThemedDataTable data={viewModel.Roles} columns={getDataTableColumns()} command={new NullCommand()} value={() => viewModel.selectedRows} />
            </Box>
            <ThemedDialog
                command={viewModel.deleteRoleCommand}
                dismissCommand={viewModel.hideDialogCommand}
                title={`Delete Role ${viewModel.roleToDelete.name}`}
                message={"Are you sure you want to delete this role?"}
                show={viewModel.showDialog}
            />
        </>
    );
});
