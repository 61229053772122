import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import { config } from "../config";
import { StyleSheet1 } from "./StyleSheet1";
import { IShoothillTheme } from "./IShoothillTheme";

const getThemeVersion = (): IShoothillTheme => {
    switch (config.themeVersion) {
        case "StyleSheet1":
            return StyleSheet1;
        default:
            return StyleSheet1;
    }
};

export let theme = getThemeVersion();

export const setTheme = (newTheme: any) => {
    theme = { ...theme, ...newTheme };
    window.theme = theme;
};
//export const palette = theme.palette;
window.theme = theme;

export const CustomThemeProvider: React.FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
    switch (config.themeProvider) {
        case "materialui":
            return (
                //EN: Add in NPM package @mui/material
                /*<StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                        <EmotionThemeProvider theme={theme}>
                            <CssBaseline>{props.children}</CssBaseline>
                        </EmotionThemeProvider>
                    </MuiThemeProvider>
                </StyledEngineProvider>*/
                <>{props.children}</>
            );
        case "emotion":
        case "fluentui":
        default:
            return <EmotionThemeProvider theme={theme}>{props.children}</EmotionThemeProvider>;
    }
};
