import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PurchaseStagesModel extends ModelBase<PurchaseStagesModel> {
    public sequence: string = "";
    public stageCode: string = "";
    public description: string = "";
    public isVisible: boolean = false;
    public imageUrl: string = "";
    public title: string = "";
    public isCompleted: boolean = false;
    public stageDateUTC: null | string = "";
    constructor() {
        super();
        makeObservable(this, {
            sequence: observable,
            stageCode: observable,
            description: observable,
            isVisible: observable,
            isCompleted: observable,
            stageDateUTC: observable,
            title: observable,
            imageUrl: observable,
        });
    }
}

export class PurchaseStagesModelValidator extends Validator<PurchaseStagesModel> {
    constructor() {
        super();
        this.ruleFor("description").notEmpty().withMessage("Please enter a description");
    }
}
