import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PanelModel extends ModelBase<PanelModel> {
    constructor() {
        super();
        makeObservable(this, {});
    }
}
