import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle: boolean;
}
export const BuildProgressSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_8706" data-name="Group 8706" transform="translate(-416 -571)">
                <g id="Group_8264" data-name="Group 8264">
                    <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(416 571)" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="23.75" fill="none" />
                    </g>
                    <g id="noun_brick_wall_977839" data-name="noun_brick wall_977839" transform="translate(425.5 580.002)">
                        <g id="Group_5512" data-name="Group 5512" transform="translate(2.5 8.998)">
                            <path
                                id="Path_3670"
                                data-name="Path 3670"
                                d="M12.611,1029.36h5.778v2.889H12.611Zm6.741,0H25.13v2.889H19.352ZM2.5,1033.212H8.278v2.889H2.5Zm6.741,0h5.778v2.889H9.241Zm6.741,0h5.778v2.889H15.981Zm6.741,0H28.5v2.889H22.722ZM5.87,1037.064h5.778v2.889H5.87Zm6.741,0h5.778v2.889H12.611Zm6.741,0H25.13v2.889H19.352ZM2.5,1040.915H8.278v2.889H2.5Zm6.741,0h5.778v2.889H9.241Zm6.741,0h5.778v2.889H15.981Zm6.741,0H28.5v2.889H22.722Z"
                                transform="translate(-2.5 -1029.36)"
                                fill="#fefefe"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
