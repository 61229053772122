import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class DocumentsModel extends ModelBase<DocumentsModel> {
    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class DocumentsModelValidator extends Validator<DocumentsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
