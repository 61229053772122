import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { BuildprogressViewModel } from "./BuildprogressViewModel";
import { StagesView } from "./Stages/StagesView";

export const BuildprogressView: React.FC = observer(() => {
    const [viewModel] = useState(() => new BuildprogressViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Buildprogress");
    }, []);
    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Buildprogress</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}></Box>
            {viewModel.stagesViewModels.map((item, index) => {
                return <StagesView viewModel={item} key={item.model.KEY} />;
            })}

            <ThemedButton mt={3} displayName={"Add Stages"} command={viewModel.addStagesCommand} />
        </Box>
    );
});
