import { EndpointWithoutRequest, Http } from "Application";
import { Moment } from "moment";
import PotalUserDetailsViewModel from "../../PortalUsers/PortalUserDetail/PortalUserDetailsViewModel";
import { runInAction } from "mobx";
import { PortalUsersModel } from "../../PortalUsers/PortalUsersModel";

class Response {
    public id: Guid | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public password: string = "";
    public role: string = "";
    public developmentId: string = "";
    public plotId: string = "";
    public userPlotId: string | null = "";
    public concurrencyToken: string = "";
    public isDeleted: boolean = false;
    public lastLoginDate: Moment | null = null;
    public userRoles: string[] = [];
}

export class GetUserByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PotalUserDetailsViewModel;
    constructor(viewModel: PotalUserDetailsViewModel, userId: string) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(`/api/user/getUserById/${userId}`);
    }

    public async HandleResponseAsync(response: Response): Promise<PortalUsersModel> {
        let portalUsersModel: PortalUsersModel = new PortalUsersModel(response.id);
        runInAction(() => {
            portalUsersModel.fromResponse(response);
            portalUsersModel.userRoles.replace(response.userRoles);
            this.viewModel.setModel(portalUsersModel);
        });
        return portalUsersModel;
    }
}
