import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { container } from "tsyringe";
import { AccountStore } from "../../../../Stores/Domain";
import { UserModel } from "../UserModel";

class Request {
    public Plots: UserPlotRequest[] = [];
    public developmentId: string = "";
    public userId: string | null | undefined = null;
}
class UserPlotRequest {
    public PlotId: string = "";
    public userPlotId: string | null | undefined = null;
}

class Response {}

export class POSTUserPlotEndpoint extends Endpoint<Request, Response> {
    private accountStore = container.resolve(AccountStore);

    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/userlot/saveuserplots");
    }

    public async HandleRequestAsync(model: UserModel): Promise<any> {
        let request = new Request();
        let userPlotRequest = new UserPlotRequest();
        request.Plots = [userPlotRequest];
        userPlotRequest.PlotId = model.plotId;
        userPlotRequest.userPlotId = model.userPlotId;
        request.developmentId = model.developmentId;
        request.userId = model.id?.toString();

        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
