import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { DashboardViewModel } from "./DashboardViewModel";
import { PanelView } from "./Panel/PanelView";

export const DashboardView: React.FC = observer(() => {
    const [viewModel] = useState(() => new DashboardViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Dashboard");
    }, []);
    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Dashboard</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}></Box>
            <PanelView viewModel={viewModel.panelViewModel} />
        </Box>
    );
});
