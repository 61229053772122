import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { CustomercareModel, CustomercareModelValidator } from "./CustomercareModel";
import { Logger } from "index";

export class CustomercareViewModel extends ViewModelBase<CustomercareModel> {
    public apiClient = new APIClient();

    constructor(model = new CustomercareModel()) {
        super(model);
        this.setValidator(new CustomercareModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands

    //#endregion Commands
}
