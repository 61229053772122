import * as Utils from "@shoothill/core";
import { BaseStore, CoreStoreInstance } from "@shoothill/core";
import { Logger } from "index";

import { AppUrls } from "./../../AppUrls";
import { action, makeObservable, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Application/Models";
import { AccountStatus } from "@shoothill/core";
import { singleton } from "tsyringe";
import { InitViewModel } from "../../Application/ViewModels/InitViewModel";
import { GlobalHistory } from "../../index";

export interface IAccountStore {
    init(stores: Stores, initialState: InitialState): Promise<void>;
    setIsLoggedIn(state: boolean): void;
    isInRole(role: string): boolean;
    Logout(redirect: boolean): Promise<void>;
    Logout(redirect: boolean): Promise<void>;
    getJwt(): string;
    getLoginState(jwt: string): void;
}

@singleton()
export class AccountStore extends BaseStore implements IAccountStore {
    public IsLoggedIn: boolean = false;
    public UserName: string = "";
    public DisplayName: string = "";
    public UserRoles: string[] = [];
    public CoinsPlotId: string | undefined = undefined;
    public loginValidationMessage: string = "";
    public CoinsDevelopmentId: string | undefined = undefined;
    public initViewModel = new InitViewModel();

    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
        Logger.logDebug("Account store created");
        makeObservable(this, {
            IsLoggedIn: observable,
            UserName: observable,
            DisplayName: observable,
            UserRoles: observable,
            CoinsPlotId: observable,
            loginValidationMessage: observable,
            CoinsDevelopmentId: observable,
            setIsLoggedIn: action,
            Logout: action,
            getLoginState: action,
            setCoinsPlotId: action,
            setLoginValidationMessage: action,
        });
    }

    //This gets called once from the Stores.ts file. We make sure this is called first as other stores depend on the login state
    public async init() {
        Logger.logDebug("Init account store");
        await this.initViewModel.getInitialState();
        await this.getLoginState(this.initViewModel.InitData.accountStatus.jwt as string);
    }

    public get initialState() {
        return this.initViewModel.InitData;
    }

    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
        CoreStoreInstance.SetLoggedIn(state);
    }

    public setCoinsPlotId(coinsPlotId: string | undefined) {
        this.CoinsPlotId = coinsPlotId;
    }

    public setLoginValidationMessage(text: string) {
        this.loginValidationMessage = text;
    }

    public setCoinsDevelopmentId(coinsDevelopmentId: string | undefined) {
        this.CoinsDevelopmentId = coinsDevelopmentId;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }
        return false;
    };

    public Logout = async (redirect: boolean = false): Promise<void> => {
        await Utils.deleteJWT();
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.setIsLoggedIn(false);
        this.UserName = "";
        this.DisplayName = "";
        CoreStoreInstance.SetDisplayName("");
        this.UserRoles = [];
        this.CoinsPlotId = "";
        //EN: Clear out all entries including cached items
        localStorage.clear();
        //(window as any).jwt = null;
        if (redirect) {
            window.location.href = "/account/login";
        } else {
            GlobalHistory.push("/account/login");
        }
    };

    public getJwt = (): string => {
        return this.jwt as string;
    };

    public getLoginState = async (jwt: string | null) => {
        // if (jwt === null) {
        //     GlobalHistory.push(AppUrls.Client.Account.Login);
        // }
        jwt = jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = await Utils.getJWT();
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            await Utils.setJWT(this.jwt);
            if (data === "") {
                return;
            }
            //localStorage.setItem(".refreshToken", apiResult?.refreshToken ?? "");
            //localStorage.setItem(".refreshTokenExpiryDate", apiResult?.refreshTokenExpiryDate ?? new Date());
            this.setIsLoggedIn(true);
            this.loginValidationMessage = "";
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = [].concat(data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || []);
            CoreStoreInstance.SetDisplayName(this.DisplayName);
            this.setCoinsPlotId(this.initViewModel.InitData.coinsPlotId);
            this.setCoinsDevelopmentId(this.initViewModel.InitData.coinsDevelopmentId);
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
            Logger.logDebug("Getloginstate finished", { jwt });
        }
    };
}
