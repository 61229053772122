import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { DashboardModel, DashboardModelValidator } from "./DashboardModel";
import { Logger } from "index";
import { PanelModel } from "./Panel/PanelModel";
import { PanelViewModel } from "./Panel/PanelViewModel";

export class DashboardViewModel extends ViewModelBase<DashboardModel> {
    public apiClient = new APIClient();
    public panelViewModel: PanelViewModel = new PanelViewModel();

    constructor(model = new DashboardModel()) {
        super(model);
        this.setValidator(new DashboardModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands

    //#endregion Commands
}
