import { makeObservable, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, RelayCommand } from "Application";
import { FileModel, FileUploaderModel, FileUploaderModelValidator } from "./FileUploaderModel";
import { BuildStagesViewModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesViewModel";

export class FileUploaderViewModel extends ViewModelBase<FileUploaderModel> {
    public apiClient = new APIClient();

    public maximumFileSize = 20000000; // 20mb

    public imageCropperPopup: boolean = false;
    public uploadedImage: string | null = null;
    public buildViewModels = new BuildStagesViewModel();
    constructor() {
        super(new FileUploaderModel());
        this.setValidator(new FileUploaderModelValidator());
        makeObservable(this, { imageCropperPopup: observable, uploadedImage: observable });
    }

    public get fileSizeExceeded() {
        return;
    }

    public get canImageCropper(): boolean {
        return this.imageCropperPopup;
    }

    public closeImageCropperPopupCommand = new RelayCommand(() => {
        this.imageCropperPopup = false;
    });

    public saveImageCommand = new RelayCommand((files: File) => {
        if (files) {
            const fileModel = new FileModel();
            fileModel.file = files;
            fileModel.fileName = files.name;
            fileModel.fileSizeBytes = files.size;
            fileModel.mimeType = files.type;
            fileModel.documentUrl = "";
            this.model.files.push(fileModel);
        }
    });

    public importData = (files: any) => {
        let input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.onchange = (_) => {
            const files: File[] = [];
            if (input.files) {
                input.files;
            }
            console.log(files);
        };
        input.click();
    };
}
