import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "../../Components/InfoBar";
import { HeaderStyles } from "../Header/HeaderStyles";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router";
import { Box, Spacer } from "../../Components";
import { pxToRem } from "../../Application";
import styled from "@emotion/styled";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { Footer } from "Views/Footer/FooterView";
const Container = styled(Box)`
    /* overflow-y: auto; */
    max-width: 1366px;
    margin: 0 auto;
    padding-bottom: 10px;
    margin-bottom: 10px;
    & > div {
        padding: 0;
        height: 100%;
    }
`;
//EN: use empty generic type to get default react props
export const DefaultLayoutNoFooter: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    const isMobile = useMediaQuery("(max-width: 767px)");
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <Box className={"overflowContainer"}>
                    <HeaderStyles />
                    <Box id={"erik"} mt={isMobile ? "50px" : "58px"}>
                        <InfoBar />
                        {/* <Container id={"rootcontainer"} height={"100%"}> */}
                        <Outlet />
                        {/* </Container> */}
                        <Footer />
                    </Box>
                </Box>
            )}
        </>
    );
});
