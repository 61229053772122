import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { nanoid } from "nanoid";

export class ImageCropperModel extends ModelBase<ImageCropperModel> {
    public imageFile: File | null = null;
    public photoType: number | null = null;
    public aspect: number | null = null;
    public files = observable<FileModel>([]);
    public isBuildStageAdmin: boolean = false;
    constructor() {
        super();
        makeObservable(this, {});
    }

    public get aspectOrDefault() {
        return this.aspect ? this.aspect : 4 / 3;
    }
}

export class FileModel extends ModelBase<FileModel> {
    public file: File | null = null;
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            file: observable,
            fileName: observable,
            fileSizeBytes: observable,
            mimeType: observable,
            documentUrl: observable,
        });
    }
}

export class ImageCropperModelValidator extends Validator<ImageCropperModel> {
    constructor() {
        super();
    }
}
