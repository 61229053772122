import React from "react";
import { observer } from "mobx-react-lite";
import { IdleTimer } from "./IdleTimer";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain/AccountStores";

export interface IProps {
    children: React.ReactNode;
}

export const IdleTimerContainer: React.FC<IProps> = observer((props) => {
    let idleTimerRef: any;

    const accountStore = container.resolve(AccountStore);

    const onIdle = () => {
        if (accountStore.IsLoggedIn) {
            accountStore.Logout();
            idleTimerRef.reset();
        }
    };

    const onActive = () => {
        idleTimerRef.reset();
        idleTimerRef.start();
    };

    return (
        <>
            <IdleTimer ref={(ref) => (idleTimerRef = ref)} timeout={1000 * 60 * 30} onIdle={onIdle} onActive={onActive}>
                {props.children}
            </IdleTimer>
        </>
    );
});
