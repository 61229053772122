import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TodoItemViewModel } from "./TodoItemViewModel";
import { DocumentCard, DocumentCardTitle } from "@fluentui/react";

interface IProps {
    viewModel: TodoItemViewModel;
}
export const TodoItem: React.FC<IProps> = observer((props: IProps) => {
    return (
        <>
            <DocumentCard aria-label="Default Document Card with large file name. Created by Annie Lindqvist a few minutes ago.">
                <DocumentCardTitle title={props.viewModel.getValue("title")} />
            </DocumentCard>
        </>
    );
});
