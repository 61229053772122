import { makeObservable, observable, observe } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { YourPurchaseModel, YourPurchaseModelValidator } from "./YourPurchaseModel";
import { GETYourPurchaseEndpoint } from "./Endpoints/GETPurchaseStagesEndpoint";
import { PurchaseStagesViewModel } from "./PurchaseStages/PurchaseStagesViewModel";
import { POSTPurchaseStageAdminEndpoint } from "./Endpoints/POSTPurchaseStageAdminEndpoint";

export class YourPurchaseViewModel extends ViewModelBase<YourPurchaseModel> {
    public apiClient = new APIClient();
    public isCancel = false;
    public isSave = false;
    public stagesViewModels = observable<PurchaseStagesViewModel>([]);

    constructor(model = new YourPurchaseModel()) {
        super(model);
        this.setValidator(new YourPurchaseModelValidator());
        makeObservable(this, { isCancel: observable, isSave: observable, stagesViewModels: observable });
        this.apiClient.sendAsync(new GETYourPurchaseEndpoint(this), this.model);
    }

    public reSetCommand: ICommand = new RelayCommand(() => {
        this.isCancel = true;
        this.apiClient.sendAsync(new GETYourPurchaseEndpoint(this), this.model);
    });

    public updateCommand: ICommand = new RelayCommand(() => {
        this.isSave = true;
        this.apiClient.sendAsync(new POSTPurchaseStageAdminEndpoint(this), this.model);
        // const canSubmitForm = this.stagesViewModels.every((vm) => vm.isModelValid());
        // if (canSubmitForm) {
        //     this.apiClient.sendAsync(new POSTPurchaseStageAdminEndpoint(this), this.model);
        // }
    });

    public dispose = (): void => {
        this.stagesModelsObserverDispose();
    };

    public get getStagesSelected() {
        return this.stagesViewModels.filter((stagesViewModels) => {
            return stagesViewModels.getValue("isVisible");
        }).length;
    }

    public get IsEdit() {
        return this.stagesViewModels.find((stagesViewModels) => {
            return stagesViewModels.isEdit;
        });
    }

    private stagesModelsObserverDispose = observe(this.model.stagesModels, (childModelChanges: any) => {
        for (const addedChildModel of childModelChanges.added) {
            this.stagesViewModels.push(new PurchaseStagesViewModel(addedChildModel));
        }
        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.stagesViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);
            if (childViewModelToRemove) {
                this.stagesViewModels.remove(childViewModelToRemove);
            }
        }
    });
}
