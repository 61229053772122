import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle: boolean;
}
export const YourHomeSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, ...rest } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_8266" data-name="Group 8266" transform="translate(-405 -571)">
                <g id="Group_8264" data-name="Group 8264">
                    <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(405 571)" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="23.75" fill="none" />
                    </g>
                </g>
                <g id="Group_8265" data-name="Group 8265">
                    <path
                        id="Path_3847"
                        data-name="Path 3847"
                        d="M28.981,21.635a.47.47,0,0,0-.623,0l-9.277,8.19a.47.47,0,0,0-.159.353V41.6a.47.47,0,0,0,.47.47h3.082a.47.47,0,0,0,.47-.47V33.9h3.921v7.7a.47.47,0,0,0,.47.47h10.61a.47.47,0,0,0,.47-.47V30.178a.472.472,0,0,0-.159-.353Zm1.626,15.1V33.9h3.921v2.835Z"
                        transform="translate(401.83 565.164)"
                        fill="#fefefe"
                    />
                    <path
                        id="Path_3848"
                        data-name="Path 3848"
                        d="M34.987,23.7l-2.593-2.289v-4.5a.47.47,0,0,0-.47-.47H30.016a.47.47,0,0,0-.47.47V18.9l-6.588-5.816-.014-.011c-.013-.011-.026-.02-.04-.029s-.025-.016-.038-.023L22.822,13c-.014-.006-.028-.011-.043-.016a.443.443,0,0,0-.044-.01.431.431,0,0,0-.044-.007l-.046,0H22.6l-.047.008-.041.01a.615.615,0,0,0-.085.035l-.039.024c-.013.009-.027.018-.04.029l-.014.011L10.3,23.7a.47.47,0,0,0,.623.705L22.646,14.061,34.364,24.406a.47.47,0,1,0,.623-.705Z"
                        transform="translate(407.854 571.035)"
                        fill="#fefefe"
                    />
                </g>
            </g>
        </svg>
    );
};
