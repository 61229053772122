import React, { useEffect, useState } from "react";
import { AutoGrid } from "Components/Grid";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Box, ThemedEditText, Button, Label, ThemedComboBox } from "../../../../Application";
import UserDetailsViewModel from "./UserDetailsViewModel";
import { BackPageSVG } from "Assets/SVGs/BackPage";
import arrow from "Assets/lockLeftArrow.png";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { container } from "tsyringe";
import { AccountStore } from "../../../../Stores/Domain";

export const ContainerBox = styled(Box)``;

export const DetailContainer = styled(Box)`
    position: relative;
    .ms-TextField-wrapper {
        span,
        label {
            font: normal normal bold 14px / 17px Lato;
            letter-spacing: 0px;
            color: #4a4a4a;
            text-transform: capitalize;
        }
    }
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

const UserForm = styled(Box)`
    span,
    .ms-Label {
        font: normal normal bold 14px / 17px Lato;
        letter-spacing: 0px;
        color: #4a4a4a;
        text-transform: capitalize;
        margin: 0 0 6px;
        padding: 0;
    }
    .ms-TextField-wrapper {
        & > div:first-child {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
    .ms-TextField-fieldGroup,
    .ms-ComboBox {
        height: 32px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
        border: 1px solid #cccbcb;
        padding-left: 0 !important;
        &:after {
            display: none !important;
        }
        &:focus,
        &:focus-within {
            border: 1px solid #f47e44 !important;
        }
    }
    input {
        padding: 8px 15px;
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .ms-TextField-errorMessage {
        padding-top: 5px;
        span {
            text-transform: capitalize;
            color: #ce4e5b;
            font: normal normal normal 12px/14px Lato;
        }
    }
`;
const optionStyles: any = {
    optionText: { textTransform: "capitalize" },
};
const Styles: any = {
    input: { textTransform: "capitalize" },
};
export const UserDetails: React.FC = observer(() => {
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new UserDetailsViewModel());
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [newUser, setNewUser] = useState(false);
    let params = useParams();
    let id = params.id || "new";

    useEffect(() => {
        if (!accountStore.isInRole("admin") && !accountStore.isInRole("sales admin")) {
            viewModel.history.push("/");
        }
        if (id && id === "new") {
            setNewUser(true);
        } else {
            let _ = viewModel.loadUserAsync(id);
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
    };

    return (
        <>
            <Box background={"#4A4A4A"} padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"0"}
                        style={{
                            letterSpacing: "-0.24px",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                            opacity: 1,
                            font: "normal normal 300 24px/30px Lato",
                        }}
                    >
                        ADMIN - {newUser ? "New Admin user" : `${viewModel.firstName}   ${viewModel.lastName}`}
                    </Label>
                </Container>
            </Box>
            <Box padding={"30px 15px"}>
                <Container id={"rootcontainer"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} minHeight={"70vh"}>
                    <UserForm>
                        <Box
                            flexBox
                            mb={"22px"}
                            alignItems={"center"}
                            width={"100%"}
                            height={"30px"}
                            onClick={() => viewModel.cancelSaveUserCommand.execute()}
                            style={{
                                cursor: "pointer",
                                gap: "8px",
                                letterSpacing: "-0.18px",
                                color: "#2A71CD",
                                font: "normal normal bold 18px/30px Lato",
                            }}
                        >
                            <BackPageSVG /> Back to admin users
                        </Box>
                        <Box display={"flex"} alignItems="center" flexWrap={"wrap"} justifyContent="space-between" mb={"32px"} style={{ gap: "12px 0" }}>
                            <Label mt={"0"} fontStyle={"h3"} style={{ font: "normal normal bold 18px/26px Lato", letterSpacing: "-0.31px", color: "#4A4A4A" }}>
                                {newUser ? "New User" : `${viewModel.firstName}   ${viewModel.lastName}`}
                            </Label>
                        </Box>

                        <Box>
                            <form onSubmit={doSubmit} id="detailsPanelForm" style={{ paddingBottom: "30px" }}>
                                <DetailContainer>
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} marginBottom={"26px"}>
                                        <ThemedEditText
                                            displayName="First Name"
                                            value={() => viewModel.getValue("firstName")}
                                            command={viewModel.updateFirstNameCommand}
                                            validationMessage={() => viewModel.getError("firstName")}
                                            autoFill={false}
                                        />
                                        <ThemedEditText
                                            displayName="Last Name"
                                            value={() => viewModel.getValue("lastName")}
                                            command={viewModel.updateLastNameCommand}
                                            validationMessage={() => viewModel.getError("lastName")}
                                            autoFill={false}
                                        />
                                    </AutoGrid>
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} marginBottom={"26px"}>
                                        <ThemedEditText
                                            displayName="Email Address"
                                            value={() => viewModel.getValue("emailAddress")}
                                            command={viewModel.updateEmailAddressCommand}
                                            validationMessage={() => viewModel.getError("emailAddress")}
                                            autoFill={false}
                                        />
                                    </AutoGrid>
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"}>
                                        {/* <ThemedComboBoxMultiSelect
                                            displayName="Roles"
                                            values={() => viewModel.getValue("userRoles")}
                                            options={viewModel.getRoles()}
                                            validationMessage={() => viewModel.getError("userRoles")}
                                            placeholder="Please Select"
                                            command={viewModel.updateSelectRolesCommand}
                                        /> */}
                                        <ThemedComboBox
                                            displayName="Roles"
                                            value={() => viewModel.getValue("userRoles")}
                                            options={viewModel.getRoles()}
                                            validationMessage={() => viewModel.getError("userRoles")}
                                            placeholder="Please Select"
                                            styles={Styles}
                                            optionStyles={optionStyles}
                                            command={viewModel.updateSelectRolesCommand}
                                        />
                                    </AutoGrid>
                                </DetailContainer>
                            </form>
                        </Box>
                        <div>{viewModel.apiClient.IsBusy ? "" : ""}</div>
                        <div className="error">{viewModel.resetLoginAttemptsError}</div>
                    </UserForm>
                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} pt={"30px"} borderTop={"1px solid #bebebe"}>
                        {!newUser && (
                            <Box display={"flex"} justifyContent="flex-end">
                                <Button
                                    paletteColor={"secondary"}
                                    type="submit"
                                    displayName={
                                        !viewModel.apiClient.IsBusy ? (
                                            <Box display={"flex"} justifyContent="center" alignItems={"center"} style={{ gap: "5px" }}>
                                                <img src={arrow} className="img-fluid" alt="image" style={{ width: "19px" }} />
                                                Issue new password
                                            </Box>
                                        ) : (
                                            "Please wait..."
                                        )
                                    }
                                    command={viewModel.forgotPasswordCommand}
                                    style={{
                                        background: "#2A71CD",
                                        maxWidth: isMobile ? "100%" : "280px",
                                        width: isMobile ? "100%" : "280px",
                                        borderRadius: 0,
                                        height: "30px",
                                        font: "normal normal bold 12px/15px Lato",
                                        letterSpacing: "0.36px",
                                        color: "#FFFFFF",
                                        flex: isMobile ? "100% 0 0" : "280px 0 0",
                                    }}
                                />
                            </Box>
                        )}
                        <Button
                            paletteColor={"secondary"}
                            type="submit"
                            displayName={id === "new" ? "Create" : !viewModel.apiClient.IsBusy ? "Save" : "Saving...."}
                            command={viewModel.saveUserCommand}
                            style={{
                                background: "#2A71CD",
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: 0,
                                height: "30px",
                                font: "normal normal bold 12px/15px Lato",
                                letterSpacing: "0.36px",
                                color: "#FFFFFF",
                            }}
                        />
                        <Button
                            paletteColor={"error"}
                            displayName={"Cancel"}
                            command={viewModel.cancelSaveUserCommand}
                            style={{
                                background: "#4A4A4A",
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: 0,
                                height: "30px",
                                font: "normal normal bold 12px/15px Lato",
                                letterSpacing: "0.36px",
                                color: "#FFFFFF",
                            }}
                        />
                    </AutoGrid>
                </Container>
            </Box>
        </>
    );
});
