import { IModalStyles, Modal } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { LoadingSpinner } from "../../../../LoadingSpinner1";
import { Box } from "../../../../General/Box";

/**
 * Loader interface.
 */
export interface ILoaderBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional class name for use with the button.
     */
    isOpen: boolean;
    /**
     * An optional delay in seconds before showing loader in ms
     */
    delayBeforeOpen?: number;
    /**
     * An optional delay in seconds before showing loader in ms
     */
    displayText?: string;
    /**
     * Styling of the modal.
     */
    styles?: IModalStyles;
}

export const LoaderBase: React.FC<ILoaderBaseProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    // #endregion Code Behind

    return (
        <Modal className={getClasseNames()} isOpen={props.isOpen} styles={props.styles}>
            <Box id={"themedloader"} center>
                <LoadingSpinner />
                <div>{props.displayText}</div>
            </Box>
        </Modal>
    );
});
LoaderBase.defaultProps = {
    delayBeforeOpen: 1000,
    displayText: "Please wait...",
};
