import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PanelModel } from "./Panel/PanelModel";

export class DashboardModel extends ModelBase<DashboardModel> {
    public panelModel: PanelModel = new PanelModel();

    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class DashboardModelValidator extends Validator<DashboardModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
