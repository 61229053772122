import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { TextBase, ITextBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/TextBase";
import { theme } from "../../AppTheme";
import { getThemeColorOption, getThemeFontStyleOption, getThemePaletteOption } from "../../../Application/Helpers";
import styled from "@emotion/styled";
import { space, SpaceProps } from "styled-system";
import { observer } from "mobx-react-lite";

const InternalText = styled(TextBase)<SpaceProps>`
    {...props}
    ${space};
`;
/**
 * Styled text. The styling can be adjusted using the variant and paletteColor props.
 *
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedText = observer((props: PropsWithChildren<ITextBaseProps & SpaceProps>) => {
    const color = getThemeColorOption(props.color);
    const font = getThemeFontStyleOption(props.fontStyle);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <InternalText
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        color: color,
                        fontFamily: font.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: font.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: font.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: font.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: font.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: font.textTransform ?? theme.defaultFontStyle.textTransform,
                        display: "block", //I think the default should be block
                    },
                },
                props.styles,
            )}
        />
    );
});

// #region Directly-styled text
// #endregion
