import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import * as sa from "Application";
import { Link, Routes } from "react-router-dom";
import { Route } from "react-router";
import styled from "@emotion/styled";
import { DashboardView } from "./Dashboard/DashboardView";
import { YourhomeView } from "./Yourhome/YourhomeView";
import { BuildprogressView } from "./Buildprogress/BuildprogressView";
import { SalesprogressView } from "./Salesprogress/SalesprogressView";
import { DocumentsView } from "./Documents/DocumentsView";
import { ChoicesView } from "./Choices/ChoicesView";
import { CustomercareView } from "./Customercare/CustomercareView";

const Header = styled(sa.Box)`
    width: 100%;
    background-color: #2484ff;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    gap: 30px;
    padding: 15px;
    a:visited {
        color: white;
    }
`;

export const PreviewView: React.FC = observer(() => {
    return (
        <sa.Box>
            <Header flexBox>
                <Link to={"/preview/dashboard"}>Dashboard</Link>
                <Link to={"/preview/yourhome"}>Yourhome</Link>
                <Link to={"/preview/buildprogress"}>Buildprogress</Link>
                <Link to={"/preview/salesprogress"}>Salesprogress</Link>
                <Link to={"/preview/documents"}>Documents</Link>
                <Link to={"/preview/choices"}>Choices</Link>
                <Link to={"/preview/customercare"}>Customercare</Link>
            </Header>
            <sa.Box mt={3}>
                <Routes>
                    <Route path="dashboard" element={<DashboardView />} />)
                    <Route path="yourhome" element={<YourhomeView />} />)
                    <Route path="buildprogress" element={<BuildprogressView />} />)
                    <Route path="salesprogress" element={<SalesprogressView />} />)
                    <Route path="documents" element={<DocumentsView />} />)
                    <Route path="choices" element={<ChoicesView />} />)
                    <Route path="customercare" element={<CustomercareView />} />)
                </Routes>
            </sa.Box>
        </sa.Box>
    );
});
