import { mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { PropsWithChildren } from "react";

import { theme } from "../../../Styles/AppTheme";
import { observer } from "mobx-react-lite";
import { DataTableBase, IDetailsListProps } from "../../index";

/**
 * A styled datalist control. The styling can be adjusted using the size and paletteColor props.
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedDataTable = observer((props: PropsWithChildren<IDetailsListProps>) => {
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                //EN: More styling props needed for the datalist.
                {
                    check: {
                        ".ms-Check": {
                            backgroundColor: "green",
                        },
                    },
                    root: {
                        /*".ms-Check.is-checked::before": {
                            backgroundColor: "green",
                        },*/
                        ".ms-DetailsHeader-cellName": {
                            color: theme.palette.tableHeader.text,
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.tableHeader.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                        },
                    },
                    headerWrapper: {},
                    subComponentStyles: {},
                },
                props.styles,
            )}
        />
    );
});

// #endregion Directly-styled buttons
