import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GETDocumentByIdEndpoint extends EndpointWithoutRequest<Response> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.responseIsBlob(true);
        this.path("/api/getdocument/" + id);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        window.open()!.location.href = window.URL.createObjectURL(response);
        return Promise.resolve();

        // if (response.type === "application/pdf") {
        //     const blob = new Blob([response], { type: "application/octet-stream" });
        //     const fileExtension = response.type.split("/")[1];
        //     const fileName = `file.${fileExtension}`;
        //     // const fileName = "response.pdf";
        //     const anchorElement = document.createElement("a");
        //     anchorElement.href = window.URL.createObjectURL(blob);
        //     anchorElement.download = fileName;
        //     document.body.appendChild(anchorElement);
        //     anchorElement.click();
        //     document.body.removeChild(anchorElement);
        // } else {
        //     window.open()!.location.href = window.URL.createObjectURL(response);
        // }
        // return Promise.resolve();
    }
}
