import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren } from "react";
import {
    themeColourOptions,
    themeFontStyleOptions,
    CommonColours,
    themePaletteOptions,
    Box,
    theme,
    getThemeColorOption,
    getThemeFontStyleOption,
    FontStyles,
    FontVariant,
} from "Application";
import styled from "@emotion/styled";
import { space, SpaceProps } from "styled-system";

const InternalLabel = styled.label<SpaceProps>`
    {...props}
    ${space}; 
`;
interface Props {
    color: string;
    fontStyle: FontVariant;
}

const StyledLabel = styled(InternalLabel)<Props>`
    /*  Theme properties*/
    color: ${(p) => p.color};
    font-family: ${(p) => p.fontStyle.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${(p) => p.fontStyle.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${(p) => p.fontStyle.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${(p) => p.fontStyle.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${(p) => p.fontStyle.lineHeight ?? theme.defaultFontStyle.lineHeight};
    text-transform: ${(p) => p.fontStyle.textTransform ?? theme.defaultFontStyle.textTransform};
    /*End theme properties*/
    /* Resets */
    transition: 0.25s ease all;
    display: block;
    /* pointer-events: none; */
    /* End Resets */
`;

/**
 * Text interface.
 */
type ILabelBaseProps = {
    /**
     * An optional class name for use with the text.
     */
    className?: string;
    /**
     * Styling of the control.
     */
    style?: CSSProperties | undefined;
    /**
     * The text variant.
     */
    fontStyle?: themeFontStyleOptions;
    /**
     * The color of the text - use this if using the generic text.
     */
    color?: themeColourOptions;
    htmlFor?: string;
    ref?: React.Ref<HTMLLabelElement>;
} & SpaceProps;

export const Label: React.FC<PropsWithChildren<ILabelBaseProps>> = observer((props: PropsWithChildren<ILabelBaseProps>) => {
    //console.log(props.fontStyle);
    // #region Code Behind
    const color = getThemeColorOption(props.color);
    const font = getThemeFontStyleOption(props.fontStyle);

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getStyles = (): CSSProperties | undefined => {
        return !isNullOrUndefined(props.style) ? props.style : undefined;
    };

    // #endregion Code Behind

    return (
        <StyledLabel
            ref={props.ref}
            m={props.m}
            p={props.p}
            mt={props.mt || 1}
            mr={props.mr}
            mb={props.mb}
            ml={props.ml}
            pt={props.pt}
            pr={props.pr}
            pb={props.pb}
            pl={props.pl}
            color={color}
            fontStyle={font}
            style={getStyles()}
            className={getClasseNames()}
            htmlFor={props.htmlFor}
        >
            {props.children}
        </StyledLabel>
    );
});

Label.defaultProps = {
    //fontStyle: "h3",
};
