import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand, Show, ThemedButton, ThemedText } from "Application";
import styled from "@emotion/styled";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { ErrorExclamationView } from "./ErrorExclamation";
import { Spacer } from "./Spacer";

export const Signature = styled.span`
    font-family: "Homemade Apple", serif;
    font-size: 40px;
    padding: 15px;
    background-color: #e3e3e3;
`;

interface Props {
    fullName: string;
    confirmedDate: Date | null;
    command: ICommand;
    validationMessage?: () => string;
}
export const SignatureView: React.FC<Props> = observer((props: Props) => {
    const onConfirmCommand = new RelayCommand(() => {
        props.command.execute();
    });

    const haveError = () => {
        try {
            return !isEmptyOrWhitespace(props.validationMessage?.() as string);
        } catch {}
        return false;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    return (
        <Box mt={3}>
            <Spacer pt={1} />
            <Box pb={2}>
                <Signature>{props.fullName}</Signature>
            </Box>
            <Show if={!props.command.canExecute()}>
                <ThemedText mt={3} fontStyle={"h4"}>{`${props.fullName} has signed this form electronically at ${props.confirmedDate}`}</ThemedText>
            </Show>
            <Show if={props.command.canExecute()}>
                <Box display={"flex"}>
                    <ThemedButton mt={3} paletteColor={"primary"} displayName={"Confirm Signature"} command={onConfirmCommand} />
                    <Box marginTop={"20px"}>
                        <ErrorExclamationView haveError={haveError()} validationMessage={getValidationMessage} />
                    </Box>
                </Box>
            </Show>
        </Box>
    );
});
