import { Stores } from "./Stores";

export class GlobalAppUrls {
    public Client = {
        Home: "/",
        Theme: "/theme",
        StyleSheet: "/stylesheet",
        ColoursMUI: "/coloursmui",
        Samples: "/samples/text",
        Error: "/error",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        Terms: "/terms",
        Test: "/test",
        YourHome: "/yourhome",
        BuildProgress: "/buildprogress",
        SalesProgress: "/salesprogress",
        Documents: "/documents",
        Choices: "/choices/",
        CustomerCare: "/customerexperience/",
        SalesStageAdmin: "/salesstageadmin/",
        BuildStageAdmin: "/buildstageadmin/",
        CustomerCareCurrentSnags: "/customerexperience/currentsnags",
        PageBuilder: "/pagebuilder",
        Todo: "/todo",
        Blank: "/blank",
        Demo: "/application/personalinfo",
        Account: {
            Login: "/account/login",
            Logout: "/logout",
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            ResetPassword: "/account/resetpassword/:token",
            Register: "/account/register",
            ConfirmEmail: "/account/confirmemail/:token",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            PortalHome: "/admin/dashboard/portaluserslist",
            LogViewer: "/admin/logviewer",
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
            User: {
                UnlockUser: "/api/user/unlock",
                DeleteUser: "/api/user/delete",
                DeleteRole: "/api/role/delete",
            },
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            GetRoles: "/api/roles/getall",
        },
        BuildStageAdmin: {
            GetBuildStagesAdmin: "/api/getbuildstagesadmin",
        },
        SalesStageAdmin: {
            GetSalesStagesAdmin: "/api/getsalesstagesadmin",
        },
    };
}
export const AppUrls = new GlobalAppUrls();
