import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { IconButtonBase, IIconButtonBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/IconButtonBase";
import { theme } from "../../AppTheme";
import { getThemePaletteOption, getThemeShapeOption, getThemeSizeOption, pxToRem } from "../../../Application/Helpers";

/**
 * A styled icon button. The styling can be adjusted using the size and paletteColor props.
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedIconButton = (props: PropsWithChildren<IIconButtonBaseProps>) => {
    const size = getThemeSizeOption(props.size);
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const shape = getThemeShapeOption(props.shape);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <IconButtonBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        backgroundColor: paletteColor.light,
                        borderRadius: shape,
                        borderColor: paletteColor.light,
                        color: paletteColor.text,
                        height: size,
                        width: size,
                    },
                    rootFocused: {
                        backgroundColor: paletteColor.light,
                        color: paletteColor.text,
                        "::after": {
                            // Fixes issues with square outlines on rounded buttons with focus.
                            outline: "none !important",
                            inset: "1px !important",
                            border: `1px solid ${paletteColor.text} !important`,
                            borderRadius: shape,
                        },
                    },
                    rootHovered: {
                        backgroundColor: paletteColor.main,
                        borderColor: paletteColor.main,
                        color: paletteColor.text,
                    },
                    rootPressed: {
                        backgroundColor: paletteColor.dark,
                        borderColor: paletteColor.dark,
                        color: paletteColor.text,
                    },
                    rootDisabled: {
                        backgroundColor: theme.palette.disabled.main,
                        borderColor: theme.palette.disabled.main,
                        color: theme.palette.disabled.text,
                    },
                    icon: {
                        fontSize: pxToRem(14),
                    },
                    iconDisabled: {
                        stroke: theme.palette.disabled.text,
                        g: {
                            stroke: theme.palette.disabled.text,
                        },
                        line: {
                            stroke: theme.palette.disabled.text,
                        },
                        path: {
                            fill: theme.palette.disabled.text,
                        },
                    },
                },
                props.styles,
            )}
        />
    );
};

// #region Directly-styled buttons

/**
 * A large default-styled button.
 */
export const ThemedDefaultIconButton = (props: PropsWithChildren<IIconButtonBaseProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <IconButtonBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        backgroundColor: theme.palette.default.light,
                        borderColor: theme.palette.default.light,
                        color: theme.palette.default.text,
                        height: theme.sizes.large,
                        width: theme.sizes.large,
                    },
                    rootFocused: {
                        backgroundColor: theme.palette.default.light,
                        color: theme.palette.default.text,
                        "::after": {
                            outlineColor: `${theme.palette.default.text} !important`,
                        },
                    },
                    rootHovered: {
                        backgroundColor: theme.palette.default.main,
                        borderColor: theme.palette.default.main,
                        color: theme.palette.default.text,
                    },
                    rootPressed: {
                        backgroundColor: theme.palette.default.dark,
                        borderColor: theme.palette.default.dark,
                        color: theme.palette.default.text,
                    },
                    rootDisabled: {
                        backgroundColor: theme.palette.disabled.main,
                        borderColor: theme.palette.disabled.main,
                        color: theme.palette.disabled.text,
                    },
                    icon: {
                        fontSize: pxToRem(14),
                    },
                    iconDisabled: {
                        stroke: theme.palette.disabled.text,
                        g: {
                            stroke: theme.palette.disabled.text,
                        },
                        line: {
                            stroke: theme.palette.disabled.text,
                        },
                        path: {
                            fill: theme.palette.disabled.text,
                        },
                    },
                },
                props.styles,
            )}
        />
    );
};

export const ShootDefaultTrIconButton = (props: PropsWithChildren<IIconButtonBaseProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <IconButtonBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        backgroundColor: theme.palette.defaultTr.light,
                        borderColor: theme.palette.defaultTr.light,
                        color: theme.palette.defaultTr.text,
                        height: theme.sizes.large,
                        width: theme.sizes.large,
                    },
                    rootFocused: {
                        backgroundColor: theme.palette.defaultTr.light,
                        color: theme.palette.defaultTr.text,
                        "::after": {
                            outlineColor: `${theme.palette.defaultTr.text} !important`,
                        },
                    },
                    rootHovered: {
                        backgroundColor: theme.palette.defaultTr.main,
                        borderColor: theme.palette.defaultTr.main,
                        color: theme.palette.defaultTr.text,
                    },
                    rootPressed: {
                        backgroundColor: theme.palette.defaultTr.dark,
                        borderColor: theme.palette.defaultTr.dark,
                        color: theme.palette.defaultTr.text,
                    },
                    rootDisabled: {
                        backgroundColor: theme.palette.disabled.main,
                        borderColor: theme.palette.disabled.main,
                        color: theme.palette.disabled.text,
                    },
                    icon: {
                        fontSize: pxToRem(14),
                        stroke: theme.palette.defaultTr.text,
                        g: {
                            stroke: theme.palette.defaultTr.text,
                        },
                        line: {
                            stroke: theme.palette.defaultTr.text,
                        },
                        path: {
                            fill: theme.palette.defaultTr.text,
                        },
                    },
                    iconDisabled: {
                        stroke: theme.palette.disabled.text,
                        g: {
                            stroke: theme.palette.disabled.text,
                        },
                        line: {
                            stroke: theme.palette.disabled.text,
                        },
                        path: {
                            fill: theme.palette.disabled.text,
                        },
                    },
                },
                props.styles,
            )}
        />
    );
};

// #endregion
