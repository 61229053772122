import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { PanelModel, PanelModelValidator } from "./PanelModel";
import { Logger } from "index";

export class PanelViewModel extends ViewModelBase<PanelModel> {
    public apiClient = new APIClient();

    constructor(model = new PanelModel()) {
        super(model);
        this.setValidator(new PanelModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands
    public updateTitleCommand = new RelayCommand((value: string) => {
        this.setValue("title", value);
    });
    public updateDescriptionCommand = new RelayCommand((value: string) => {
        this.setValue("description", value);
    });
    public updateLastupdatedCommand = new RelayCommand((value: string) => {
        this.setValue("lastUpdated", value);
    });

    //#endregion Commands
}
