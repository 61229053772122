import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../../../../Application/Validation";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel> {
    public newPassword: string = "";
    public confirmPassword: string = "";
    public token: string = "";

    constructor() {
        super();
        makeObservable(this, {
            newPassword: observable,
            confirmPassword: observable,
            token: observable,
        });
    }
}

export class ResetPasswordValidator extends Validator<ResetPasswordModel> {
    constructor() {
        super();

        this.ruleFor("newPassword")
            .notEmpty()
            .withMessage("Please enter a new password")
            .matches(/(?=.*[A-Z])(?=.*[!^%&*()#])(?=.*[0-9])(?=.*[a-z]).{6,}$/)
            .withMessage("Password must contain at least 6 characters, including UPPER/lowercase, special character (!^%&*()#) and numbers.");
        this.ruleFor("confirmPassword")
            .notEmpty()
            .withMessage("Please enter a confirm Password")
            .maxLength(100)
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.newPassword, message: (value, model) => "Passwords must match" });
    }
}
