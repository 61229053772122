import { Checkbox, ICheckboxStyles, ILabelStyles, ITextStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { getThemeColorOption, getThemePaletteOption } from "Application/Helpers/StyleHelpers";
import { Box, ErrorExclamationView } from "Components/index";
import { AccentColours, ICommand, theme, themeColourOptions, themePaletteOptions } from "Application";
import styled from "@emotion/styled";
import { SpaceProps } from "styled-system";
import { Label } from "../Labels/Label";

type Props = {
    paletteColour: AccentColours;
} & SpaceProps;
const StyledCheckBox = styled.label<Props>`
    font-family: system-ui, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    align-items: center;

    .form-control + .form-control {
        margin-top: 1em;
    }

    .form-control--disabled {
        color: var(--form-control-disabled);
        cursor: not-allowed;
    }

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        -moz-appearance: none;
        appearance: none;
        /* For iOS < 15 */
        background-color: white;
        /* Not removed via appearance */
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 1px solid ${theme.palette.field.light};
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: center;
        transition: 250ms transform ease-in-out;
        box-shadow: inset 1em 1em ${(p) => p.paletteColour.main};
        /* Windows High Contrast Mode */
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    /*input[type="checkbox"]:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
    }*/

    input[type="checkbox"]:disabled {
        --form-control-color: lightgrey;
        color: lightgray;
        cursor: not-allowed;
    }
`;

/**
 * Checkbox interface.
 */
interface ICheckBoxBaseProps {
    /**
     * An optional class name for use with the checkbox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the checkbox. Will be passed back by the command.
     */
    value: () => boolean;
    /**
     * Text content to display on the checkbox.
     */
    displayName?: string;
    /**
     * Used if we have an array of checkboxes
     */
    index?: number;
    /**
     * Styling of the checkbox.
     */
    styles?: ICheckboxStyles;
    /**
     * The color of the checkbox - use this if using the generic checkbox.
     */
    paletteColor?: themePaletteOptions;
    /**
     * Get validation message
     */
    validationMessage?: () => string;
    /**
     * Styling for the error message.
     */
    errorStyles?: ITextStyles;

    onRenderLabel?: (isInError: boolean) => JSX.Element;

    labelColor?: themeColourOptions;

    description?: string;
}

export const CheckBox: React.FC<ICheckBoxBaseProps> = observer((props) => {
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const isChecked = !isNullOrUndefined(props.value) ? props.value() : false;

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (props.command.execute) {
            props.command.execute(event.target.checked, props.index);
        }
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getErrorStyles = (): Partial<ICheckboxStyles> | undefined => {
        return !isNullOrUndefined(props.errorStyles) ? props.errorStyles : { root: { color: "red" } };
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };
    console.log(props.value());
    return (
        <Box alignSelf={"end"}>
            <StyledCheckBox paletteColour={paletteColor} className="form-control">
                <input type="checkbox" name="checkbox" checked={props.value()} onChange={onChange} disabled={isDisabled()} />

                <Box style={{ display: "flex", columnGap: "0" }}>
                    <Label color={props.labelColor}>{props.displayName}</Label>
                    <Box mt={"-20px"}>
                        <ErrorExclamationView haveError={canDisplayErrorMessage()} validationMessage={() => getValidationMessage()} />
                    </Box>
                </Box>
            </StyledCheckBox>
            {/* <Checkbox
                    checked={props.value()}
                    className={getClassNames()}
                    label={props.displayName}
                    disabled={isDisabled()}
                    onChange={onChange}
                    styles={props.styles}
                    onRenderLabel={() => onRenderLabel(getValidationMessage() !== "")}
                />*/}
            {/*{canDisplayErrorMessage() && <TextBase styles={getErrorStyles()}>{getValidationMessage()}</TextBase>}*/}
        </Box>
    );
});
