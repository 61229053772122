import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { YourhomeViewModel } from "../YourhomeViewModel";

import { YourhomeModel } from "../YourhomeModel";
import { ModelBase } from "@shoothill/core";
class Response {
    public coinsPlotId: string | null = "";
    public developmentName: string | null = "";
    public plotNumber: string | null = "";
    public houseTypeName: string | null = "";
    public fullAddress: string | null = "";
    public salesAgentName: string | null = "";
    public marketingPrice: number = 0;
    public deposit: number = 0;
    public buildCompletionActualDateUTC: string | null = "";
    public buildCompletionTargetDateUTC: string | null = "";
    public choicesCompleteActualDateUTC: string | null = "";
    public choicesCompleteTargetDateUTC: string | null = "";
    public contractExchangeActualDateUTC: string | null = "";
    public contractExchangeDeadlineDateUTC: string | null = "";
    public legalCompletionActualDateUTC: string | null = "";
    public legalCompletionTargetDateUTC: string | null = "";
    public developmentContactNumber: string | null = "";
    public developmentEmail: string | null = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    public incentives: number = 0;
    public purchasedUpgrades: number = 0;
    public netPurchasePrice: number = 0;
    public nonCashIncentivesQuantity: string | null = "";
    public nonCashIncentivesDescription: string | null = "";
    public houseTypeImages: HouseTypeImageResponse[] = [];
}

export class HouseTypeImageResponse extends ModelBase {
    public id: string = "";
    public companyId: string = "";
    public coinsDevelopmentId: number = 0;
    public coinsHouseTypeId: number = 0;
    public fileName: string = "";
    public fileURL: string = "";
}

// class HouseTypeImageResponse {
//     public id: string = "";
//     public companyId: string = "";
//     public coinsDevelopmentId: number = 0;
//     public coinsHouseTypeId: number = 0;
//     public fileName: string = "";
//     public fileURL: string = "";
// }

export class GETPlotDetailEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: YourhomeViewModel;
    constructor(viewModel: YourhomeViewModel, coinsPlotId: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/getyourhome/" + coinsPlotId);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        this.viewModel.model.fromResponse(response);
        response.houseTypeImages.forEach((item) => {
            let model: HouseTypeImageResponse = {} as HouseTypeImageResponse;
            model.id = item.id;
            model.companyId = item.companyId;
            model.coinsDevelopmentId = item.coinsDevelopmentId;
            model.coinsHouseTypeId = item.coinsHouseTypeId;
            model.fileName = item.fileName;
            model.fileURL = item.fileURL;
            this.viewModel.model.houseTypeImages.push(item);
        });
    }
}
