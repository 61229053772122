import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { CustomercareViewModel } from "./CustomercareViewModel";
import { uniqueId } from "lodash-es";
import { useParams } from "react-router";
import { Box } from "../../Components";
import { HorizontalSnagBlock } from "../../Components/HorizontalSnagBlock";
import { HorizontalBlock } from "../../Components/HorizontalBlock";

interface Props {
    viewModel: CustomercareViewModel;
}
export const SnagListView: React.FC<Props> = observer((props: Props) => {
    const { category } = useParams();
    console.log(props.viewModel.snags);
    return (
        <Box ml={"30px"} mr={"30px"} mt={"30px"}>
            {props.viewModel.snags(category as string).map((snag) => {
                console.log(snag.dateClosed);
                //return <div key={uniqueId()}>{snag.description}</div>;
                return (
                    <Box key={uniqueId()} mt={3} mr={3} onClick={() => props.viewModel.viewDetailCommand.execute(snag)}>
                        <HorizontalBlock
                            title={snag.category}
                            subTitle={snag.description}
                            description={""}
                            lastUpdated={snag.dateClosed || snag.timeClosed ? `${snag.dateClosed && snag.dateClosed} ${snag.timeClosed && `@ ${snag.timeClosed}`}` : "--"}
                            path={""}
                            view={"snagView"}
                        />
                    </Box>
                );
            })}
        </Box>
    );
});
