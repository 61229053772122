import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle, Label, Button } from "Application";
import styled from "@emotion/styled";
import { YourPurchaseViewModel } from "./YourPurchaseViewModel";
import { PurchaseStagesView } from "./PurchaseStages/PurchaseStagesView";

const LinkLine = styled(Box)``;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

export const YourPurchaseView: React.FC = observer(() => {
    const boxRef = React.useRef<any>(null);
    const [viewModel] = useState(() => new YourPurchaseViewModel());
    const [height] = useState(0);

    useEffect(() => {
        setPageTitle("Sales Stage Admin");
    });

    const listView = () => {
        return (
            <>
                <LinkLine id={"linkline"} style={{ height: height }} />
                <Box id={"buildgrid"} grid dc={"1fr"} style={{ gap: "20px" }}>
                    <Box grid dc={"185px 1fr 2fr"} justifyContent={"center"} alignItems={"center"} style={{ gap: "30px" }}>
                        <Label mt={"0px"} style={{ font: "normal normal bold 14px/12px Lato", color: "#4A4A4A" }}>
                            Show on portal
                        </Label>
                        <Label mt={"0px"} style={{ font: "normal normal bold 14px/12px Lato", color: "#4A4A4A" }}>
                            Stages selected: {viewModel.getStagesSelected}
                        </Label>
                    </Box>
                    {viewModel.stagesViewModels
                        .slice()
                        .sort((a, b) => parseInt(b.model.stageCode) - parseInt(a.model.stageCode))
                        .map((item, index) => {
                            return (
                                <PurchaseStagesView
                                    index={viewModel.stagesViewModels.length - index}
                                    total={viewModel.stagesViewModels.length}
                                    key={item.model.KEY}
                                    viewModel={item}
                                />
                            );
                        })}
                </Box>
            </>
        );
    };

    return (
        <>
            <Box background={"#4A4A4A"} padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"0"}
                        style={{
                            letterSpacing: "-0.24px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            opacity: 1,
                            font: "normal normal bold 24px/30px Lato",
                        }}
                    >
                        ADMIN - sales Stage Admin
                    </Label>
                </Container>
            </Box>
            <Box padding={"25px 15px  100px"}>
                <Container id={"rootcontainer"}>
                    <div ref={boxRef} style={{ position: "relative", height: "100%" }}>
                        {!viewModel.isMobile && listView()}
                    </div>
                </Container>
            </Box>
            {viewModel.IsEdit && (
                <Box
                    style={{
                        marginTop: "50px",
                        position: "fixed",
                        boxShadow: "0px -3px 4px rgb(149 149 149 / 50%)",
                        width: "100%",
                        bottom: "0",
                        background: "#fff",
                        zIndex: 999,
                    }}
                >
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px", margin: "22px 0" }}>
                        <Button
                            mr={2}
                            command={viewModel.updateCommand}
                            paletteColor="secondary"
                            displayName={viewModel.isSave ? "Saving..." : "SAVE"}
                            style={{ padding: "5px 90px", height: "30px", borderRadius: "0" }}
                        />
                        <Button
                            mr={2}
                            command={viewModel.reSetCommand}
                            paletteColor="default"
                            displayName={viewModel.isCancel ? "Canceling..." : "CANCEL"}
                            style={{ padding: "5px 90px", height: "30px", borderRadius: "0" }}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
});
