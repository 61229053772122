import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ChoiceModel extends ModelBase<ChoiceModel> {
    public title: string = "Dummy Text Data";
    public numberChoicesAvailable: number = 0;
    public numberChoicesMade: number = 0;
    public numberExtrasAdded: number = 0;
    public variant: string = "-1";
    public included: boolean = false;
    public cost: number = 0;
    public path: string = "";
    public asset: any = null;

    constructor() {
        super();
        makeObservable(this, {
            title: observable,
            numberChoicesMade: observable,
            numberChoicesAvailable: observable,
            numberExtrasAdded: observable,
            variant: observable,
            included: observable,
            cost: observable,
        });
    }
}

export class ChoiceModelValidator extends Validator<ChoiceModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
