import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, ThemedEditText, ThemedText, Show, Else, Button, Label } from "../../../../Application";
import { ThemedLoader } from "../../../../Styles/ThemedPrimitives/Style1/ThemedLoader";
import { LoginHomeViewModel } from "../../LoginHomeViewModel";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useParams } from "react-router";
import styled from "@emotion/styled";

/**
 * Login page view.
 */
interface Props {
    confirmEmail?: boolean;
    loginHomeViewModel: LoginHomeViewModel;
}

const FormField = styled(Box)`
    margin-bottom: 10px;
    span {
        font: normal normal 300 14px/17px Lato;
        letter-spacing: 0.7px;
        color: #4a4a4a;
        text-transform: uppercase;
        margin: 0 0 5px;
        padding: 0;
        font-weight: 700;
    }
    .ms-TextField-wrapper {
        & > div:first-child {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
    .ms-TextField-fieldGroup {
        height: 32px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
        border: 1px solid #cccbcb;
        &:after {
            display: none !important;
        }
        &:focus,
        &:focus-within,
        &:hover {
            /* border: 1px solid #F47E44 !important; */
            border: 1px solid #f7a177 !important;
        }
    }
    input {
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .ms-TextField-errorMessage {
        padding-top: 5px;
        span {
            text-transform: capitalize;
            color: #ce4e5b;
            font: normal normal normal 12px/14px Lato;
        }
    }
`;

export const ForgotPasswordView = observer((props: Props) => {
    // #region Code Behind
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    let params = useParams();
    let token = params.token || null;
    console.log("forgot password view", token);
    useEffect(() => {
        if (token) {
            viewModel.showResetPasswordCommand.execute(token);
        }
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <>
            <AutoGrid alignContent={"center"} dc="1fr" rowGap={"10px"} maxWidth={"300px"} width={"100%"}>
                <ThemedText fontStyle={"h3"} styles={{ root: { font: "normal normal bold 18px/24px Lato", letterSpacing: "-0.31px", color: "#4A4A4A" } }}>
                    Forgot your password?
                </ThemedText>
                <Show if={!viewModel.showSuccess}>
                    <ThemedText
                        fontStyle={"h4"}
                        styles={{ root: { fontWeight: "400", font: "normal normal normal 12px/14px Lato", letterSpacing: "0px", color: "#4A4A4A", marginBottom: "15px" } }}
                    >
                        Tell us your email address, and we'll send you an email to reset your details.
                    </ThemedText>
                    <FormField>
                        <ThemedEditText
                            displayName="Email Address *"
                            command={viewModel.updateEmailAddressCommand}
                            value={() => viewModel.getValue("emailAddress")}
                            validationMessage={() => viewModel.getError("emailAddress")}
                        />
                    </FormField>
                    <Button
                        displayName="Continue"
                        command={viewModel.forgotPasswordCommand}
                        paletteColor="secondary"
                        type="submit"
                        style={{ width: "100%", borderRadius: 0, font: "normal normal 300 12px/16px Lato", letterSpacing: "0.36px", color: "#FFFFFF", textTransform: "uppercase" }}
                    />
                    <Else>
                        <ThemedText fontStyle={"h4"} styles={{ root: { fontWeight: "400" } }}>
                            We have sent you an email to reset your password.
                        </ThemedText>
                    </Else>
                </Show>

                <Button
                    displayName="Back to sign in"
                    command={props.loginHomeViewModel.navigateToSignInCommand}
                    paletteColor="secondary"
                    type="submit"
                    style={{ width: "100%", borderRadius: 0, font: "normal normal 300 12px/16px Lato", letterSpacing: "0.36px", color: "#FFFFFF", textTransform: "uppercase" }}
                />
            </AutoGrid>
        </>
    );

    const renderResetPassword = () => {
        return (
            <AutoGrid alignContent={"center"} dc="1fr" rowGap={"10px"} minWidth={"300px"}>
                <Show if={!viewModel.resetPasswordViewModel.showSuccessMessage}>
                    <ThemedText fontStyle={"h3"} styles={{ root: { font: "normal normal bold 18px/24px Lato", letterSpacing: "-0.31px", color: "#4A4A4A", marginBottom: "25px" } }}>
                        {props.confirmEmail ? "Set your password?" : "Reset your password?"}
                    </ThemedText>
                    <Show if={viewModel.resetPasswordViewModel.showErrorMessage}>
                        <ThemedText fontStyle={"h3"} styles={{ root: { font: "normal normal bold 14px/24px Lato", letterSpacing: "-0.31px", color: "red", marginBottom: "25px" } }}>
                            {viewModel.resetPasswordViewModel.apiClient.ValidationMessage}
                        </ThemedText>
                    </Show>
                    <FormField>
                        <ThemedEditText
                            displayName={"New Password"}
                            command={viewModel.resetPasswordViewModel.updateNewPasswordCommand}
                            value={() => viewModel.resetPasswordViewModel.getValue("newPassword")}
                            validationMessage={() => viewModel.resetPasswordViewModel.getError("newPassword")}
                            type={"password"}
                        />
                    </FormField>
                    <FormField>
                        <ThemedEditText
                            displayName={"Confirm Password"}
                            command={viewModel.resetPasswordViewModel.updateConfirmPasswordCommand}
                            value={() => viewModel.resetPasswordViewModel.getValue("confirmPassword")}
                            validationMessage={() => viewModel.resetPasswordViewModel.getError("confirmPassword")}
                            type={"password"}
                        />
                    </FormField>
                    <Button
                        displayName="Reset Password"
                        command={viewModel.resetPasswordViewModel.resetPasswordCommand}
                        paletteColor="secondary"
                        type="submit"
                        style={{ width: "100%", borderRadius: 0, font: "normal normal 300 12px/16px Lato", letterSpacing: "0.36px", color: "#FFFFFF", textTransform: "uppercase" }}
                    />
                    <Else>
                        <ThemedText fontStyle={"h4"}>You have successfully reset your password</ThemedText>
                        <ThemedText fontStyle={"h4"}>You can now go back to the login page</ThemedText>
                        {/* <Link onClick={() => props.loginHomeViewModel.navigateToSignInCommand.execute()}>
                            <Label
                                color={"blue"}
                                fontStyle={"h7"}
                                style={{ textDecoration: "underline", font: "normal normal normal 9px/10px Lato", letterSpacing: "0.24px", color: "#F47E44" }}
                            >
                                Back to sign in
                            </Label>
                        </Link> */}
                        <Button
                            displayName="Back to sign in"
                            command={props.loginHomeViewModel.navigateToSignInCommand}
                            paletteColor="secondary"
                            type="submit"
                            style={{
                                width: "100%",
                                borderRadius: 0,
                                font: "normal normal 300 12px/16px Lato",
                                letterSpacing: "0.36px",
                                color: "#FFFFFF",
                                textTransform: "uppercase",
                                cursor: "pointer",
                            }}
                        />
                    </Else>
                </Show>
            </AutoGrid>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            <Show if={!viewModel.showResetPassword}>
                {renderForm()}
                {renderBusy()}
                <Else>{renderResetPassword()}</Else>
            </Show>
        </React.Fragment>
    );
});
