import { makeObservable, observable, observe, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { ChoiceModel, ChoiceModelValidator } from "./ChoiceModel";
import { Logger } from "index";

export class ChoiceViewModel extends ViewModelBase<ChoiceModel> {
    public apiClient = new APIClient();

    constructor(model = new ChoiceModel()) {
        super(model);
        this.setValidator(new ChoiceModelValidator());
        makeObservable(this, {});
    }

    public get choices() {
        return [
            {
                key: "1",
                text: "CB314 - White",
            },
            {
                key: "2",
                text: "CB315 - Black",
            },
            {
                key: "3",
                text: "CB316 - Grey",
            },
            {
                key: "4",
                text: "CB317 - Red",
            },
            {
                key: "5",
                text: "CB318 - Blue",
            },
        ] as KeyValuePair[];
    }

    //#region Update Commands
    public updateChoicesMadeCommand = new RelayCommand((value: string) => {
        this.setValue("numberChoicesMade", value);
    });
    public updateCostCommand = new RelayCommand((value: string) => {
        this.setValue("included", value);
    });

    //#endregion Commands
}
