import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PurchaseStagesModel } from "./PurchaseStages/PurchaseStagesModel";

export class YourPurchaseModel extends ModelBase<YourPurchaseModel> {
    public stagesModels = observable<PurchaseStagesModel>([]);
    public plotNumber: string = "";
    public plotId: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    constructor() {
        super();
        makeObservable(this, {
            plotNumber: observable,
            plotId: observable,
            developmentName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
        });
    }
}

export class YourPurchaseModelValidator extends Validator<YourPurchaseModel> {
    constructor() {
        super();
    }
}
