import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AutoGrid, Box, Button, NullCommand, ThemedComboBox, ThemedEditText, formatDate } from "../../../../Application";
import { IColumn } from "@fluentui/react";
import { DeleteIcon } from "../../../../Assets";
import { ThemedDataTable } from "../../../../Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { EmailIcon } from "../../../../Assets/Icons/EmailIcon";
import { EditIcon } from "../../../../Assets/Icons/EditIcon";
import { nanoid } from "nanoid";
import { ThemedDialog } from "../../../../Styles/ThemedPrimitives/Style1/ThemedDialog";
import { PortalUsersModel } from "./PortalUsersModel";
import PortalUserDetailsViewModel from "./PortalUserDetail/PortalUserDetailsViewModel";
import { LockSVG } from "Assets/SVGs/Locksvg";
import styled from "@emotion/styled";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { AccountStore } from "../../../../Stores/Domain";
import { container } from "tsyringe";
import { UnLockSVG } from "Assets/SVGs/UnLockSVG";

const controlStyles = {
    root: {
        width: "100%",
        height: "30px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

const UserListTable = styled(Box)`
    .ms-DetailsHeader {
        grid-template-columns: 14% 8% 14% 20% 15% 19% 10% 12%;
        .ms-DetailsHeader-cell {
            &:last-child {
                .ms-DetailsHeader-cellTitle {
                    justify-content: center;
                }
            }
        }
        @media (max-width: 767px) {
            grid-template-columns: 150px 250px 150px 150px 220px 220px 90px;
        }
    }
    .ms-DetailsRow-fields {
        grid-template-columns: 14% 8% 14% 20% 15% 19% 10% 12%;
        @media (max-width: 767px) {
            grid-template-columns: 150px 250px 150px 150px 220px 220px 90px;
        }
    }
    .ms-DetailsHeader-cellTitle span {
        color: #4a4a4a;
    }
`;

const comboBoxStyles: any = {
    Container: { borderBottom: "1px solid #000", height: "30px" },
    optionsContainer: { top: "10px", maxHeight: "255px", paddingBottom: "5px" },
};

export const PortalUsersList: React.FC = observer(() => {
    const accountStore = container.resolve(AccountStore);
    const [portalViewModel] = useState(() => new PortalUserDetailsViewModel());
    const isMobile = useMediaQuery("(max-width: 767px)");
    const developmentData = portalViewModel.developmentOptions;
    const viewModel = portalViewModel.useData;

    useEffect(() => {
        if (!accountStore.isInRole("admin") && !accountStore.isInRole("sales admin")) {
            viewModel.history.push("/");
        }
    }, []);

    const getDataTableColumns = (): IColumn[] => {
        return [
            {
                key: "developmentCol",
                name: "Development",
                fieldName: "development",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "10px", whiteSpace: "initial" }} p={"10px"}>
                            {developmentData.find((el: any) => el.key == data?.item.coinsDevelopmentId)?.text}
                        </Box>
                    );
                },
            },
            {
                key: "plotNoCol",
                name: "Plot no.",
                fieldName: "coinsPlotId",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ width: "120px" }}>
                            {data?.item.coinsPlotId}
                        </Box>
                    );
                },
            },
            {
                key: "fullNameCol",
                name: "Name",
                fieldName: "name",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ width: "120px", whiteSpace: "initial" }}>
                            {data?.item.firstName + "  " + data?.item.lastName}
                        </Box>
                    );
                },
            },
            {
                key: "emailCol",
                name: "Email address",
                fieldName: "emailAddress",
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "10px", whiteSpace: "initial" }}>
                            <EmailIcon />
                            {data?.item.emailAddress}
                        </Box>
                    );
                },
            },
            {
                key: "lastLoginDateCol",
                name: "Last logged in",
                fieldName: "lastLoginDate",
                onRenderField: (data) => {
                    return (
                        <div key={nanoid()} style={{ gap: "10px" }}>
                            {formatDate(data?.item.lastLoginDate, "DD/MM/YYYY @ HH:mm")}
                        </div>
                    );
                },
            },
            {
                key: "createByCol",
                name: "CreatedBy",
                fieldName: "createdBy",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <div key={nanoid()} role={"gridcell"} style={{ gap: "10px", textTransform: "capitalize", whiteSpace: "initial" }}>
                            {viewModel.getUserName(data?.item?.createdBy)}
                        </div>
                    );
                },
            },
            {
                key: "actionsCol",
                name: "Actions",
                onRender: (item: PortalUsersModel) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} height={"100%"} justifyContent={"center"} style={{ gap: "20px" }}>
                            {item.accessFailedCount > 7 ? (
                                <Box
                                    title={"Unlock User"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => viewModel.unlockUserWithConfirmDialogCommand.execute(item)}
                                >
                                    <LockSVG />
                                </Box>
                            ) : (
                                <Box
                                    title={"Lock User"}
                                    display={"flex"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => viewModel.lockUserWithConfirmDialogCommand.execute(item)}
                                    alignItems={"center"}
                                >
                                    <UnLockSVG />
                                </Box>
                            )}
                            <Box title={"Edit User"} display={"flex"} alignItems={"center"} onClick={() => viewModel.editUserCommand.execute(item)}>
                                <EditIcon />
                            </Box>
                            <Box title={"Delete User"} display={"flex"} alignItems={"center"} onClick={() => viewModel.deleteUserWithConfirmDialogCommand.execute(item)}>
                                <DeleteIcon color={"red"} />
                            </Box>
                        </Box>
                    );
                },
            },
        ] as IColumn[];
    };

    return (
        <>
            <Box id="tablecontainer">
                <Box flexGrow={1} textAlign="left" mb={"15px"}>
                    <Button
                        paletteColor="secondary"
                        command={viewModel.newUserCommand}
                        displayName={"Add New Portal User"}
                        style={{
                            background: "#2A71CD",
                            maxWidth: "100%",
                            width: isMobile ? "50%" : "25%",
                            borderRadius: 0,
                            height: "30px",
                            font: "normal normal bold 12px/15px Lato",
                            letterSpacing: "0.36px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            padding: isMobile ? "8px 15px" : "8px 30px",
                        }}
                    />
                </Box>
                <AutoGrid dc={"25% auto"} mb={"15px"} rowGap={"22px"} columnGap={"30px"} minHeight={"30px"}>
                    <ThemedComboBox
                        command={viewModel.updateDevelopmentIdCommand}
                        options={portalViewModel.developmentOptions}
                        value={() => viewModel.getValue("filterId")!}
                        validationMessage={() => viewModel.getError("filterId")}
                        placeholder={"Show all developments"}
                        styles={comboBoxStyles}
                        canClearText={true}
                    />
                    <ThemedEditText
                        command={viewModel.updateSearchKeyword}
                        placeholder={"Keyword search"}
                        styles={controlStyles}
                        value={() => viewModel.getValue("searchKeyword")}
                    />
                </AutoGrid>
                <UserListTable>
                    {developmentData.length > 0 && (
                        <ThemedDataTable data={viewModel.getUsers} columns={getDataTableColumns()} command={new NullCommand()} value={() => viewModel.selectedRows} />
                    )}
                </UserListTable>
            </Box>
            <ThemedDialog
                command={viewModel.deleteUserCommand}
                dismissCommand={viewModel.hideDialogCommand}
                title={`Delete User ${viewModel.userToDelete?.emailAddress}`}
                message={"Are you sure you want to delete this user?"}
                show={viewModel.showDialog}
                paletteColor={"secondary"}
            />
            <ThemedDialog
                command={viewModel.unlockUserCommand}
                dismissCommand={viewModel.hideUnlockDialogCommand}
                title={`Unlock User ${viewModel.userToUnlock?.emailAddress}`}
                message={"Are you sure you want to unlock this user?"}
                show={viewModel.showUnlockDialog}
                paletteColor={"secondary"}
            />
            <ThemedDialog
                command={viewModel.LockUserCommand}
                dismissCommand={viewModel.hideLockDialogCommand}
                title={`Lock User ${viewModel.userToUnlock?.emailAddress}`}
                message={"Are you sure you want to lock this user?"}
                show={viewModel.showLockDialog}
                paletteColor={"secondary"}
            />
        </>
    );
});
