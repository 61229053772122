import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { container } from "tsyringe";
import { AccountStore } from "../../../Stores/Domain";
import { CustomercareViewModel } from "../CustomercareViewModel";
import { SnagModel } from "../SnagModel";
import { runInAction } from "mobx";

class Response {
    public snags: snagsResponse[] = [];
    public plotNumber: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
}
class snagsResponse {
    public category: string = "";
    public dateClosed: null | Date = null;
    public description: string = "";
    public loggedBy: string = "";
    public reference: string = "";
    public coinsReference: string = "";
    public status: string = "";
    public timeClosed: string = "";
}

export class GETYourSnagsEndpoint extends EndpointWithoutRequest<Response> {
    private accountStore = container.resolve(AccountStore);
    private viewModel: CustomercareViewModel;

    constructor(viewModel: CustomercareViewModel) {
        super();
        this.verb(Http.Get);
        this.path("/api/getyoursnags/" + this.accountStore.CoinsPlotId!);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        this.viewModel.setValue("plotNumber", response.plotNumber);
        this.viewModel.setValue("developmentName", response.developmentName);
        this.viewModel.setValue("address1", response.address1);
        this.viewModel.setValue("address2", response.address2);
        this.viewModel.setValue("address3", response.address3);
        this.viewModel.setValue("address4", response.address4);
        this.viewModel.setValue("postcode", response.postcode);
        runInAction(() => {
            for (const snag of response.snags) {
                let model = new SnagModel();
                model.category = snag.category;
                model.dateClosed = snag.dateClosed;
                model.description = snag.description;
                model.loggedBy = snag.loggedBy;
                model.reference = snag.reference;
                model.coinsReference = snag.coinsReference;
                model.status = snag.status;
                model.timeClosed = snag.timeClosed;
                this.viewModel.yourSnags.push(model);
            }
        });
        //Return your model
        return {};
    }
}
