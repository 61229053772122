import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { DocumentsViewModel } from "./DocumentsViewModel";

export const DocumentsView: React.FC = observer(() => {
    const [viewModel] = useState(() => new DocumentsViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Documents");
    }, []);
    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Documents</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}></Box>
        </Box>
    );
});
