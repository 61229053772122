import React, { useRef, useState, useEffect } from "react";
import { AutoGrid } from "Components/Grid";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Box, ThemedButton, ThemedDefaultEditText, ThemedText } from "../../../Application";
import RoleDetailViewModel from "./RoleDetailViewModel";

export const DetailContainer = styled(Box)`
    position: relative;
`;

export const RoleDetails: React.FC = observer(() => {
    const [viewModel] = useState(() => new RoleDetailViewModel());
    const [newRole, setNewRole] = useState(false);
    const params = useParams();

    let id = params.id || "";

    useEffect(() => {
        if (id === "new") {
            setNewRole(true);
        } else {
            viewModel.getRole(id);
        }
    }, [viewModel.roleStore.isDataLoaded]); //We are dependent on the role store being loaded

    const doSubmit = async (e: any) => {
        e.preventDefault();
    };

    return (
        <Box p={3}>
            <Box marginTop="45px" marginBottom="10px" display="flex">
                <Box flexGrow={1}>
                    <ThemedText fontStyle="h1" color="primary">
                        Role
                    </ThemedText>
                </Box>
            </Box>
            <hr />
            <form onSubmit={doSubmit} id="detailsPanelForm">
                <DetailContainer>
                    <Box pt={5} pb={5}>
                        <AutoGrid desktopColumns={"400px"}>
                            <ThemedDefaultEditText displayName="Name" value={() => viewModel.getValue("name")} command={viewModel.updateRoleNameCommand} />
                        </AutoGrid>
                    </Box>
                    <hr />
                    <Box display={"flex"} justifyContent="center" pt={3} mb={3}>
                        <ThemedButton paletteColor={"error"} styles={{ root: { color: "white" } }} displayName={"Cancel"} command={viewModel.cancelRoleCommand} />
                        <span style={{ paddingRight: "20px" }} />
                        <ThemedButton paletteColor={"primary"} type="submit" displayName={!viewModel.apiClient.IsBusy ? "Save" : "Saving"} command={viewModel.saveRoleCommand} />
                    </Box>
                </DetailContainer>
            </form>
        </Box>
    );
});
