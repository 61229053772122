import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ImageCropperViewModel } from "./ImageCropperViewModel";
import { BuildStageModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesModel";
import { runInAction } from "mobx";
import { BuildStagesViewModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesViewModel";
import { PurchaseStagesViewModel } from "Views/Admin/YourPurchase/PurchaseStages/PurchaseStagesViewModel";

export class Response {
    public companyId: string = "";
    public title: string = "";
    public number: string = "";
    public description: string = "";
    public createdDate: string = "";
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";
    public id: string = "";
}

class Request {
    public id: string = "";
}

export class POSTSaveImageEndpoint extends Endpoint<FormData, Response> {
    private readonly viewModel: ImageCropperViewModel;
    public id: string = "";
    public viewModelReplace: BuildStagesViewModel | PurchaseStagesViewModel;
    constructor(viewModel: ImageCropperViewModel, id: string, model: BuildStagesViewModel | PurchaseStagesViewModel) {
        super();
        this.verb(Http.Post);
        this.path("/api/buildProgress/uploadicon");
        this.viewModel = viewModel;
        this.id = id;
        this.viewModelReplace = model;
    }

    public async HandleRequestAsync(): Promise<any> {
        let request: Request = new Request();
        request.id = this.id;
        const formData = new FormData();
        formData.append("id", request.id);
        for (const file of this?.viewModel?.model?.files!) {
            formData.append("file", file.file!, file.fileName);
        }
        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            let m = new BuildStageModel();
            m.id = response.id;
            m.isCompleted = response.isCompleted;
            m.isVisible = response.isVisible;
            m.description = response.description;
            m.stageCode = response.number;
            m.sequence = response.title;
            this.viewModelReplace.model.imageUrl = response.imageUrl;
        });
    }
}
