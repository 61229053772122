import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";
import { theme } from "../../../Styles/AppTheme";
import { getThemePaletteOption, getThemeShapeOption, getThemeSizeOption, pxToRem } from "../../../Application/Helpers";
import { observer } from "mobx-react-lite";
import { DialogBase, IDialogBaseProps } from "../../StyleFrameworks/FluentUI/Primitives/Active/DialogBase";

/**
 * A styled dialog. The styling can be adjusted using the size and paletteColor props.
 */
export const ThemedDialog = observer((props: PropsWithChildren<IDialogBaseProps>) => {
    const paletteColor = getThemePaletteOption(props.paletteColor);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DialogBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        backgroundColor: paletteColor.light,
                        borderColor: paletteColor.light,
                        color: paletteColor.text,
                    },
                    rootFocused: {
                        backgroundColor: paletteColor.light,
                        color: paletteColor.text,
                        "::after": {
                            // Fixes issues with square outlines on rounded buttons with focus.
                            outline: "none !important",
                            inset: "1px !important",
                            border: `1px solid ${paletteColor.text} !important`,
                        },
                    },
                    rootHovered: {
                        backgroundColor: paletteColor.main,
                        borderColor: paletteColor.main,
                        color: paletteColor.text,
                    },
                    rootPressed: {
                        backgroundColor: paletteColor.dark,
                        borderColor: paletteColor.dark,
                        color: paletteColor.text,
                    },
                    rootDisabled: {
                        backgroundColor: theme.palette.disabled.main,
                        borderColor: theme.palette.disabled.main,
                        color: theme.palette.disabled.text,
                    },
                    label: {
                        fontFamily: theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.button.fontSize,
                        fontWeight: theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.button.letterSpacing,
                        textTransform: theme.fontStyles.button.textTransform,
                    },
                },
                props.styles,
            )}
        />
    );
});
