import { ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application/Commands";
import { makeObservable, observable } from "mobx";
// Urls
import { AppUrls } from "../../AppUrls";
import { HeaderLinkViewModel } from "./HeaderLinkViewModel";

export class HeaderViewModel extends ViewModelBase<any> {
    public dashBoardViewModel = new HeaderLinkViewModel("Dashboard", "/");
    public yourHomeViewModel = new HeaderLinkViewModel("Your Home", "yourhome");
    public yourBuildProgressModel = new HeaderLinkViewModel("Build Progress", "buildprogress");
    public salesProgressModel = new HeaderLinkViewModel("Sales Progress", "salesprogress");
    public documentsModel = new HeaderLinkViewModel("Documents", "documents");
    public choicesModel = new HeaderLinkViewModel("Choices & Extras", "choices");
    public customerCareModel = new HeaderLinkViewModel("Customer Experience", "customerexperience");
    public yourSalesStageModel = new HeaderLinkViewModel("Sales Stage Admin", "salesstageadmin");
    public yourBuildStageModel = new HeaderLinkViewModel("Build Stage Admin", "buildstageadmin");
    public samplesViewModel = new HeaderLinkViewModel("Samples", "samples");
    public todoViewModel = new HeaderLinkViewModel("Todo", "todo");
    public testViewModel = new HeaderLinkViewModel("Test", "test");
    public logViewerViewModel = new HeaderLinkViewModel("LogViewer", "logviewer");
    public adminViewModel = new HeaderLinkViewModel("Users", "admin");
    public demoViewModel = new HeaderLinkViewModel("Demo", "application");
    public loginViewModel = new HeaderLinkViewModel("Login", "account");
    public logoutViewModel = new HeaderLinkViewModel("Log out", "logout");

    private activeHeaderLinkViewModel: HeaderLinkViewModel = this.dashBoardViewModel;

    public logout: boolean = false;

    constructor() {
        super({});
        makeObservable(this, { logout: observable });
    }

    private setActiveLink = (viewModel: HeaderLinkViewModel) => {
        this.clearActiveCommand.execute();
        this.activeHeaderLinkViewModel = viewModel;
    };

    // #region Actions
    public clearActiveCommand = new RelayCommand(() => {
        this.activeHeaderLinkViewModel.clearActiveOption();
    });

    public navigateToLoginCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.loginViewModel);
            this.loginViewModel.navigateToLinkCommand.execute(AppUrls.Client.Account.Login);
        },
        () => !this.isLoggedIn,
    );

    // public navigateToLogoutCommand = new RelayCommand(
    //     () => {
    //         this.setActiveLink(this.loginViewModel);
    //         this.loginViewModel.navigateToLinkCommand.execute(AppUrls.Client.Account.Logout);
    //     },
    //     () => this.isLoggedIn,
    // );

    public navigateToLogViewerCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.testViewModel);
            this.logViewerViewModel.navigateToLinkCommand.execute(AppUrls.Client.Admin.LogViewer);
        },
        () => this.isLoggedIn,
    );

    public navigateToAdminCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.adminViewModel);
            this.adminViewModel.navigateToLinkCommand.execute(AppUrls.Client.Admin.AdminHome);
        },
        () => this.isLoggedIn,
    );

    public navigateToPortalCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.adminViewModel);
            this.adminViewModel.navigateToLinkCommand.execute(AppUrls.Client.Admin.PortalHome);
        },
        () => this.isLoggedIn,
    );

    public navigateToDemoCommand = new RelayCommand(() => {
        this.setActiveLink(this.demoViewModel);
        this.demoViewModel.navigateToLinkCommand.execute(AppUrls.Client.Demo);
    });

    public navigateToHomeCommand = new RelayCommand(() => {
        this.setActiveLink(this.dashBoardViewModel);
        this.dashBoardViewModel.navigateToLinkCommand.execute(AppUrls.Client.Home);
    });

    public navigateToYourHomeCommand = new RelayCommand(() => {
        this.setActiveLink(this.yourHomeViewModel);
        this.loginViewModel.navigateToLinkCommand.execute(AppUrls.Client.YourHome);
    });
    public navigateToBuildProgressCommand = new RelayCommand(() => {
        this.setActiveLink(this.yourBuildProgressModel);
        this.yourBuildProgressModel.navigateToLinkCommand.execute(AppUrls.Client.BuildProgress);
    });
    public navigateToSalesProgressCommand = new RelayCommand(() => {
        this.setActiveLink(this.salesProgressModel);
        this.salesProgressModel.navigateToLinkCommand.execute(AppUrls.Client.SalesProgress);
    });
    public navigateToDocumentsCommand = new RelayCommand(() => {
        this.setActiveLink(this.documentsModel);
        this.documentsModel.navigateToLinkCommand.execute(AppUrls.Client.Documents);
    });
    public navigateToChoicesCommand = new RelayCommand(() => {
        this.setActiveLink(this.choicesModel);
        this.choicesModel.navigateToLinkCommand.execute(AppUrls.Client.Choices);
    });
    public navigateToCustomerCareCommand = new RelayCommand(() => {
        this.setActiveLink(this.customerCareModel);
        this.customerCareModel.navigateToLinkCommand.execute(AppUrls.Client.CustomerCare);
    });

    public navigateToYourPurchaseCommand = new RelayCommand(() => {
        this.setActiveLink(this.yourSalesStageModel);
        this.yourSalesStageModel.navigateToLinkCommand.execute(AppUrls.Client.SalesStageAdmin);
    });

    public navigateToBuildStageCommand = new RelayCommand(() => {
        this.setActiveLink(this.yourBuildStageModel);
        this.yourBuildStageModel.navigateToLinkCommand.execute(AppUrls.Client.BuildStageAdmin);
    });

    public navigateToLogoutCommand = new RelayCommand(
        () => {
            this.logout = true;
        },
        () => this.isLoggedIn,
    );

    public logoutClose = new RelayCommand(
        () => {
            this.logout = false;
        },
        () => this.isLoggedIn,
    );

    // #endregion Computeds
}
