import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle, Label } from "Application";
import { BuildprogressViewModel } from "./BuildprogressViewModel";
import { StagesView } from "./Stages/StagesView";
import { StagesMobileView } from "./Stages/StagesMobileView";
import styled from "@emotion/styled";
import prev from "Assets/Previous.svg";
import next from "Assets/Next.svg";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/swiper.css";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const LinkLine = styled(Box)`
    position: absolute;
    left: 48px;
    top: 85px;
    width: 5px;
    background-color: #6a6a6a;
    border: none;
`;

const PrevNext = styled(Box)`
    width: 14px;
    height: 23px;
    position: absolute;
    top: 55px;
    z-index: 20;
    cursor: pointer;
`;

const Prev = styled(PrevNext)`
    left: 0px;
`;

const Next = styled(PrevNext)`
    right: 0px;
`;

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

export const BuildprogressView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const boxRef = React.useRef<HTMLDivElement>(null);
    const [viewModel] = useState(() => new BuildprogressViewModel());
    const [height, setHeight] = useState(0);
    useEffect(() => {
        setPageTitle("Build progress");
        setTimeout(() => {
            console.log("height", boxRef.current!.clientHeight);
            setHeight(boxRef.current?.clientHeight! - 140);
        }, 100);
    });

    function SwipeNext() {
        const swiper = useSwiper();
        return (
            <Next onClick={() => swiper.slideNext()}>
                <img src={next} />
            </Next>
        );
    }

    function SwipePrev() {
        const swiper = useSwiper();
        return (
            <Prev onClick={() => swiper.slidePrev()}>
                <img src={prev} />
            </Prev>
        );
    }

    const listView = () => {
        return (
            <>
                <LinkLine id={"linkline"} style={{ height: height }} />
                <Box id={"buildgrid"} grid dc={"1fr"} style={{ gap: "30px" }} mb={"50px"}>
                    {viewModel.buildProgressList.map((item, index) => {
                        return <StagesView index={viewModel.buildProgressList.length - index} total={viewModel.model.buildOverallCount} key={item.model.KEY} viewModel={item} />;
                    })}
                </Box>
            </>
        );
    };

    const mobileListView = () => {
        return (
            <>
                <Box id={"buildcarousel"} mb={"50px"}>
                    <Swiper centeredSlides={true} navigation={true} pagination={{ clickable: true, enabled: false }} slidesPerView={3} modules={[Pagination, Navigation]}>
                        {viewModel.buildProgressList.map((item, index) => {
                            return (
                                <React.Fragment key={item.model.KEY}>
                                    <SwiperSlide style={{ width: "90px", height: "80vh", textAlign: "center", paddingTop: "20px" }}>
                                        {({ isActive }) =>
                                            isActive ? (
                                                <StagesMobileView
                                                    isFocused={true}
                                                    index={viewModel.buildProgressList.length - index}
                                                    total={viewModel.model.buildOverallCount}
                                                    key={item.model.KEY}
                                                    viewModel={item}
                                                />
                                            ) : (
                                                <StagesMobileView
                                                    isFocused={false}
                                                    index={viewModel.buildProgressList.length - index}
                                                    total={viewModel.model.buildOverallCount}
                                                    key={item.model.KEY}
                                                    viewModel={item}
                                                />
                                            )
                                        }
                                    </SwiperSlide>
                                </React.Fragment>
                            );
                        })}
                        <SwipeNext />
                        <SwipePrev />
                    </Swiper>
                </Box>
            </>
        );
    };

    return (
        <>
            <Box background={"#4A4A4A"} padding={isMobile ? "10px 15px 40px" : "25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{ letterSpacing: "-0.24px", color: "#FFFFFF", textTransform: "capitalize", opacity: 1, fontSize: "24px", lineHeight: "30px", fontWeight: "bold" }}
                    >
                        Build Progress
                    </Label>
                    <Label
                        fontStyle={"h3"}
                        style={{
                            letterSpacing: isMobile ? "-0.24px" : "0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "400",
                        }}
                    >
                        Home {Number.isNaN(parseInt(viewModel.getValue("plotNumber"), 10)) ? "" : parseInt(viewModel.getValue("plotNumber"), 10)} {"  "}
                        {viewModel.getValue("developmentName")}
                    </Label>
                    <FieldLabel
                        fontStyle={"h4"}
                        style={{ letterSpacing: isMobile ? "-0.15px" : "0", color: "#f47e44", textTransform: "capitalize", fontSize: "10px", lineHeight: "18px", marginTop: "4px" }}
                    >
                        {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                            viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                        } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                    </FieldLabel>
                </Container>
            </Box>
            <Box padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <div ref={boxRef} style={{ position: "relative", height: "100%" }}>
                        {!viewModel.isMobile && listView()}
                        {viewModel.isMobile && mobileListView()}
                    </div>
                </Container>
            </Box>
        </>
    );
});
