import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { YourhomeViewModel } from "./YourhomeViewModel";

export const YourhomeView: React.FC = observer(() => {
    const [viewModel] = useState(() => new YourhomeViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Yourhome");
    }, []);
    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Yourhome</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}>
                <ThemedText>{viewModel.getValue("plotAddressOne")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotAddressTwo")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotAddressThree")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotTown")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotCounty")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotPostCode")}</ThemedText>
                <ThemedText>{viewModel.getValue("salesAgent")}</ThemedText>
                <ThemedText>{viewModel.getValue("agredPrice")}</ThemedText>
                <ThemedText>{viewModel.getValue("deposit")}</ThemedText>
                <ThemedText>{viewModel.getValue("developmentContactNumber")}</ThemedText>
                <ThemedText>{viewModel.getValue("developmentEmailAddress")}</ThemedText>
                <ThemedText>{viewModel.getValue("buildCompletionTarget")}</ThemedText>
                <ThemedText>{viewModel.getValue("buildCompletionActual")}</ThemedText>
                <ThemedText>{viewModel.getValue("choicesCompletionTarget")}</ThemedText>
                <ThemedText>{viewModel.getValue("choiceCompletionActual")}</ThemedText>
                <ThemedText>{viewModel.getValue("legalCompletionTarget")}</ThemedText>
                <ThemedText>{viewModel.getValue("legalCompletionActual")}</ThemedText>
                <ThemedText>{viewModel.getValue("contractxchangeDeadline")}</ThemedText>
                <ThemedText>{viewModel.getValue("contractExchangeActual")}</ThemedText>
                <ThemedText>{viewModel.getValue("plotName")}</ThemedText>
            </Box>
        </Box>
    );
});
