import { BaseStore } from "@shoothill/core";
import Moment from "moment";
import { Stores } from "Stores/index";
import { UserModel } from "Views/Admin/Users/UserModel";
import { singleton } from "tsyringe";
import { action, computed, makeObservable, observable } from "mobx";

// App

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

@singleton()
export class UserStore extends BaseStore {
    public users = observable<UserModel>([]);
    private isLoadingData: boolean = false;
    private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };
    private lastUpdated: any = Moment();

    public constructor() {
        super();
        makeObservable<UserStore, "isLoadingData" | "filters" | "lastUpdated">(this, {
            users: observable,
            isLoadingData: observable,
            filters: observable,
            lastUpdated: observable,
            getUserCount: computed,
            setUsers: action,
            clearUsers: action,
            setIsLoadingData: action,
            getUsers: computed,
            getIsLoadingData: computed,
            getFilters: computed,
            getFilteredUsers: computed,
        });
    }

    public Init(stores: Stores) {}

    get getUserCount(): number {
        return this.users.length;
    }

    public setUsers(users: UserModel[]) {
        this.users.clear();
        this.users.replace(users);
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    public clearUsers() {
        this.users.clear();
    }

    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    public get getUsers() {
        return this.users;
    }

    public get getIsLoadingData() {
        return this.isLoadingData;
    }

    public get getFilters() {
        return this.filters;
    }

    public get getFilteredUsers() {
        let users = this.users.slice();
        if (this.filters.searchFilter) {
            users = this.users.filter(
                (or) =>
                    or.emailAddress.toLowerCase().includes(this.filters.searchFilter) ||
                    or.firstName.toLowerCase().includes(this.filters.searchFilter) ||
                    or.lastName.toLowerCase().includes(this.filters.searchFilter),
            );
        }
        return users;
    }
}
