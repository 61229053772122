import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { BuildprogressViewModel } from "../BuildprogressViewModel";
import { StagesModel } from "../Stages/StagesModel";

class Response {
    public buildProgressItems: BuildProgressResponse[] = [];
    public plotNumber: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    public buildOverallCount: number = 0;
}
class BuildProgressResponse {
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public stageDateUTC: string | null = "";
    public description: string | null = "";
    public number: number = 0;
    public title: string | null = "";
    public imageUrl: string = "";
}

export class GETBuildProgressEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: BuildprogressViewModel;
    constructor(viewModel: BuildprogressViewModel, coinsPlotId: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/getbuildprogress/" + coinsPlotId);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.setValue("plotNumber", response.plotNumber);
        this.viewModel.setValue("developmentName", response.developmentName);
        this.viewModel.setValue("address1", response.address1);
        this.viewModel.setValue("address2", response.address2);
        this.viewModel.setValue("address3", response.address3);
        this.viewModel.setValue("address4", response.address4);
        this.viewModel.setValue("postcode", response.postcode);
        this.viewModel.setValue("buildOverallCount", response.buildOverallCount);
        runInAction(() => {
            for (const item of response.buildProgressItems) {
                let m = new StagesModel();
                m.isCompleted = item.isCompleted;
                m.isVisible = item.isVisible;
                m.stageDate = new Date(item.stageDateUTC!);
                m.description = item.description!;
                m.number = item.number;
                m.title = item.title!;
                m.imageUrl = item.imageUrl;
                this.viewModel.model.stagesModels.push(m);
            }
        });
        //Return your model
        return {};
    }
}
