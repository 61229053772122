import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { Moment } from "moment";
import * as sa from "Application";
import { PortalUsersModel } from "../../PortalUsers/PortalUsersModel";

class Request {
    public id: Guid = null;
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    //public password: string = "";
    public concurrencyToken: string = "";
    public isDeleted: boolean = false;
    public lastLoginDate: Moment | null = null;
    public userRoles: string[] = [];
    public isAdminUser: boolean = false;
}

class Response {
    public id: Guid = null;
}

export class SaveUserEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(`/api/user/upsert`);
    }

    HandleRequestAsync(model: PortalUsersModel): Promise<Request> {
        let request: Request = new Request();
        request.id = model.id;
        request.firstName = model.firstName;
        request.lastName = model.lastName;
        request.emailAddress = model.emailAddress;
        //request.password = model.password;
        request.concurrencyToken = model.concurrencyToken;
        request.isDeleted = model.isDeleted;
        request.lastLoginDate = model.lastLoginDate;
        request.userRoles = model.userRoles;
        request.isAdminUser = false;
        if (!request.id) {
            this.path("/api/account/register");
        }
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<PortalUsersModel> {
        let portalUsersModel: PortalUsersModel = new PortalUsersModel(response.id);
        portalUsersModel.fromResponse(response);
        return portalUsersModel;
    }
}
