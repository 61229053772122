import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class DocumentListModel extends ModelBase<DocumentListModel> {
    public documentId: string = "";
    public documentTypeLongDescription: string = "";
    public fileName: string = "";
    public documentUrl: string = "";
    public documentModels = observable<DocumentListModel>([]);
    public plotNumber: string = "033";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    constructor() {
        super();
        makeObservable(this, {
            documentId: observable,
            documentTypeLongDescription: observable,
            fileName: observable,
            documentUrl: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
        });
    }
}

export class DocumentsModelValidator extends Validator<DocumentListModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
