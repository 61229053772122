import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class YourhomeModel extends ModelBase<YourhomeModel> {
    public coinsPlotId: string = "";
    public developmentName: string = "";
    public houseTypeName: string = "";
    public plotNumber: string = "";
    public fullAddress: string | null = "";
    public salesAgentName: string | null = "";
    public marketingPrice: number = 0;
    public deposit: number = 0;
    public buildCompletionActualDateUTC: Date | null = null;
    public buildCompletionTargetDateUTC: Date | null = null;
    public choicesCompleteActualDateUTC: Date | null = null;
    public choicesCompleteTargetDateUTC: Date | null = null;
    public contractExchangeActualDateUTC: Date | null = null;
    public contractExchangeDeadlineDateUTC: Date | null = null;
    public legalCompletionActualDateUTC: Date | null = null;
    public legalCompletionTargetDateUTC: Date | null = null;
    public developmentContactNumber: string | null = "";
    public developmentEmail: string | null = "";
    public floorPlan: string | null = "";
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public address4: string | null = null;
    public postcode: string | null = null;
    public incentives: number = 0;
    public purchasedUpgrades: number = 0;
    public netPurchasePrice: number = 0;
    public nonCashIncentivesQuantity: string | null = null;
    public nonCashIncentivesDescription: string | null = null;

    public houseTypeImages: HouseTypeImageResponse[] = [];
    /*    public get fullAddress(): string {
        return [this.plotAddressOne, this.plotAddressTwo, this.plotAddressThree, this.plotTown, this.plotPostCode].filter(Boolean).join(", ");
    }*/

    constructor() {
        super();
        makeObservable(this, {
            deposit: observable,
            developmentContactNumber: observable,
            developmentName: observable,
            developmentEmail: observable,
            fullAddress: observable,
            houseTypeName: observable,
            houseTypeImages: observable,
            plotNumber: observable,
            salesAgentName: observable,
            marketingPrice: observable,
            buildCompletionActualDateUTC: observable,
            buildCompletionTargetDateUTC: observable,
            choicesCompleteActualDateUTC: observable,
            choicesCompleteTargetDateUTC: observable,
            contractExchangeActualDateUTC: observable,
            contractExchangeDeadlineDateUTC: observable,
            legalCompletionActualDateUTC: observable,
            legalCompletionTargetDateUTC: observable,
            floorPlan: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
            incentives: observable,
            purchasedUpgrades: observable,
            netPurchasePrice: observable,
            nonCashIncentivesQuantity: observable,
            nonCashIncentivesDescription: observable,
        });
    }
}

class HouseTypeImageResponse {
    public companyId: string = "";
    public coinsDevelopmentId: number = 0;
    public coinsHouseTypeId: number = 0;
    public fileName: string = "";
    public fileURL: string = "";
}

export class YourhomeModelValidator extends Validator<YourhomeModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
