import React, { useEffect, useRef, useState } from "react";
import { Box } from "../StyleFrameworks";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { ImageCropperViewModel } from "./ImageCropperViewModel";
import { ImageCropperModel } from "./ImageCropperModel";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Label } from "@fluentui/react";
import { Button, ICommand, RelayCommand } from "Application";
import { BuildStagesViewModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesViewModel";
import { PurchaseStagesViewModel } from "Views/Admin/YourPurchase/PurchaseStages/PurchaseStagesViewModel";
export interface IProps {
    imageFile?: any;
    photoType?: number;
    aspect?: number;
    closeModel?: ICommand;
    fileName?: string;
    id?: string;
    model: BuildStagesViewModel | PurchaseStagesViewModel;
    isBuildStageAdmin: boolean;
}

const Container = styled(Box)`
    width: 400px;
    .Heading {
        padding: 10px 15px;
        color: #fff;
        background-color: #4a4a4a;
        font-size: 16px;
    }
    .ContainerBody {
        padding: 15px;
        .ms-Label {
            margin-bottom: 10px;
            padding: 0;
        }
        .ReactCropImg {
            text-align: center;
            margin-bottom: 15px;
            img {
                margin: auto;
                object-fit: cover;
            }
        }
        .BtnPart {
            display: flex;
            gap: 15px;
            justify-content: center;
            button {
                margin: 0;
                min-width: 130px;
                padding: 5px 10px;
            }
        }
    }
`;

export const ImageCropperView: React.FC<IProps> = observer((props: IProps) => {
    const toModel = () => {
        const { imageFile, photoType, aspect, isBuildStageAdmin } = props;
        const model = new ImageCropperModel();
        model.imageFile = imageFile ? imageFile : null;
        model.photoType = photoType ? photoType : null;
        model.aspect = aspect ? aspect : null;
        model.isBuildStageAdmin = isBuildStageAdmin;
        return model;
    };

    const model = toModel();

    const [viewModel] = useState(() => new ImageCropperViewModel(model));

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [aspect, setAspect] = useState<number | undefined>(16 / 9);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);

    const blobUrlRef = useRef("");
    const imgRef = useRef<HTMLImageElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const TO_RADIANS = Math.PI / 180;

    const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        );
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };

    const canvasPreview = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) => {
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";
        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;
        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;
        ctx.save();
        ctx.translate(-cropX, -cropY);
        ctx.translate(centerX, centerY);
        ctx.rotate(rotateRads);
        ctx.scale(scale, scale);
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
        ctx.restore();
    };

    const useDebounceEffect = (fn: () => void, waitTime: number, deps?: any) => {
        useEffect(() => {
            const t = setTimeout(() => {
                // eslint-disable-next-line prefer-spread
                fn.apply(undefined, deps);
            }, waitTime);

            return () => {
                clearTimeout(t);
            };
        }, deps);
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
            }
        },
        100,
        [completedCrop, scale, rotate],
    );

    const onDownloadCropClick = () => {
        if (!previewCanvasRef.current) {
            throw new Error("Crop canvas does not exist");
        }
        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error("Failed to create blob");
            }
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
            }
            const file = new File([blob], `${props.fileName}`, {
                type: blob.type,
            });
            viewModel.saveCropperImageCommand.execute(file, props.id, props.closeModel, props.model, props.isBuildStageAdmin);
            blobUrlRef.current = URL.createObjectURL(blob);
        });
    };

    const saveImageCropperCommand = new RelayCommand(() => {
        onDownloadCropClick();
    });

    return (
        <>
            <Container>
                <Box className="Heading">Crop image</Box>
                <Box className="ContainerBody">
                    <Label>Resize and position the area you wish to crop</Label>
                    <div className="ReactCropImg">
                        {!!props.imageFile && (
                            <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={aspect}>
                                <img ref={imgRef} alt={props.fileName} src={props.imageFile} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad} />
                            </ReactCrop>
                        )}
                    </div>
                    {!!completedCrop && (
                        <div style={{ position: "absolute", left: "-9999px" }}>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                    position: "absolute",
                                    left: "-9999px",
                                }}
                            />
                        </div>
                    )}
                    <Box className="BtnPart">
                        <Button mr={2} command={saveImageCropperCommand} paletteColor="secondary" displayName={"SAVE"} />
                        <Button mr={2} command={props.closeModel!} paletteColor="default" displayName={"CANCEL"} />
                    </Box>
                </Box>
            </Container>
        </>
    );
});
