import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { container } from "tsyringe";
import { AccountStore } from "../../../Stores/Domain";
import { CustomercareViewModel } from "../CustomercareViewModel";
import { KeyValuePair } from "@shoothill/core";

class Response {
    public houseAreas: HouseAreaResponse[] = [];
    public snagTypes: SnagTypesResponse[] = [];
}
class HouseAreaResponse {
    public houseAreaDescription: string = "";
    public houseAreaType: string = "";
}
class SnagTypesResponse {
    public snagType: string = "";
    public snagTypeDescription: string = "";
}

export class GETCustomerCareEndpoint extends EndpointWithoutRequest<Response> {
    private accountStore = container.resolve(AccountStore);
    private viewModel: CustomercareViewModel;

    constructor(viewModel: CustomercareViewModel) {
        super();
        this.verb(Http.Get);
        this.path("/api/getcustomercare/" + this.accountStore.CoinsPlotId!);
        this.cache(60 * 60);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        for (const house of response.houseAreas) {
            this.viewModel.houseAreaOptions.push({
                key: house.houseAreaType,
                text: house.houseAreaDescription,
            } as KeyValuePair);
        }
        for (const snag of response.snagTypes) {
            this.viewModel.snagTypeOptions.push({
                key: snag.snagType,
                text: snag.snagTypeDescription,
            } as KeyValuePair);
        }
        //Return your model
        return {};
    }
}
