import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { YourhomeModel, YourhomeModelValidator } from "./YourhomeModel";
import { Logger } from "index";

export class YourhomeViewModel extends ViewModelBase<YourhomeModel> {
    public apiClient = new APIClient();

    constructor(model = new YourhomeModel()) {
        super(model);
        this.setValidator(new YourhomeModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands
    public updatePlotaddressoneCommand = new RelayCommand((value: string) => {
        this.setValue("plotAddressOne", value);
    });
    public updatePlotaddresstwoCommand = new RelayCommand((value: string) => {
        this.setValue("plotAddressTwo", value);
    });
    public updatePlotaddressthreeCommand = new RelayCommand((value: string) => {
        this.setValue("plotAddressThree", value);
    });
    public updatePlottownCommand = new RelayCommand((value: string) => {
        this.setValue("plotTown", value);
    });
    public updatePlotcountyCommand = new RelayCommand((value: string) => {
        this.setValue("plotCounty", value);
    });
    public updatePlotpostcodeCommand = new RelayCommand((value: string) => {
        this.setValue("plotPostCode", value);
    });
    public updateSalesagentCommand = new RelayCommand((value: string) => {
        this.setValue("salesAgent", value);
    });
    public updateAgredpriceCommand = new RelayCommand((value: string) => {
        this.setValue("agredPrice", value);
    });
    public updateDepositCommand = new RelayCommand((value: string) => {
        this.setValue("deposit", value);
    });
    public updateDevelopmentcontactnumberCommand = new RelayCommand((value: string) => {
        this.setValue("developmentContactNumber", value);
    });
    public updateDevelopmentemailaddressCommand = new RelayCommand((value: string) => {
        this.setValue("developmentEmailAddress", value);
    });
    public updateBuildcompletiontargetCommand = new RelayCommand((value: string) => {
        this.setValue("buildCompletionTarget", value);
    });
    public updateBuildcompletionactualCommand = new RelayCommand((value: string) => {
        this.setValue("buildCompletionActual", value);
    });
    public updateChoicescompletiontargetCommand = new RelayCommand((value: string) => {
        this.setValue("choicesCompletionTarget", value);
    });
    public updateChoicecompletionactualCommand = new RelayCommand((value: string) => {
        this.setValue("choiceCompletionActual", value);
    });
    public updateLegalcompletiontargetCommand = new RelayCommand((value: string) => {
        this.setValue("legalCompletionTarget", value);
    });
    public updateLegalcompletionactualCommand = new RelayCommand((value: string) => {
        this.setValue("legalCompletionActual", value);
    });
    public updateContractxchangedeadlineCommand = new RelayCommand((value: string) => {
        this.setValue("contractxchangeDeadline", value);
    });
    public updateContractexchangeactualCommand = new RelayCommand((value: string) => {
        this.setValue("contractExchangeActual", value);
    });
    public updatePlotnameCommand = new RelayCommand((value: string) => {
        this.setValue("plotName", value);
    });

    //#endregion Commands
}
