import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class CustomercareModel extends ModelBase<CustomercareModel> {
    public houseArea: string = "";
    public snagType: string = "";
    public details: string = "";
    public images: string[] = [];
    public plotNumber: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";

    constructor() {
        super();
        makeObservable(this, {
            houseArea: observable,
            snagType: observable,
            details: observable,
            images: observable,
        });
    }
}

export class CustomercareModelValidator extends Validator<CustomercareModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
