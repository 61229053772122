import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { StagesModel } from "./Stages/StagesModel";

export class BuildprogressModel extends ModelBase<BuildprogressModel> {
    public stagesModels = observable<StagesModel>([]);

    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class BuildprogressModelValidator extends Validator<BuildprogressModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
