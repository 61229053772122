import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import UserDetailsViewModel from "../UserDetail/UserDetailsViewModel";
import { KeyValuePair } from "@shoothill/core";

class Response {
    public developments: DevelopmentResponse[] = [];
}
class DevelopmentResponse {
    public developmentId: string = "";
    public developmentName: string = "";
}

export class GETDevelopemntsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: UserDetailsViewModel;
    constructor(viewModel: UserDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path("/api/development/getall");
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        for (let item of response.developments) {
            this.viewModel.developmentOptions.push({
                key: item.developmentId,
                text: item.developmentName,
            } as KeyValuePair);
        }
        //Return your model
        return {};
    }
}
