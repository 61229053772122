import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle?: boolean;
}
export const DocumentsSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_8708" data-name="Group 8708" transform="translate(-416 -571)">
                <g id="Group_8264" data-name="Group 8264">
                    <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(416 571)" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="23.75" fill="none" />
                    </g>
                    <g id="Group_5580" data-name="Group 5580" transform="translate(17161 19754)">
                        <path
                            id="Subtraction_7"
                            data-name="Subtraction 7"
                            d="M15,20H0V0H10V4.167h5V20ZM2.855,16.058a.562.562,0,0,0,0,1.124h8.989a.562.562,0,0,0,0-1.124Zm0-3.089a.562.562,0,1,0,0,1.123h8.989a.562.562,0,1,0,0-1.123Zm0-3.091a.562.562,0,1,0,0,1.123h8.989a.562.562,0,0,0,0-1.123Zm0-3.089a.562.562,0,1,0,0,1.123h8.989a.562.562,0,0,0,0-1.123Zm0-3.091a.562.562,0,1,0,0,1.123H6.788a.562.562,0,1,0,0-1.123Z"
                            transform="translate(-16725.834 -19165.834)"
                            fill="#fefefe"
                        />
                        <g id="Group_5579" data-name="Group 5579" transform="translate(-16730 -19170)">
                            <path
                                id="Path_3618"
                                data-name="Path 3618"
                                d="M31.292,10.823V7.713A1.717,1.717,0,0,0,29.579,6H16.713A1.717,1.717,0,0,0,15,7.713V25.635a1.717,1.717,0,0,0,1.713,1.713H18.09V29.4a1.6,1.6,0,0,0,1.6,1.6H33.346a1.6,1.6,0,0,0,1.6-1.6V15.138a1.578,1.578,0,0,0-.469-1.129Zm1.733,3.323H30.362a.476.476,0,0,1-.475-.475V11.008ZM18.09,10.688V26.225H16.713a.59.59,0,0,1-.59-.59V7.713a.59.59,0,0,1,.59-.59H29.579a.59.59,0,0,1,.59.59V9.7l-.14-.14A1.583,1.583,0,0,0,28.9,9.09H19.688A1.6,1.6,0,0,0,18.09,10.688ZM33.82,29.4a.476.476,0,0,1-.475.475H19.688a.476.476,0,0,1-.475-.475V10.688a.476.476,0,0,1,.475-.475h9.076v3.458a1.6,1.6,0,0,0,1.6,1.6H33.82Z"
                                transform="translate(-15 -6)"
                                fill="#fefefe"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
