/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import footerLogo from "Assets/footerLogo.png";
import { Main, ExternalLink } from "./FooterView.styles";
import { Box } from "Components";
import useMediaQuery from "../../Application/Hooks/useMediaQuery";
import { ThemedText } from "../../Application";
import FaceBookLogo from "Assets/SVGs/Facebook";
import InstagramLogo from "Assets/SVGs/Instagram";
import TwitterLogo from "Assets/SVGs/Twitter";
import YoutubeLogo from "Assets/SVGs/Youtube";
import styled from "@emotion/styled";

const SocialMediaIcon = styled(Box)`
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    a {
        display: inline-block;
        svg {
            width: 20px;
            height: auto;
            fill: #fff;
        }
    }
`;
const FooterText = styled(Box)`
    .ThemedText {
        font: var(--unnamed-font-style-normal) Lato;
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        padding: 0 8px;
    }
`;

export const Footer: React.FC = () => {
    const isMobile = useMediaQuery("(max-width: 767px)");

    return (
        <Main>
            <Box
                backgroundColor={"#4A4A4A"}
                paddingLeft={isMobile ? "10px" : "60px"}
                paddingRight={isMobile ? "10px" : "60px"}
                paddingTop={isMobile ? "10px" : "15px"}
                paddingBottom={isMobile ? "10px" : "15px"}
                borderTop={"5px solid#F47E44"}
                textAlign={"center"}
            >
                <Box paddingTop={"10px"} paddingBottom={"10px"}>
                    <img width={115} src={footerLogo} alt={"no image"} />
                    {/* <FooterText marginTop={"10px"}>
                        <ThemedText className="ThemedText">Allison Homes, Southfields Business Park, Falcon Way, Bourne, Lincolnshire PE10 0FF</ThemedText>
                    </FooterText>
                    <FooterText display={"flex"} justifyContent={"center"} marginTop={"4px"} flexWrap={isMobile ? "wrap" : "nowrap"}>
                        <ThemedText className="ThemedText">T:01778 391550</ThemedText>
                        <ThemedText className="ThemedText">E: enquiries@allison-home.co.uk</ThemedText>
                    </FooterText>
                    <SocialMediaIcon>
                        <ExternalLink
                            target={"_blank"}
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                                width: "16px",
                            }}
                            href="https://www.facebook.com/AllisonHomesUK"
                        >
                            <FaceBookLogo />
                        </ExternalLink>
                        <ExternalLink
                            target={"_blank"}
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                            }}
                            href="https://www.instagram.com/allison_homes"
                        >
                            <InstagramLogo />
                        </ExternalLink>
                        <ExternalLink
                            target={"_blank"}
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                            }}
                            href="https://twitter.com/AllisonHomesUK"
                        >
                            <TwitterLogo />
                        </ExternalLink>
                        <ExternalLink
                            target={"_blank"}
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                            }}
                            href="https://www.youtube.com/c/AllisonHomes"
                        >
                            <YoutubeLogo />
                        </ExternalLink>
                    </SocialMediaIcon>
                    <FooterText>
                        <ThemedText className="ThemedText">
                            © Copyright 2023 Allison Homes all rights reserved Allison Homes is a trading name of Allison Homes Limited v1.0.0
                        </ThemedText>
                    </FooterText> */}
                </Box>
            </Box>
        </Main>
    );
};
