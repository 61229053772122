import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionStyles, IChoiceGroupStyles, ITextStyles, mergeStyleSets, Stack } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../../../../Application/Commands";
import { TextBase } from "./TextBase";
import { theme } from "../../../../../Styles";

/**
 * Radio button group interface.
 */
export interface IRadioButtonsBaseProps {
    /**
     * An optional class name for use with the radio button group.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A collection of options to display in the radio button group.
     */
    options: IChoiceGroupOption[];
    /**
     * A selection value to use with the radio button group. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display as the label of the radio button group.
     */
    displayName?: string;
    /**
     * Text content to display as the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the radio button group.
     */
    styles?: IChoiceGroupStyles;
    /**
     * Styling of the radio button options.
     */
    optionStyles?: IChoiceGroupOptionStyles;
    /**
     * Styling for the error message.
     */
    errorStyles?: ITextStyles;
}

export const RadioButtonsBase: React.FC<IRadioButtonsBaseProps> = observer((props) => {
    // #region Code Behind

    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const getClasseNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getOptions = (): IChoiceGroupOption[] => {
        return isNullOrUndefined(props.optionStyles)
            ? props.options
            : props.options.map((option) => {
                  option.styles = mergeStyleSets(props.optionStyles, option.styles);

                  return option;
              });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const onChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        props.command.execute(option?.key);
    };

    // #endregion Code Behind

    return (
        <Stack>
            <ChoiceGroup className={getClasseNames()} label={props.displayName} onChange={onChange} options={getOptions()} selectedKey={props.value()} styles={props.styles} />
            {canDisplayErrorMessage() && <TextBase styles={props.errorStyles}>{getValidationMessage()}</TextBase>}
        </Stack>
    );
});
