import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class YourhomeModel extends ModelBase<YourhomeModel> {
    public plotAddressOne: string = "Dummy Text Data";
    public plotAddressTwo: string = "Dummy Text Data";
    public plotAddressThree: string = "Dummy Text Data";
    public plotTown: string = "Dummy Text Data";
    public plotCounty: string = "Dummy Text Data";
    public plotPostCode: string = "Dummy Text Data";
    public salesAgent: string = "Dummy Text Data";
    public agredPrice: string = "Dummy Text Data";
    public deposit: string = "Dummy Text Data";
    public developmentContactNumber: string = "Dummy Text Data";
    public developmentEmailAddress: string = "Dummy Text Data";
    public buildCompletionTarget: string = "Dummy Text Data";
    public buildCompletionActual: string = "Dummy Text Data";
    public choicesCompletionTarget: string = "Dummy Text Data";
    public choiceCompletionActual: string = "Dummy Text Data";
    public legalCompletionTarget: string = "Dummy Text Data";
    public legalCompletionActual: string = "Dummy Text Data";
    public contractxchangeDeadline: string = "Dummy Text Data";
    public contractExchangeActual: string = "Dummy Text Data";
    public plotName: string = "Dummy Text Data";

    constructor() {
        super();
        makeObservable(this, {
            plotAddressOne: observable,
            plotAddressTwo: observable,
            plotAddressThree: observable,
            plotTown: observable,
            plotCounty: observable,
            plotPostCode: observable,
            salesAgent: observable,
            agredPrice: observable,
            deposit: observable,
            developmentContactNumber: observable,
            developmentEmailAddress: observable,
            buildCompletionTarget: observable,
            buildCompletionActual: observable,
            choicesCompletionTarget: observable,
            choiceCompletionActual: observable,
            legalCompletionTarget: observable,
            legalCompletionActual: observable,
            contractxchangeDeadline: observable,
            contractExchangeActual: observable,
            plotName: observable,
        });
    }
}

export class YourhomeModelValidator extends Validator<YourhomeModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
