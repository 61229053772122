import { EndpointWithoutRequest, Http } from "../../../Application/Helpers";
import { Logger } from "../../../index";
import { AppUrls } from "../../../AppUrls";
import { RoleStore } from "./RoleStore";
import { RoleModel } from "../../../Views/Admin/Roles/RoleModel";

class Response {
    public id: string = "";
    public name: string = "";
}

export class GetRolesEndpoint extends EndpointWithoutRequest<Response> {
    private roleStore: RoleStore;

    constructor(roleStore: RoleStore) {
        super();
        this.roleStore = roleStore;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.GetRoles);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logDebug("GetRolesEndpoint Response", response);
        let roleModels: RoleModel[] = [];
        response.forEach((role: Response, index: number) => {
            let roleModel = new RoleModel(role.id);
            roleModel.fromResponse(role);
            roleModels.push(roleModel);
        });
        this.roleStore.setRoles(roleModels);
        //Return your model
        Logger.logDebug("GetRolesEndpoint Response", roleModels);
        return roleModels;
    }
}
