import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { nanoid } from "nanoid";

export class FileUploaderModel extends ModelBase<FileUploaderModel> {
    public fileName: string = "";
    public isBuildStageAdmin: boolean = false;
    public files = observable<FileModel>([]);
    constructor() {
        super();
        makeObservable(this, {
            files: observable,
            fileName: observable,
        });
    }
}

export class FileModel extends ModelBase<FileModel> {
    public file: File | null = null;
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            file: observable,
            fileName: observable,
            fileSizeBytes: observable,
            mimeType: observable,
            documentUrl: observable,
        });
    }
}

export class FileUploaderModelValidator extends Validator<FileUploaderModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
