import React from "react";
import { observer } from "mobx-react-lite";

import styled from "@emotion/styled";
import { YourhomeViewModel } from "../YourhomeViewModel";

import { Box, Label, formatDate } from "Application";

const FieldTitle = styled(Label)`
    margin: 0 0 10px;
    font: normal normal bold 14px/17px Lato;
    letter-spacing: -0.21px;
    color: #4a4a4a;
`;

const FieldLabelSmall = styled(Label)`
    font-size: 12px;
    font-weight: 400;
    margin: 3px 0 0;
    letter-spacing: -0.18px;
`;

const ListBox = styled(Box)`
    label {
        font: normal normal normal 12px/17px Lato;
        letter-spacing: -0.18px;
        color: #4a4a4a;
        &:first-child {
            font: normal normal bold 12px/17px Lato;
        }
    }
`;

interface props {
    viewModel: YourhomeViewModel;
}

export const CompletionGridView: React.FC<props> = observer((props: props) => {
    const viewModel = props.viewModel;

    const DesktopPanel = () => {
        console.log(viewModel.getValue("contractExchangeDeadlineDateUTC"));
        return (
            <Box py={"28px"} pb={"50px"} grid dc={"repeat(4,1fr)"} rowGap={"5px"} borderBottom={"1px dashed #f47e44"}>
                <Box>
                    <FieldTitle mb={"15px"}>Build Completion</FieldTitle>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall fontStyle={"h5"}>
                            {viewModel.getValue("buildCompletionTargetDateUTC") !== null ? formatDate(viewModel.getValue("buildCompletionTargetDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("buildCompletionActualDateUTC") !== null ? formatDate(viewModel.getValue("buildCompletionActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                </Box>
                {/* <Box>
                    <FieldTitle mb={"15px"}>Choices Complete</FieldTitle>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>{formatDate(viewModel.getValue("choicesCompleteTargetDateUTC"))}</FieldLabelSmall>
                    </Box>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>{formatDate(viewModel.getValue("choicesCompleteActualDateUTC"))}</FieldLabelSmall>
                    </Box>
                </Box> */}
                <Box>
                    <FieldTitle mb={"15px"}>Contract Exchange</FieldTitle>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("contractExchangeDeadlineDateUTC") !== null ? formatDate(viewModel.getValue("contractExchangeDeadlineDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("contractExchangeActualDateUTC") !== null ? formatDate(viewModel.getValue("contractExchangeActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                </Box>
                <Box>
                    <FieldTitle mb={"15px"}>Legal Completion</FieldTitle>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("legalCompletionTargetDateUTC") !== null ? formatDate(viewModel.getValue("legalCompletionTargetDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("legalCompletionTargetDateUTC") !== null ? formatDate(viewModel.getValue("legalCompletionActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </Box>
                </Box>
            </Box>
        );
    };

    const MobilePanel = () => {
        return (
            <Box py={"28px"} grid dc={"1fr"} rowGap={"24px"} borderBottom={"1px dashed #f47e44"}>
                <Box>
                    <FieldTitle mb={"15px"}>Build Completion</FieldTitle>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall fontStyle={"h5"}>
                            {viewModel.getValue("buildCompletionTargetDateUTC") ? formatDate(viewModel.getValue("buildCompletionTargetDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("buildCompletionActualDateUTC") ? formatDate(viewModel.getValue("buildCompletionActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                </Box>
                {/* <Box>
                    <FieldTitle mb={"15px"}>Choices Complete</FieldTitle>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>{formatDate(viewModel.getValue("choicesCompleteTargetDateUTC"))}</FieldLabelSmall>
                    </Box>
                    <Box grid dc={"50px 1fr"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>{formatDate(viewModel.getValue("choicesCompleteActualDateUTC"))}</FieldLabelSmall>
                    </Box>
                </Box> */}

                <Box>
                    <FieldTitle mb={"15px"}>Contract Exchange</FieldTitle>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("contractExchangeDeadlineDateUTC") ? formatDate(viewModel.getValue("contractExchangeDeadlineDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {viewModel.getValue("contractExchangeActualDateUTC") ? formatDate(viewModel.getValue("contractExchangeActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                </Box>
                <Box>
                    <FieldTitle mb={"15px"}>Legal Completion</FieldTitle>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Target:</Label>
                        <FieldLabelSmall>
                            {formatDate(viewModel.getValue("legalCompletionTargetDateUTC")) ? formatDate(viewModel.getValue("legalCompletionTargetDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                    <ListBox grid dc={"50px 1fr"} columnGap={"12px"}>
                        <Label fontStyle={"h5"}>Actual:</Label>
                        <FieldLabelSmall>
                            {formatDate(viewModel.getValue("legalCompletionActualDateUTC")) ? formatDate(viewModel.getValue("legalCompletionActualDateUTC")) : "-"}
                        </FieldLabelSmall>
                    </ListBox>
                </Box>
            </Box>
        );
    };

    return <>{!viewModel.isMobile ? <>{DesktopPanel()}</> : <>{MobilePanel()}</>}</>;
});
