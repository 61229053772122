import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Label } from "Application";
import { DocumentIcon } from "../../Assets/Icons/DocumentIcon";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { DocumentListViewModel } from "./DocumentListViewModel";
interface Props {
    id: string;
    title: string;
    fileName: string;
    viewModel: DocumentListViewModel;
}
export const DocumentView: React.FC<Props> = observer((props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    return (
        <>
            <Box style={{ cursor: "pointer" }} onClick={() => props.viewModel.getDocumentCommand.execute(props.id)}>
                <Box mt={isMobile ? "12px " : 3} display={"flex"} alignItems={"center"} style={{ gap: "12px" }}>
                    <DocumentIcon style={{ flex: "20px 0 0" }} />
                    <Label mt={"0"} style={{ font: "normal normal normal 14px/26px Lato", letterSpacing: "0", color: "#4A4A4A", cursor: "pointer" }}>
                        {props.fileName}
                    </Label>
                </Box>
            </Box>
        </>
    );
});
