import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class BuildStageModel extends ModelBase<BuildStageModel> {
    public sequence: string = "";
    public stageCode: string = "";
    public description: string = "";
    public imageUrl: string = "";
    public isVisible: boolean = false;
    public isCompleted: boolean = false;
    constructor() {
        super();
        makeObservable(this, {
            imageUrl: observable,
            isVisible: observable,
            sequence: observable,
            stageCode: observable,
            description: observable,
            isCompleted: observable,
        });
    }
}

export class BuildStageModelValidator extends Validator<BuildStageModel> {
    constructor() {
        super();
        this.ruleFor("description").notEmpty().withMessage("Please enter a description");
    }
}
