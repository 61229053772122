import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { YourhomeViewModel } from "../YourhomeViewModel";
import { Box, Label } from "Application";
const FieldTitle = styled(Label)`
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin-top: 0;
    letter-spacing: -0.21px;
`;

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 0;
    letter-spacing: -0.21px;
`;

interface props {
    viewModel: YourhomeViewModel;
}

export const PricesGridView: React.FC<props> = observer((props: props) => {
    const viewModel = props.viewModel;

    const DesktopPanel = () => {
        return (
            <>
                <Box display={"flex"} flexDirection={"column"} py={"28px"} borderBottom={"1px solid #f47e44"}>
                    <Box grid dc={"200px auto"} rowGap={"10px"}>
                        <FieldTitle>Purchase Price:</FieldTitle>
                        <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("marketingPrice")}</FieldLabel>

                        <FieldTitle>Discounts/Allowances:</FieldTitle>
                        <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("incentives")}</FieldLabel>

                        <FieldTitle>Purchased Upgrades:</FieldTitle>
                        <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("purchasedUpgrades")}</FieldLabel>

                        <FieldTitle>Total Purchase Price:</FieldTitle>
                        <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("netPurchasePrice")}</FieldLabel>
                    </Box>
                    <FieldLabel style={{ width: "100%", marginTop: "10px" }} fontStyle={"h4"}>
                        Part Exchange may not have been included The price may be subject to change depending on items being amended. Please liaise with the Sales executive to
                        discuss any further items and ensure that the price set out here, is the same that has been communicated to you by your legal representative.
                    </FieldLabel>
                </Box>
            </>
        );
    };

    const MobilePanel = () => {
        return (
            <Box py={"28px"} grid dc="1fr" rowGap={"10px"} borderBottom={"1px solid #f47e44"}>
                <Box grid dc={"140px auto"} columnGap={"10px"} rowGap={"5px"}>
                    <FieldTitle>Purchase Price:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("marketingPrice")}</FieldLabel>
                </Box>

                <Box grid dc={"140px auto"} columnGap={"10px"} rowGap={"5px"}>
                    <FieldTitle>Discounts/Allowances:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("incentives")}</FieldLabel>
                </Box>

                <Box grid dc={"140px auto"} columnGap={"10px"} rowGap={"5px"}>
                    <FieldTitle>Purchased Upgrades:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("purchasedUpgrades")}</FieldLabel>
                </Box>

                <Box grid dc={"140px auto"} columnGap={"10px"} rowGap={"5px"}>
                    <FieldTitle>Total Purchase Price:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("netPurchasePrice")}</FieldLabel>
                </Box>
                <FieldLabel fontStyle={"h4"}>
                    The price may be subject to change depending on items being amended. Please liaise with the Sales executive to discuss any further items and ensure that the
                    price set out here, is the same that has been communicated to you by your legal representative.
                </FieldLabel>
            </Box>
        );
    };

    return <>{!viewModel.isMobile ? <>{DesktopPanel()}</> : <>{MobilePanel()}</>}</>;
});
