import { makeObservable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { DashboardModel, DashboardModelValidator } from "./DashboardModel";
import { PanelViewModel } from "./Panel/PanelViewModel";
import { GETDashboardEndpoint } from "./Endpoints/GETDashboardEndpoint";

export class DashboardViewModel extends ViewModelBase<DashboardModel> {
    public apiClient = new APIClient();
    public panelViewModel: PanelViewModel = new PanelViewModel();
    public buildProgressModifiedDateUTC: string = "";
    constructor(model = new DashboardModel()) {
        super(model);
        this.setValidator(new DashboardModelValidator());
        makeObservable(this, {});
    }

    public get buildCompletedCount(): any {
        return this.model.buildCompletedCount ? this.model.buildCompletedCount : 0;
    }

    public get buildOverallCount(): any {
        return this.model.buildOverallCount ? this.model.buildOverallCount : 0;
    }

    public get salesCompletedCount(): any {
        return this.model.salesCompletedCount ? this.model.salesCompletedCount : 0;
    }

    public get salesOverallCount(): any {
        return this.model.salesOverallCount ? this.model.salesOverallCount : 0;
    }

    public get documentCount(): any {
        return this.model.documentCount ? this.model.documentCount : 0;
    }

    public get reportedSnagsCount(): any {
        return this.model.reportedSnagsCount ? this.model.reportedSnagsCount : 0;
    }

    public getDashboard = (coinsPlotId: string) => {
        this.apiClient.sendAsync(new GETDashboardEndpoint(this, coinsPlotId));
    };
}
