import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { LoginViewModel } from "./LoginViewModel";
import { Box, setPageTitle, Button, Label, CheckBox, Input } from "../../../Application";

import { ThemedLoader } from "../../../Styles/ThemedPrimitives/Style1/ThemedLoader";
import styled from "@emotion/styled";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { PasswordSVG } from "../../../Assets/Icons/PasswordSVG";
import { PasswordSVGVisible } from "../../../Assets/Icons/PasswordSVGVisible";

const LoginForm = styled(Box)`
    font-size: 12px;
    font-weight: 400;
    .formField {
        margin-bottom: 25px;
        p {
            margin-bottom: 15px;
            font: normal normal normal 12px/14px Lato;
            letter-spacing: 0px;
            color: #4a4a4a;
        }
        & > div > div:first-child {
        }
        .form-control {
            grid-template-columns: 30px auto;
            gap: 8px;
        }
    }
    label.placeholder,
    label.select-label {
        font: normal normal 300 14px/17px Lato;
        letter-spacing: 0px;
        color: #4a4a4a;
        margin: 0 0 5px;
        font-weight: 700;
    }
    input {
        height: 30px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .inputbox {
        position: relative;
        &:focus,
        &:focus-within,
        &:hover {
            border: 1px solid #f7a177 !important;
        }
    }
    input[type="checkbox"] {
        width: 30px !important;
        border-radius: 0 !important;
        height: 30px !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccbcb !important;
        transform: inherit !important;
        cursor: pointer;
        & + div label {
            margin: 0;
            font: normal normal normal 14px/17px Lato;
            letter-spacing: 0px;
            color: #4a4a4a;
        }
    }
    button label {
        margin: 0;
        text-decoration: underline;
        font: normal normal normal 9px/10px Lato;
        letter-spacing: 0.24px;
        color: #2a71cd;
    }
    .forgot-pass {
        color: #2a71cd;
        text-decoration: underline;
        font: normal normal normal 9px/10px Lato;
        @media only screen and (max-width: 767px) {
            font: normal normal normal 8px/10px Lato;
        }
    }
`;

/**
 * Login page view.
 */
const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new LoginViewModel());
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setPageTitle("Sign In");
    }, []);
    // #endregion Code Behind

    const handleEyeClick = () => {
        setVisible(!visible);
    };

    const getEyeIcon = () => {
        if (!visible) {
            return <PasswordSVG onClick={handleEyeClick} style={{ transform: "scale(1)", transformOrigin: "26px 6px" }} />;
        }
        return <PasswordSVGVisible onClick={handleEyeClick} style={{ transform: "scale(1)", transformOrigin: "26px 6px" }} />;
    };

    const renderForm = () => (
        <>
            <LoginForm alignContent={"center"} dc="1fr" rowGap={"30px"} maxWidth={"262px"}>
                <Label mb={"10px"} style={{ color: "red" }}>
                    {viewModel.apiClient.ValidationMessage}
                </Label>
                <Box className="formField">
                    <Input
                        labelColor={"black"}
                        command={viewModel.updateEmailCommand}
                        displayName="Login"
                        canClearText={true}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        placeholder="Username or email address"
                    />
                </Box>
                <Box className="formField">
                    <Input
                        labelColor={"black"}
                        command={viewModel.updatePasswordCommand}
                        suffix={getEyeIcon()}
                        placeholder="Password"
                        displayName="Password"
                        type={!visible ? "password" : "text"}
                        canClearText={true}
                        value={() => viewModel.getValue("password")}
                        validationMessage={() => viewModel.getError("password")}
                    />
                </Box>
                <Box className="formField" display={"flex"} justifyContent={"space-between"} mb={"30px"}>
                    <CheckBox
                        className="checkBoxModule"
                        command={viewModel.rememberMeCommand}
                        value={() => viewModel.getValue("rememberMe")}
                        displayName={"Remember me"}
                        labelColor={"black"}
                    />
                    <Link className="forgot-pass" onClick={() => loginHomeViewModel.navigateToForgotPasswordCommand.execute()}>
                        Forgot Password?
                    </Link>
                </Box>
                <Button
                    displayName="Continue"
                    command={viewModel.loginCommand}
                    paletteColor="secondary"
                    type="submit"
                    style={{
                        width: "100%",
                        borderRadius: 0,
                        font: "normal normal 300 12px/16px Lato",
                        letterSpacing: "0.36px",
                        color: "#FFFFFF",
                    }}
                />
                <Box className="formField" style={{ marginTop: "40px" }}>
                    <p>
                        Welcome to your online new home portal. Here you can track the build progress of your new home, access helpful guides, view important documentation and much
                        more - all in one easy to use space.
                    </p>
                    <p>You can check your progress, ask questions, view photos and download your documentation all from the comfort of your home.</p>
                </Box>
            </LoginForm>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;
