import React from "react";

const BuildComplete = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
            <g id="Group_8307" data-name="Group 8307" transform="translate(-423 -197)">
                <g id="Group_8303" data-name="Group 8303" transform="translate(422.887 196.887)">
                    <g id="Group_5581" data-name="Group 5581" transform="translate(0 0)">
                        <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(0.113 0.113)" fill="#fff" stroke="#F47E44" strokeWidth="5">
                            <circle cx="48" cy="48" r="48" stroke="none" />
                            <circle cx="48" cy="48" r="45.5" fill="none" />
                        </g>
                    </g>
                    <path
                        id="Path_3894"
                        data-name="Path 3894"
                        d="M20.177,1029.36h10.1v5.051h-10.1Zm11.784,0h10.1v5.051h-10.1ZM2.5,1036.094H12.6v5.05H2.5Zm11.784,0h10.1v5.05h-10.1Zm11.784,0h10.1v5.05h-10.1Zm11.784,0h10.1v5.05h-10.1Zm-29.461,6.734h10.1v5.05H8.392Zm11.784,0h10.1v5.05h-10.1Zm11.784,0h10.1v5.05h-10.1ZM2.5,1049.562H12.6v5.051H2.5Zm11.784,0h10.1v5.051h-10.1Zm11.784,0h10.1v5.051h-10.1Zm11.784,0h10.1v5.051h-10.1Z"
                        transform="translate(23.671 -993.547)"
                        fill="#F47E44"
                    />
                </g>
            </g>
        </svg>
    );
};

export default BuildComplete;
