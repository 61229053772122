import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { StagesModel } from "./Stages/StagesModel";

export class BuildprogressModel extends ModelBase<BuildprogressModel> {
    public stagesModels = observable<StagesModel>([]);
    public plotNumber: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    public buildOverallCount: number = 0;
    constructor() {
        super();
        makeObservable(this, {
            plotNumber: observable,
            developmentName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
            buildOverallCount: observable,
        });
    }
}

export class BuildprogressModelValidator extends Validator<BuildprogressModel> {
    constructor() {
        super();
    }
}
