import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { StagesModel, StagesModelValidator } from "./StagesModel";
import { Logger } from "index";

export class StagesViewModel extends ViewModelBase<StagesModel> {
    public apiClient = new APIClient();

    constructor(model = new StagesModel()) {
        super(model);
        this.setValidator(new StagesModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands
    public updateStatusCommand = new RelayCommand((value: string) => {
        this.setValue("status", value);
    });
    public updateTitleCommand = new RelayCommand((value: string) => {
        this.setValue("title", value);
    });
    public updateUpdateddateCommand = new RelayCommand((value: string) => {
        this.setValue("updatedDate", value);
    });
    public updateDescriptionCommand = new RelayCommand((value: string) => {
        this.setValue("description", value);
    });

    //#endregion Commands
}
