import * as React from "react";
import { SVGProps } from "react";

type Props = {
    color?: string;
} & SVGProps<SVGSVGElement>;

export const DeleteIcon = (props: Props) => (
    <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14.5" viewBox="0 0 14 14.5" {...props}>
        <g id="trash" transform="translate(-1163 -346)">
            <path id="Path_6891" data-name="Path 6891" d="M0,0H12L10,8H2Z" transform="translate(1164 352)" fill="#6a6a6a" stroke="#6a6a6a" strokeLinejoin="round" strokeWidth="1" />
            <g id="Rectangle_2451" data-name="Rectangle 2451" transform="translate(1163 348)" fill="none" stroke="#6a6a6a" strokeWidth="1">
                <rect width="14" height="3" stroke="none" />
                <rect x="0.5" y="0.5" width="13" height="2" fill="none" />
            </g>
            <g id="Rectangle_2452" data-name="Rectangle 2452" transform="translate(1167 346)" fill="none" stroke="#6a6a6a" strokeWidth="1">
                <rect width="6" height="3" stroke="none" />
                <rect x="0.5" y="0.5" width="5" height="2" fill="none" />
            </g>
        </g>
    </svg>
);
