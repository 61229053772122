// Libraries
import * as MobX from "mobx";

// Core
import { ViewModelBase } from "@shoothill/core";

export class BlankViewModel extends ViewModelBase {
    public plotNumber: string = "033";
    public developmentName: string = "Harriers Rest";
    public address1: string | null = "221B Harriers Rest";
    public address2: string | null = "off Eagle Lane";
    public address3: string | null = "Wittering";
    public address4: string | null = "Lincs";
    public postcode: string | null = "TE5 EST";

    constructor() {
        super(null);
    }
}
