import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import PotalUserDetailsViewModel from "../../PortalUsers/PortalUserDetail/PortalUserDetailsViewModel";
import { isNullOrEmpty, KeyValuePair } from "@shoothill/core";
import { runInAction } from "mobx";
import { AccountStore } from "../../../../../Stores/Domain";
import { container } from "tsyringe";

class Response {
    public plots: PlotResponse[] = [];
}
class PlotResponse {
    public plotId: string = "";
    public plotAddress: string = "";
}

export class GETPlotEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PotalUserDetailsViewModel;
    private accountStore = container.resolve(AccountStore);
    constructor(coinsDevelopmentId: string, viewModel: PotalUserDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(`/api/plot/getplots/${coinsDevelopmentId}/${viewModel.userId}`);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        runInAction(() => {
            this.viewModel.plotOptions = [];
            for (const item of response.plots) {
                if (!isNullOrEmpty(item.plotId)) {
                    this.viewModel.plotOptions.push({
                        key: item.plotId,
                        text: isNullOrEmpty(item.plotAddress) ? "Plot " + item.plotId : "Plot " + item.plotId + " " + item.plotAddress,
                    } as KeyValuePair);
                }
            }
        });
        //Return your model
        return {};
    }
}
