import { isEmptyOrWhitespace, isNullOrEmpty, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, FocusEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { Box, ErrorExclamationView, getThemeColorOption, getThemeShapeOption, getThemeSizeOption, ICommand, IKeyState, Label, pxToRem, RelayCommand, theme } from "Application";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "Styles";
import { ClearText } from "../../General/ClearText";
import { FieldLabel, InputWrapper } from "../CommonStyles";
import { IMaskInput, useIMask } from "react-imask";
import styled from "@emotion/styled";
import { PasswordSVG } from "../../../Assets/Icons/PasswordSVG";

const PassClearBtn = styled.div`
    display: flex;
    position: absolute;
    right: 10px;
`;
/**
 * Button interface.
 */
interface IInputBaseProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string | number;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * An icon to display on the button.
     */
    iconName?: string;
    /**
     * Treats the component as a textarea.
     */
    multiline?: boolean;
    /**
     * Number of rows in a multiline box
     */
    rows?: number;
    /**
     * Forces browsers to not fill the text
     */
    autoFill?: boolean;
    /**
     * Styling of the control.
     */
    style?: CSSProperties | undefined;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * The color of the label.
     */
    labelColor?: themeColourOptions;
    /**
     * The description text to show under the text.
     */
    description?: string;
    /**
     * Any JSX Element
     */
    prefix?: JSX.Element;
    /**
     * Any JSX Element
     */
    suffix?: JSX.Element;
    /**
     * The control type - password or text.
     */
    type?: "password" | "text" | "email" | "number";
    /**
     * Masked options for the input.
     */
    /**
     * Set if you want to disable the input.
     */
    canClearText?: boolean;
    readonly?: boolean;
    maskedOptions?: any;
    noHeader?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}
export type Ref = HTMLButtonElement;
// @ts-ignore
export const Input: React.FC<IInputBaseProps> = observer((props: IInputBaseProps) => {
    const size = getThemeSizeOption(props.size);
    const shape = getThemeShapeOption(props.shape);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const isMultiLine = !isNullOrUndefined(props.multiline) ? props.multiline : false;
    const labelColor = getThemeColorOption(props.labelColor);
    // #region Code Behind
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editinput"]: true,
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        let newValue = e.currentTarget.value;
        updateValue(newValue);
    };
    const updateValue = (newValue: any) => {
        if (!isEmptyOrWhitespace(newValue.toString()) && newValue.toString()!.length > props.maxLength!) {
            return;
        }
        /* if (props.type === "number") {
            newValue = parseFloat(newValue as any);
        }*/
        props.command.execute(newValue, keyState.current);
    };
    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getStyles = (): {} | undefined => {
        return !isNullOrUndefined(props.style) ? props.style : undefined;
    };

    const getType = (): string => {
        return isEmptyOrWhitespace(props.type) ? "text" : props.type!;
    };

    const canDisplayClear = (): boolean => {
        if (props.canClearText === false) {
            return false;
        } else {
            if (!isNullOrUndefined(props.value())) {
                return !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
            }
            return false;
        }
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | undefined): void => {
        let keyId: number = event!.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            updateValue(props.value());
        }
    };
    const clearTextCommand = new RelayCommand(() => {
        props.command.execute("");
    });
    // #endregion Code Behind

    const displayLabel = (
        <Box flexBox style={{ gap: "6px" }} alignItems={"center"}>
            <FieldLabel htmlFor={props.id} className="placeholder" style={{ color: haveError ? "#c52f3e" : "#4a4a4a" }}>
                {props.displayName}
            </FieldLabel>
            <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />
        </Box>
    );

    const multiLineTextBox = (
        <Box showIf={isMultiLine} style={getStyles()}>
            <Box className={"inputbox"}>
                <textarea
                    id={props.id}
                    className={getClassNames()}
                    disabled={isDisabled()}
                    autoComplete={getAutoFill()}
                    prefix={props.prefix as any}
                    onChange={onChange}
                    //onKeyDown={onKeyDown}
                    placeholder={props.placeholder}
                    value={props.value()}
                    rows={props.rows}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                ></textarea>
                {canDisplayClear() && <ClearText command={clearTextCommand} />}
            </Box>
        </Box>
    );
    const inputEditBox = (
        <Box showIf={!isMultiLine && !props.readonly}>
            <Box className={"inputbox"} style={getStyles()} borderColor={haveError ? "#c52f3e !important" : "#4a4a4a"}>
                <Box showIf={!isNullOrUndefined(props.prefix)} ml={1}>
                    {props.prefix}
                </Box>
                <Box flexBox style={{ width: "100%", gap: 0 }}>
                    <IMaskInput
                        autoFocus={props.autoFocus}
                        {...props.maskedOptions}
                        value={props.value()?.toString()}
                        onAccept={(value, mask) => {
                            updateValue(mask.unmaskedValue);
                            //console.log(value, mask);
                        }}
                        autoComplete={getAutoFill()}
                        onKeyDown={onKeyDown}
                        type={getType()}
                        id={props.id}
                        placeholder={props.placeholder}
                        readOnly={props.readonly}
                        unmask={"typed"}
                        className={getClassNames()}
                        disabled={isDisabled()}
                    />
                </Box>
                <PassClearBtn>
                    <Box showIf={!isNullOrUndefined(props.suffix)} ml={1} flexBox alignItems={"center"}>
                        {props.suffix}
                    </Box>
                    <Box>{canDisplayClear() && <ClearText command={clearTextCommand} />}</Box>
                </PassClearBtn>
            </Box>
        </Box>
    );

    return (
        <>
            {!props.readonly ? (
                <InputWrapper shape={shape}>
                    {!props.noHeader && displayLabel}
                    {multiLineTextBox}
                    {inputEditBox}
                </InputWrapper>
            ) : (
                <Box showIf={props.readonly} flexBox alignItems={"self-end"}>
                    <Label>{props.value()}</Label>
                </Box>
            )}
        </>
    );
});
Input.defaultProps = {
    maxLength: 10000,
    maskedOptions: { mask: /.*/ },
};
