import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { ChoicesViewModel } from "./ChoicesViewModel";
import { ChoiceView } from "./Choice/ChoiceView";

export const ChoicesView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ChoicesViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Choices");
    }, []);
    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Choices</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}></Box>
            {viewModel.choiceViewModels.map((item, index) => {
                return <ChoiceView viewModel={item} key={item.model.KEY} />;
            })}

            <ThemedButton mt={3} displayName={"Add Choice"} command={viewModel.addChoiceCommand} />
        </Box>
    );
});
