import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../../AppUrls";

export class Response {}

export class DeleteRoleEndpoint extends EndpointWithoutRequest<Response> {
    private roleId: Guid;

    constructor(roleId: Guid) {
        super();
        this.roleId = roleId;
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Account.User.DeleteRole + "/" + roleId);
    }

    public async HandleResponseAsync(response: Response[]): Promise<void> {}
}
