import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { AccountStore } from "Stores/Domain/AccountStores";
import { container } from "tsyringe";
import { GlobalHistory } from "../../index";
import { AutoGrid, Box, Label, setPageTitle } from "Application";
import { DashboardViewModel } from "./DashboardViewModel";
import { PanelView } from "./Panel/PanelView";
import { WelcomePanelView } from "./Panel/WelcomePanelView";

import image2 from "Assets/panel2.png";
import image3 from "Assets/panel3.png";
import image4 from "Assets/panel4.png";
import image6 from "Assets/panel6.png";
import imageM2 from "Assets/panelM2.png";
import imageM3 from "Assets/panelM3.png";
import imageM4 from "Assets/panelM4.png";
import imageM6 from "Assets/panelM6.png";

import house1Desktop from "Assets/1stPanel.png";
import house1Mobile from "Assets/mainImage@2x.png";
import { BuildProgressSVG } from "../../Assets/SVGs/BuildProgressSVG";
import { YourHomeSVG } from "../../Assets/SVGs/YourHomeSVG";
import { SalesProgressSVG } from "../../Assets/SVGs/SalesProgressSVG";
import { DocumentsSVG } from "../../Assets/SVGs/DocumentsSVG";
import { CustomerCareSVG } from "../../Assets/SVGs/CustomerCare.svg";
import flowerLight from "../../Assets/flowerlight.png";

import { AppUrls } from "../../AppUrls";
import styled from "@emotion/styled";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const LabelTitle = styled(Label)`
    font-size: 18px;
    font-weight: bold;
    font: normal normal 300 24px/24px Lato;
    letter-spacing: -0.27px;
    color: #4a4a4a;
    margin: 0 0 22px;
    font-weight: 700;
    @media only screen and (max-width: 767px) {
        font: normal normal bold 18px/23px Lato;
        margin: 0 0 10px;
    }
`;

const LabelSmall = styled(Label)`
    margin: 0 0 2px;
    font: normal normal normal 10px / 14px Lato;
    letter-spacing: 0px;
    color: #4a4a4a;
    text-transform: capitalize;
`;

const LabelSmallAddress = styled(Label)`
    margin: 0 0 2px;
    font: normal normal normal 10px / 14px Lato;
    letter-spacing: 0px;
    color: #4a4a4a;
    text-transform: capitalize;
`;

const LabelBold = styled(Label)`
    margin: 0 0 22px;
    font-weight: 700;
    font: normal normal bold 14px/14px Lato;
    letter-spacing: 0px;
    color: #4a4a4a;
    @media only screen and (max-width: 767px) {
        margin: 0 0 10px;
    }
`;

const LabelBoldAddress = styled(Label)`
    margin: 0;
    font-weight: 700;
    font: normal normal bold 14px/14px Lato;
    letter-spacing: 0px;
    color: #4a4a4a;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
    margin-bottom: 150px;
    & > div {
        padding: 0;
        height: 100%;
    }
`;

const DashboardPage = styled(Box)`
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        left: 0;
        height: 100%;
        width: 555px;
        top: 0;

        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top left;
    }
    &::after {
        content: "";
        right: 0;
        height: 100%;
        width: 555px;
        top: 0;

        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
    }
`;

export const DashboardView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new DashboardViewModel());
    const accountStore = container.resolve(AccountStore);
    let coinsPlotId = accountStore.CoinsPlotId;
    useEffect(() => {
        setPageTitle("Dashboard");
        const isSalesAdmin = () => accountStore.isInRole("admin");
        const isPortal = accountStore.CoinsDevelopmentId && accountStore.CoinsPlotId;
        if (accountStore.IsLoggedIn) {
            if (isPortal) {
                coinsPlotId && viewModel.getDashboard(coinsPlotId as string);
            } else {
                if (!isSalesAdmin) {
                    GlobalHistory.push(AppUrls.Client.Admin.PortalHome);
                } else {
                    GlobalHistory.push(AppUrls.Client.Admin.AdminHome);
                }
            }
        } else {
            GlobalHistory.push(AppUrls.Client.Account.Login);
        }
    }, []);
    let PlotId = parseInt(viewModel.getValue("coinsPlotId"), 10);
    const Panels = () => {
        return (
            <Container id={"rootcontainer"}>
                <AutoGrid dc="1fr 1fr 1fr 1fr 1fr 1fr" style={{ height: "100%", gap: "4px" }} columnGap={"4px"}>
                    <WelcomePanelView
                        icon={<YourHomeSVG showblankcircle={false} />}
                        title={""}
                        description={""}
                        lastUpdatedDate={""}
                        path={AppUrls.Client.Home}
                        delay={0}
                        viewModel={viewModel.panelViewModel}
                        desktopViewModel={viewModel}
                    />
                    <PanelView
                        title={`Home ${viewModel.getValue("coinsPlotId") !== null ? PlotId : ""} 
                     `}
                        subTitle={viewModel.getValue("houseTypeName")}
                        description={viewModel.getValue("developmentName")}
                        lastUpdatedDate={""}
                        path={AppUrls.Client.YourHome}
                        delay={0}
                        icon={<YourHomeSVG showblankcircle={false} />}
                        image={house1Desktop}
                        viewModel={viewModel.panelViewModel}
                        view={"homeView"}
                    />
                    <PanelView
                        title={"Build "}
                        subTitle={"Progress"}
                        description={`STAGE ${viewModel.buildCompletedCount} OF ${viewModel.buildOverallCount}`}
                        lastUpdatedDate={viewModel.getValue("buildProgressModifiedDateUTC")}
                        path={AppUrls.Client.BuildProgress}
                        delay={0}
                        icon={<BuildProgressSVG showblankcircle={false} />}
                        image={image2}
                        viewModel={viewModel.panelViewModel}
                    />
                    <PanelView
                        title={"Sales"}
                        subTitle={"Progress"}
                        description={`STAGE ${viewModel.salesCompletedCount} OF ${viewModel.salesOverallCount}`}
                        lastUpdatedDate={viewModel.getValue("salesProgressModifiedDateUTC")}
                        path={AppUrls.Client.SalesProgress}
                        delay={0}
                        icon={<SalesProgressSVG showblankcircle={false} />}
                        image={image3}
                        viewModel={viewModel.panelViewModel}
                    />
                    <PanelView
                        title={"Document"}
                        subTitle={"Store"}
                        description={`${viewModel.documentCount} DOCS UPLOADED`}
                        lastUpdatedDate={viewModel.getValue("documentStoreModifiedDateUTC")}
                        path={AppUrls.Client.Documents}
                        delay={0}
                        icon={<DocumentsSVG showblankcircle={false} />}
                        image={image4}
                        viewModel={viewModel.panelViewModel}
                    />
                    {/*<PanelView
                    title={"Choices & Extras"}
                    description={""}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.Choices}
                    delay={0.4}
                    icon={<ChoicesSVG showblankcircle={false} />}
                    image={image5}
                    viewModel={viewModel.panelViewModel}
                />*/}
                    <PanelView
                        title={"Customer"}
                        subTitle={"Experience"}
                        description={`${viewModel.reportedSnagsCount} REPORTED ISSUES`}
                        lastUpdatedDate={viewModel.getValue("customerCareModifiedDateUTC")}
                        path={AppUrls.Client.CustomerCare}
                        delay={0}
                        icon={<CustomerCareSVG showblankcircle={false} />}
                        image={image6}
                        viewModel={viewModel.panelViewModel}
                    />
                </AutoGrid>
            </Container>
        );
    };

    const PanelsMobile = () => {
        return (
            <Box flexBox alignItems={"center"} style={{ flexDirection: "column", width: "calc(100% - 15px)", gap: "5px" }}>
                <PanelView
                    title={`Home ${viewModel.getValue("coinsPlotId") !== null ? PlotId : ""} 
                     `}
                    subTitle={viewModel.getValue("houseTypeName")}
                    description={viewModel.getValue("developmentName")}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.YourHome}
                    delay={0}
                    icon={<YourHomeSVG showblankcircle={false} />}
                    image={house1Mobile}
                    viewModel={viewModel.panelViewModel}
                    view={"homeView"}
                />
                <PanelView
                    title={"Build "}
                    subTitle={"Progress"}
                    description={`STAGE ${viewModel.buildCompletedCount} OF ${viewModel.buildOverallCount}`}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.BuildProgress}
                    delay={0}
                    icon={<BuildProgressSVG showblankcircle={false} />}
                    image={imageM2}
                    viewModel={viewModel.panelViewModel}
                />
                <PanelView
                    title={"Sales"}
                    subTitle={"Progress"}
                    description={`STAGE ${viewModel.salesCompletedCount} OF ${viewModel.salesOverallCount}`}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.SalesProgress}
                    delay={0}
                    icon={<SalesProgressSVG showblankcircle={false} />}
                    image={imageM3}
                    viewModel={viewModel.panelViewModel}
                />
                <PanelView
                    title={"Document"}
                    subTitle={"Store"}
                    description={`${viewModel.documentCount} DOCS UPLOADED`}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.Documents}
                    delay={0}
                    icon={<DocumentsSVG showblankcircle={false} />}
                    image={imageM4}
                    viewModel={viewModel.panelViewModel}
                />
                {/*<PanelView
                    title={"Choices & Extras"}
                    description={""}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.Choices}
                    delay={0.4}
                    icon={<ChoicesSVG showblankcircle={false} />}
                    image={image5}
                    viewModel={viewModel.panelViewModel}
                />*/}
                <PanelView
                    title={"Customer"}
                    subTitle={"Experience"}
                    description={`${viewModel.reportedSnagsCount} REPORTED ISSUES`}
                    lastUpdatedDate={""}
                    path={AppUrls.Client.CustomerCare}
                    delay={0}
                    icon={<CustomerCareSVG showblankcircle={false} />}
                    image={imageM6}
                    viewModel={viewModel.panelViewModel}
                />
            </Box>
        );
    };

    return (
        <>
            {!viewModel.isMobile ? (
                <DashboardPage px={"15px"} flexBox justifyContent={"center"}>
                    <Box zIndex={"15"} position={"relative"}>
                        {Panels()}
                    </Box>
                </DashboardPage>
            ) : (
                <>
                    <Box background={"#f7f7f7"} padding={"14px"} borderBottom={"5px solid #f47e44"}>
                        <LabelTitle>Welcome {`${viewModel.getValue("firstName") ? viewModel.getValue("firstName") : ""} `}</LabelTitle>
                        <LabelSmall style={{}}>My new home</LabelSmall>
                        <LabelBold>
                            {`${viewModel.getValue("developmentName") ? viewModel.getValue("developmentName") : ""} Home ${viewModel.getValue("coinsPlotId") ? PlotId : ""} ${
                                viewModel.getValue("houseTypeName") ? viewModel.getValue("houseTypeName") : ""
                            }`}
                        </LabelBold>
                        <LabelSmallAddress>My new address</LabelSmallAddress>
                        <LabelBoldAddress>{`${viewModel.getValue("address1") ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") ? viewModel.getValue("address3") : ""} ${viewModel.getValue("address4") ? viewModel.getValue("address4") : ""} ${
                            viewModel.getValue("postcode") ? decodeURIComponent(escape(viewModel.getValue("postcode"))) : ""
                        }`}</LabelBoldAddress>
                    </Box>
                    <Box grid mc={"1fr"} py={isMobile ? "12px" : "15px"} style={{ marginTop: 0 }} justifyContent={"center"}>
                        {PanelsMobile()}
                    </Box>
                </>
            )}
        </>
    );
});
