import * as MobX from "mobx";

import { CoreStoreInstance, FieldType, sortByString, ViewModelBase } from "@shoothill/core";
import { RoleModel, RoleModelValidator, UserModel } from "../../../Application/Models";
import { action, computed, makeObservable, observable } from "mobx";

import { RoleStore } from "Stores/Domain/Admin";
import { container } from "tsyringe";
import { RelayCommandAsync, ICommandAsync, ICommand, RelayCommand } from "Application/Commands";
import { APIClient, IKeyState } from "../../../Application";
import { DeleteUserEndpoint } from "../Users/Endpoints/DeleteUserEndpoint";
import { SaveUserEndpoint } from "../Users/Endpoints/SaveUserEndpoint";
import { AppUrls } from "../../../AppUrls";
import { SaveRoleEndpoint } from "./Endpoints/SaveRoleEndpoint";

export default class RoleDetailViewModel extends ViewModelBase<RoleModel> {
    public roleStore = container.resolve(RoleStore);
    public apiClient = new APIClient();
    public selectedRows: string[] = [];
    //public Valid: boolean = false;

    constructor() {
        super(new RoleModel(""));
        this.setValidator(new RoleModelValidator());
        makeObservable(this, {
            getRole: action,
        });
    }

    public updateRoleNameCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("name", value);
    });

    public saveRoleCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new SaveRoleEndpoint(), this.model);
        if (this.apiClient.IsRequestSuccessful) {
            CoreStoreInstance.ShowInfoBar("Role Saved Successfully", "success");
            this.history.push(AppUrls.Client.Admin.AdminHome);
        }
    });

    public cancelRoleCommand: ICommand = new RelayCommand(async () => {
        this.history.push(AppUrls.Client.Admin.AdminHome);
    });

    private updateField(fieldName: keyof FieldType<RoleModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public getRole = (id: string) => {
        if (id) {
            const role = this.roleStore.getRoles.find((u: any) => u.id === id) as RoleModel;
            if (role) {
                this.setValue("id", role.id);
                this.setValue("name", role.name);
            }
        } else {
            return new RoleModel("");
        }
    };
}
