import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, NullCommand } from "Application";
import { TestViewModel } from "../Application/Examples/TestViewModel";
import styled from "@emotion/styled";
const StyledBox = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
const StyledCell = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
export const BlankView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    return (
        <>
            <Box flexBox columnGap={"15px"}>
                <Box>one</Box>
                <Box>two</Box>
                <Box>three</Box>
            </Box>
        </>
    );
});
