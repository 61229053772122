import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PanelModel extends ModelBase<PanelModel> {
    public title: string = "";
    public description: string = "";
    public lastUpdated: Date | null = null;

    constructor() {
        super();
        makeObservable(this, {
            title: observable,
            description: observable,
            lastUpdated: observable,
        });
    }
}

export class PanelModelValidator extends Validator<PanelModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
