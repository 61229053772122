import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { ChoiceModel, ChoiceModelValidator } from "./ChoiceModel";
import { Logger } from "index";

export class ChoiceViewModel extends ViewModelBase<ChoiceModel> {
    public apiClient = new APIClient();

    constructor(model = new ChoiceModel()) {
        super(model);
        this.setValidator(new ChoiceModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands
    public updateTitleCommand = new RelayCommand((value: string) => {
        this.setValue("title", value);
    });
    public updateChoicesmadeCommand = new RelayCommand((value: string) => {
        this.setValue("choicesMade", value);
    });

    //#endregion Commands
}
