import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class StagesModel extends ModelBase<StagesModel> {
    public status: string = "";
    public title: string = "";
    public description: string = "";
    public stageDate: null | Date = null;
    public number: number = 0;
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            status: observable,
            title: observable,
            description: observable,
            stageDate: observable,
            number: observable,
            isCompleted: observable,
            isVisible: observable,
            imageUrl: observable,
        });
    }
}

export class StagesModelValidator extends Validator<StagesModel> {
    constructor() {
        super();
    }
}
