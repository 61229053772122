import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AutoGrid, ThemedButton, ThemedEditText, ThemedText, setPageTitle } from "../../../Application";
import { RegisterUserViewModel } from "../SignUp/RegisterUserViewModel";
import { useParams } from "react-router";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { Route, Routes } from "react-router-dom";

interface Props {
    confirmEmail?: boolean;
    loginHomeViewModel: LoginHomeViewModel;
}
/**
 * Login page view.
 */
const LoginView: React.FunctionComponent<Props> = observer((props: Props) => {
    // #region Code Behind
    const [viewModel] = useState(() => new RegisterUserViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        if (viewModel.isLoggedIn) {
            viewModel.history.push("/");
        }
        setPageTitle("Sign Up");
    }, []);

    useEffect(() => {
        if (props.confirmEmail) {
            viewModel.confirmEmailCommand.execute(token);
        }
    }, [token]);

    // #endregion Code Behind
    return (
        <>
            <Routes>
                <Route index element={<RenderForm />} />
                <Route path={"success"} element={<ThemedText fontStyle={"h4"}>Thank you for confirming your email. You can now go and login</ThemedText>} />
                <Route path={"error"} element={<ThemedText fontStyle={"h4"}>There was an error confirming your email.</ThemedText>} />
            </Routes>
            <Link onClick={() => props.loginHomeViewModel.navigateToSignInCommand.execute()}>
                <ThemedText fontStyle={"h7"}>Back to sign in</ThemedText>
            </Link>
        </>
    );
});
export default LoginView;

const RenderForm = observer(() => {
    const [viewModel] = useState(() => new RegisterUserViewModel());
    return (
        <>
            <AutoGrid alignContent={"center"} dc="1fr" rowGap={"15px"} minWidth={"300px"}>
                <ThemedEditText
                    displayName="First name *"
                    command={viewModel.updateFirstNameCommand}
                    value={() => viewModel.getValue("firstName")}
                    validationMessage={() => viewModel.getError("firstName")}
                />
                <ThemedEditText
                    displayName="Last name *"
                    command={viewModel.updateLastNameCommand}
                    value={() => viewModel.getValue("lastName")}
                    validationMessage={() => viewModel.getError("lastName")}
                />
                <ThemedEditText
                    displayName="Email address *"
                    autoFill={false}
                    command={viewModel.updateEmailAddressCommand}
                    value={() => viewModel.getValue("emailAddress")}
                    validationMessage={() => viewModel.getError("emailAddress")}
                />
                <ThemedEditText
                    displayName="Password *"
                    command={viewModel.updatePasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("password")}
                    validationMessage={() => viewModel.getError("password")}
                />
                <ThemedEditText
                    displayName="Confirm Password *"
                    command={viewModel.updateConfirmPasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("confirmPassword")}
                    validationMessage={() => viewModel.getError("confirmPassword")}
                />
                <ThemedButton displayName="Continue" command={viewModel.registerCommand} paletteColor="secondary" type="submit" />
            </AutoGrid>
        </>
    );
});
