import { observer } from "mobx-react-lite";
import React from "react";
import { ILabelStyles, TooltipHost, Label, ITooltipHostStyles, ICalloutContentStyles, Stack } from "@fluentui/react";
import { ExclamationIconSVG } from "Assets/ExclamationSVG";
import { nanoid } from "nanoid";
import { ThemedText } from ".";
import { theme } from "../../AppTheme";
import { getThemeColorOption, pxToRem } from "../../../Application/Helpers";
import { themeColourOptions } from "Styles/IShoothillTheme";

export interface ValidationLabelProps {
    displayName?: string;
    validationMessage?: () => string;
    labelColor?: themeColourOptions;
}

export const ValidationLabelError: React.FC<ValidationLabelProps> = observer((props) => {
    const labelColor = getThemeColorOption(props.labelColor);
    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };

    const toolTipHostStyle: Partial<ITooltipHostStyles> = {
        root: {
            "&&": {
                margin: `auto auto auto ${pxToRem(10)}`,
                paddingTop: pxToRem(4),
            },
        },
    };

    const calloutStyle: Partial<ICalloutContentStyles> = {
        beak: { background: theme.palette.common.error },
        beakCurtain: { background: theme.palette.common.error },
        calloutMain: { background: theme.palette.common.error },
    };
    return (
        <>
            <Stack horizontal>
                <Label styles={labelStyle}>{props.displayName ? props.displayName : "Error"}</Label>
                <TooltipHost
                    id={nanoid()}
                    content={
                        <ThemedText fontStyle={"h7"} color={"white"}>
                            {props.validationMessage?.() as string}
                        </ThemedText>
                    }
                    tooltipProps={{
                        calloutProps: {
                            gapSpace: -5,
                            styles: calloutStyle,
                        },
                    }}
                    styles={toolTipHostStyle}
                >
                    <ExclamationIconSVG backgroundColor={theme.palette.common.error} color={theme.palette.common.white} />
                </TooltipHost>
            </Stack>
        </>
    );
});
