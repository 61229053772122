import React from "react";

const SalesComplete = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105">
            <defs>
                <filter id="Ellipse_209" x="0" y="0" width="105" height="105" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood flood-opacity="0.251" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_8726" data-name="Group 8726" transform="translate(-418.5 -195.5)">
                <g id="Group_8303" data-name="Group 8303" transform="translate(422.887 196.887)">
                    <g id="Group_5581" data-name="Group 5581" transform="translate(0 0)">
                        <g transform="matrix(1, 0, 0, 1, -4.39, -1.39)" filter="url(#Ellipse_209)">
                            <g id="Ellipse_209-2" data-name="Ellipse 209" transform="translate(4.5 1.5)" fill="#fff" stroke="#f47e44" stroke-width="5">
                                <circle cx="48" cy="48" r="48" stroke="none" />
                                <circle cx="48" cy="48" r="45.5" fill="none" />
                            </g>
                        </g>
                    </g>
                    <g id="noun_Key_2288" transform="translate(22.58 23.665)">
                        <path
                            id="Path_3682"
                            data-name="Path 3682"
                            d="M31.449,27.352A9.237,9.237,0,0,0,41.535,29.43l.774.524,1.455-.2-1.351,1.782L44.076,33.2l3.079-.21-.591,2.34,1.43,1.482,2.528-.111-.467,1.954,1.813,1.818,1.971-.84,1.058.847-.516,1.169.124.09,6.178.367.471-2.235L46.088,24.564a8.694,8.694,0,0,0-2.042-9.783,9.059,9.059,0,0,0-12.8-.226A9.038,9.038,0,0,0,31.449,27.352Zm1.593-7.123a2.836,2.836,0,1,1,4.077.077A2.857,2.857,0,0,1,33.042,20.229Z"
                            transform="translate(-13.12 -5.512)"
                            fill="#f47e44"
                            fill-rule="evenodd"
                        />
                        <g id="Group_5553" data-name="Group 5553" transform="translate(11.61 12.668)">
                            <path
                                id="Path_3683"
                                data-name="Path 3683"
                                d="M25.559,35.643a11.508,11.508,0,0,1-3.408-7.975A11.166,11.166,0,0,1,22.49,24.9a9.141,9.141,0,0,0-1.629,8.628,9.237,9.237,0,0,0,8.012,6.468l.444.817L30.7,41.3l-2.014.968.712,2.24,2.833,1.225-1.6,1.811.593,1.969,2.3,1.06L32.215,52.1,33,54.544l2.141.157.551,1.239-1,.8.067.134,5.322,3.161,1.445-1.767L35.514,38.922a11.13,11.13,0,0,1-1.76.145A11.518,11.518,0,0,1,25.559,35.643Z"
                                transform="translate(-20.415 -24.899)"
                                fill="#f47e44"
                            />
                        </g>
                        <g id="Group_5554" data-name="Group 5554" transform="translate(3.533 1.448)">
                            <path
                                id="Path_3684"
                                data-name="Path 3684"
                                d="M13.281,1.448A9.693,9.693,0,0,0,9.892,20.242c-.039-.3-.079-.6-.107-.919-.03-.353-.026-.69-.025-1.028a7.963,7.963,0,1,1,11.514-7.1,7.546,7.546,0,0,1-.171,1.544.934.934,0,0,0,.812.785.82.82,0,0,0,.933-.511,9.511,9.511,0,0,0,.181-1.818A9.758,9.758,0,0,0,13.281,1.448Z"
                                transform="translate(-3.533 -1.448)"
                                fill="#f47e44"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SalesComplete;
