import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AutoGrid } from "../../Application";
import { TodoViewModel } from "./TodoViewModel";
import { TodoItem } from "./TodoItem";
import { nanoid } from "nanoid";
import { TextBase } from "../../Components/StyleFrameworks/FluentUI/Primitives/Active/TextBase";

export const Todo: React.FC = observer(() => {
    const [viewModel] = useState(() => new TodoViewModel());
    return (
        <>
            <TextBase fontStyle={"h3"}>Todos</TextBase>
            <AutoGrid id={"todogrid"} pt={2} dc={"repeat(4, 1fr)"} rowGap={"1em"}>
                {viewModel.todoItemsViewModel.map((todoItemViewmodel: any, index: number) => {
                    return <TodoItem key={nanoid()} viewModel={todoItemViewmodel} />;
                })}
            </AutoGrid>
        </>
    );
});
