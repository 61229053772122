import { isNullOrUndefined } from "@shoothill/core";
import { ILabelStyles, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { getThemeColorOption } from "Application/Helpers/StyleHelpers";
import { theme } from "../../AppTheme";
import { Show } from "Components";
import { ICheckBoxBaseProps, CheckBoxBase } from "../../../Components";
import { getThemePaletteOption } from "../../../Application/Helpers";
import { ValidationLabelError } from "./ValidationLabelError";
import { TickIcon } from "Assets/Icons/TickIconSVG";

export const ThemedCheckBox = observer((props: PropsWithChildren<ICheckBoxBaseProps>) => {
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const isChecked = !isNullOrUndefined(props.value) ? props.value() : false;
    const labelColor = getThemeColorOption(props.labelColor);

    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };
    return (
        <CheckBoxBase
            {...props}
            onRenderLabel={(isInError) => {
                if (isInError) {
                    return <ValidationLabelError labelColor="secondary" displayName={props.displayName} validationMessage={props.validationMessage} />;
                }
                return (
                    <Stack horizontal>
                        <Label styles={labelStyle}>{props.displayName}</Label>
                        <Show if={props.validationMessage?.() !== undefined && props.validationMessage() === ""}>
                            <TickIcon />
                        </Show>
                    </Stack>
                );
            }}
            styles={mergeStyleSets(
                {
                    checkmark: {},
                    root: {
                        ".ms-Checkbox": {},
                        ".ms-Checkbox-checkbox": {
                            backgroundColor: "white",
                            borderColor: theme.palette.field.light,
                        },
                        ".ms-Checkbox-checkmark": {
                            color: paletteColor.light,
                        },
                        ":hover": {
                            ".ms-Checkbox-checkbox": {
                                backgroundColor: "white",
                                borderColor: theme.palette.field.main,
                            },
                            ".ms-Checkbox-checkmark": {
                                color: isChecked ? paletteColor.main : theme.palette.field.light,
                            },
                        },
                        ":focus": {
                            ".ms-Checkbox-checkbox": {
                                borderColor: theme.palette.common.focus,
                            },
                        },
                    },
                },
                {
                    root: {},
                    label: {
                        alignItems: "center",
                        // color: theme.palette.field.text,
                        // fontFamily: theme.fontStyles.checkboxLabel.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        // fontSize: theme.fontStyles.checkboxLabel.fontSize ?? theme.defaultFontStyle.fontSize,
                        // fontWeight: theme.fontStyles.checkboxLabel.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        // letterSpacing: theme.fontStyles.checkboxLabel.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        // lineHeight: theme.fontStyles.checkboxLabel.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        // textTransform: theme.fontStyles.checkboxLabel.textTransform ?? theme.defaultFontStyle.textTransform,
                    },

                    // label: {
                    //     alignItems: "center",
                    // },
                    checkbox: {
                        height: theme.sizes.large,
                        width: theme.sizes.large,
                    },
                    checkmark: {
                        fontSize: theme.sizes.medium,
                        fontWeight: "bold",
                    },
                    // text: {
                    //     color: theme.palette.field.text,
                    //     fontFamily: theme.fontStyles.checkboxLabel.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    //     fontSize: theme.fontStyles.checkboxLabel.fontSize ?? theme.defaultFontStyle.fontSize,
                    //     fontWeight: theme.fontStyles.checkboxLabel.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    //     letterSpacing: theme.fontStyles.checkboxLabel.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    //     lineHeight: theme.fontStyles.checkboxLabel.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    //     textTransform: theme.fontStyles.checkboxLabel.textTransform ?? theme.defaultFontStyle.textTransform,
                    // },
                    subComponentStyles: {
                        label: labelStyle,
                    },
                },
                props.styles,
            )}
            errorStyles={{
                root: {
                    display: "none",
                    color: theme.palette.common.error,
                    fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                    fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    marginTop: "5px !important",
                    paddingTop: 0,
                },
            }}
        />
    );
});

// #region Directly-styled check box

export const ThemedDefaultCheckBox = observer((props: PropsWithChildren<ICheckBoxBaseProps>) => {
    const isChecked = !isNullOrUndefined(props.value) ? props.value() : false;
    return (
        <CheckBoxBase
            {...props}
            styles={mergeStyleSets(
                {
                    checkmark: {},
                    root: {
                        ".ms-Checkbox-checkbox": {
                            backgroundColor: "white",
                            borderColor: theme.palette.field.light,
                        },
                        ".ms-Checkbox-checkmark": {
                            color: theme.palette.secondary.light,
                        },
                        ":hover": {
                            ".ms-Checkbox-checkbox": {
                                backgroundColor: "white",
                                borderColor: theme.palette.field.main,
                            },
                            ".ms-Checkbox-checkmark": {
                                color: isChecked ? theme.palette.secondary.main : theme.palette.field.light,
                            },
                        },
                        ":focus": {
                            ".ms-Checkbox-checkbox": {
                                borderColor: theme.palette.common.focus,
                            },
                        },
                    },
                },
                {
                    root: {},

                    // subComponentStyles: {
                    //     label: {
                    //         alignItems: "center",
                    //         color: theme.palette.field.text,
                    //         fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    //         fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                    //         fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    //         letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    //         lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    //         textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    //     },
                    // },

                    label: {
                        alignItems: "center",
                    },

                    checkbox: {
                        height: theme.sizes.large,
                        width: theme.sizes.large,
                    },
                    checkmark: {
                        fontSize: theme.sizes.medium,
                        fontWeight: "bold",
                    },
                    // text: {
                    //     color: theme.palette.field.text,
                    //     fontFamily: theme.fontStyles.checkboxLabel.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    //     fontSize: theme.fontStyles.checkboxLabel.fontSize ?? theme.defaultFontStyle.fontSize,
                    //     fontWeight: theme.fontStyles.checkboxLabel.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    //     letterSpacing: theme.fontStyles.checkboxLabel.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    //     lineHeight: theme.fontStyles.checkboxLabel.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    //     textTransform: theme.fontStyles.checkboxLabel.textTransform ?? theme.defaultFontStyle.textTransform,
                    // },
                },
                props.styles,
            )}
            errorStyles={{
                root: {
                    color: theme.palette.common.error,
                    fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                    fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    marginTop: "5px !important",
                    paddingTop: 0,
                },
            }}
        />
    );
});

// #endregion Directly-styled check box
