import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { Moment } from "moment";
import { RoleModel } from "../RoleModel";
import { AccountStore, RoleStore } from "../../../../Stores/Domain";
import { container } from "tsyringe";

class Request {
    public id: Guid = null;
    public name: string = "";
}

class Response {
    public id: Guid = null;
    public name: string = "";
}

export class SaveRoleEndpoint extends Endpoint<Request, Response> {
    private roleStore = container.resolve(RoleStore);

    constructor() {
        super();
        this.verb(Http.Put);
        this.path(`/api/role/upsert`);
    }

    HandleRequestAsync(model: RoleModel): Promise<Request> {
        let request: Request = new Request();
        request.id = model.id;
        request.name = model.name;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<RoleModel> {
        //Return your model
        let roleModel = new RoleModel(response.id);
        roleModel.name = response.name;
        this.roleStore.addNewRole(roleModel);
        return Promise.resolve(roleModel);
    }
}
