import { DatePicker, defaultDatePickerStrings, IDatePickerStrings, IDatePickerStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";
import { Box } from "../../../../General/Box";
import { reaction } from "mobx";
import { config } from "../../../../../config";
import Moment from "moment";
import { Logger } from "../../../../../index";

/**
 * Edit Date interface.
 */
export interface IEditDateBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => Date | undefined;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    styles?: IDatePickerStyles;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    maxDate?: Date;
    initialDate?: Date;
}

export const EditDateBase: React.FC<IEditDateBaseProps> = observer((props) => {
    // #region DatePicker Code Behind

    const [internalDate, setInternalDate] = React.useState<Date | undefined>();

    useEffect(() => {
        reaction(
            () => props.value(),
            (value) => {
                setInternalDate(value);
            },
        );
        setInternalDate(props.value());
    }, [props.value]);

    const getDateFormat = (date?: Date): string => {
        try {
            const format = new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            Logger.logDebug(`getDateFormat: ${date}`);
            return format.format(date);
        } catch {}
        return "";
    };

    const onParseDateFromString = (newValue: string): Date => {
        const previousValue = internalDate || new Date();
        const newValueParts = (newValue || "").trim().split("/");

        const day = newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
        const month = newValueParts.length > 1 ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1 : previousValue.getMonth();
        let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();

        if (year < 100) {
            year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
        }

        return new Date(year, month, day);
    };

    const onSelectDate = (date: Date | null | undefined): void => {
        setInternalDate(isNullOrUndefined(date) ? undefined : date!);
        props.command.execute(isNullOrUndefined(date) ? undefined : date);
    };

    // #endregion DatePicker Code Behind

    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getValue = (): Date | undefined => {
        return props.value?.();
    };

    // The string (set to empty) are local validation strings. They have been set to empty so
    // they do not conflict with external validation done in the model.
    const strings: IDatePickerStrings = {
        ...defaultDatePickerStrings,
        invalidInputErrorMessage: "",
        isOutOfBoundsErrorMessage: "",
        isRequiredErrorMessage: "",
        isResetStatusMessage: "",
    };

    // #endregion Code Behind

    return (
        <DatePicker
            allowTextInput={true}
            className={getClasseNames()}
            disableAutoFocus={true}
            formatDate={getDateFormat}
            label={props.displayName}
            onSelectDate={onSelectDate}
            parseDateFromString={onParseDateFromString}
            placeholder={props.placeholder}
            strings={strings}
            styles={props.styles}
            initialPickerDate={props.initialDate}
            maxDate={props.maxDate}
            textField={{
                autoComplete: "off",
                errorMessage: getValidationMessage(),
            }}
            value={internalDate}
        />
    );
});
