import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle } from "Application";
import { PanelViewModel } from "./PanelViewModel";
import styled from "@emotion/styled";
import { Label } from "../../../Components/Primitives/Labels";
import { HorizontalBlock } from "../../../Components/HorizontalBlock";

interface PanelProps {
    image: string;
}
const Panel = styled(Box)<PanelProps>`
    background-color: #4a4a4a;
    background-repeat: no-repeat;
    border-radius: 2px;
    cursor: pointer;
`;

interface Props {
    image: string;
    icon: JSX.Element;
    viewModel: PanelViewModel;
    delay: number;
    path: string;
    description: string;
    title: any;
    subTitle?: string;
    lastUpdatedDate: string;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
}
export const PanelView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const [isHover, setIsHover] = useState(false);
    useEffect(() => {
        setPageTitle("Panel");
    }, []);

    const PanelForDesktop = () => {
        return (
            <Panel
                style={props.style}
                onClick={() => viewModel.navigateCommand.execute(props.path)}
                image={props.image}
                transition={{ type: "tween", duration: 0.1, delay: props.delay }}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
            >
                <img src={props.image} className="img-fluid" alt="image" style={{ width: "100%", height: "500px", objectFit: "cover" }} />
                <Box flexBox justifyContent={"center"} mt={"-30px"}>
                    {props.icon}
                </Box>
                <Box padding={"0px 25px 25px"} grid dc="1fr" rowGap={"5px"}>
                    <Box textAlign={"center"}>
                        <Label
                            style={{
                                fontSize: "24px",
                                lineHeight: "26px",
                                fontWeight: "bold",
                                letterSpacing: "0px",
                                color: "#FFFFFF",
                                textTransform: "uppercase",
                                marginTop: "12px",
                            }}
                            fontStyle={"h4"}
                        >
                            {props.title}
                        </Label>
                        <Label
                            fontStyle={"h5"}
                            style={{
                                letterSpacing: "0px",
                                marginTop: "1px",
                                color: "#FFFFFF",
                                textTransform: "uppercase",
                                fontSize: "12px",
                                lineHeight: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            {props.subTitle}
                        </Label>
                        <Label
                            fontStyle={"h5"}
                            style={{
                                letterSpacing: "0px",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#FFFFFF",
                                textTransform: "capitalize",
                                margin: "18px 0 0",
                                font: "normal normal normal 12px/14px Lato",
                            }}
                        >
                            {props.description}
                        </Label>
                    </Box>
                </Box>
            </Panel>
        );
    };

    const PanelForMobile = () => {
        return (
            <Box grid dc={"1fr"} style={{ width: "100%" }}>
                <HorizontalBlock
                    title={props.title}
                    subTitle={props.subTitle}
                    description={props.description}
                    lastUpdated={props.lastUpdatedDate}
                    image={props.image}
                    icon={props.icon}
                    path={props.path}
                    view={props.view}
                />
            </Box>
        );
    };

    return <>{viewModel.isMobile ? PanelForMobile() : PanelForDesktop()}</>;
});
