/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { ThemedText } from "../../Styles";

export const SessionExpiredView: React.FC = () => {
    return (
        <ThemedText fontStyle={"h2"} color={"primary"}>
            Your session has expired!
        </ThemedText>
    );
};
