import * as React from "react";
import { SVGProps } from "react";

export const LogoutSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_5613" data-name="Group 5613" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_1540" data-name="Rectangle 1540" width="20.833" height="20.836" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Ellipse_209" data-name="Ellipse 209" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
            <circle cx="25" cy="25" r="25" stroke="none" />
            <circle cx="25" cy="25" r="23.75" fill="none" />
        </g>
        <g id="Group_8739" data-name="Group 8739" transform="translate(17 14.301)">
            <g id="Group_5516" data-name="Group 5516" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path
                    id="Path_3677"
                    data-name="Path 3677"
                    d="M.581,0A.651.651,0,0,0,0,.649V20.183a.651.651,0,0,0,.647.655h11.4a.651.651,0,0,0,.647-.655V15.3a.651.651,0,1,0-1.3,0v4.235H1.3V1.3H11.391v4.23a.651.651,0,1,0,1.3,0V.649A.648.648,0,0,0,12.046,0H.647A.3.3,0,0,0,.581,0M15.289,5.928a.653.653,0,0,0-.63.65V8.465H5.074a.651.651,0,0,0-.652.65v2.6a.65.65,0,0,0,.652.65h9.585v1.89a.652.652,0,0,0,1.053.509l4.872-3.836a.645.645,0,0,0,0-1.024L15.712,6.068a.646.646,0,0,0-.423-.14"
                    transform="translate(0 0)"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
);
