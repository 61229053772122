import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { DocumentsModel, DocumentsModelValidator } from "./DocumentsModel";
import { Logger } from "index";

export class DocumentsViewModel extends ViewModelBase<DocumentsModel> {
    public apiClient = new APIClient();

    constructor(model = new DocumentsModel()) {
        super(model);
        this.setValidator(new DocumentsModelValidator());
        makeObservable(this, {});
    }

    //#region Update Commands

    //#endregion Commands
}
