import { makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { PurchaseStagesModel, PurchaseStagesModelValidator } from "./PurchaseStagesModel";

export class PurchaseStagesViewModel extends ViewModelBase<PurchaseStagesModel> {
    public apiClient = new APIClient();
    public isEdit: boolean = false;
    public timeOut: any = null;
    public description = this.model.description;
    public title = this.model.title;
    public imageUrl = this.model.imageUrl;
    constructor(model = new PurchaseStagesModel()) {
        super(model);
        this.setValidator(new PurchaseStagesModelValidator());
        makeObservable(this, { description: observable, isEdit: observable, title: observable, imageUrl: observable });
    }

    public updateStageCodeCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("stageCode", value);
    });

    public updateTermsAndConditionText = (value: string): void => {
        this.updateField("description", value);
    };

    public updateDescriptionCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("description", value);
    });

    public updateIsVisibleCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("isVisible", value);
    });

    public updateField(fieldName: keyof FieldType<PurchaseStagesModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
        this.isEdit = true;
    }
}
