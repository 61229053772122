import React, { useCallback, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { observer } from "mobx-react-lite";
import { FileUploaderViewModel } from "./FileUploaderViewModel";
import styled from "@emotion/styled";
import { Modal } from "@fluentui/react";
import { ImageCropperView } from "Components/ImageCropper/ImageCroperView";
import { BuildStagesViewModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesViewModel";
import { PurchaseStagesViewModel } from "Views/Admin/YourPurchase/PurchaseStages/PurchaseStagesViewModel";

interface IProps {
    //summary: defines the file types allowed to be uploaded
    allowedFileTypes?: Accept;
    //summary: determines if multiple files can be uploaded at once from the device
    multiple?: boolean;
    //summary: determines if the uploading functionality is disabled
    disabled?: boolean;
    //summary: determines if the component can allow dragging and dropping files
    allowDraggingFiles?: boolean;
    //summary: determines if the component can display a container for dragging and dropping files
    showDropZoneContainer?: boolean;
    //summary: determines if the component can display a button for uploading files
    hasUploadButton?: boolean;
    //summary: defines the maximum number of files that can be uploaded at once
    maxFiles?: number;
    //summary: defines the placeholder text used inside the drop zone
    placeholderText?: string;
    //summary: determines if the placeholder text used inside the drop zone is displayed
    showPlaceholderText?: boolean;
    //summary: defines the button used for uploading files
    uploadButtonStyles?: React.CSSProperties;
    //summary: defines the button used for uploading files
    uploadButtonText?: string;
    //summary: defines id
    id?: string;
    //summary: defines viewModel
    viewModel?: BuildStagesViewModel | PurchaseStagesViewModel;
    isBuildStageAdmin: boolean;
}

interface IDropZoneContainerProp {
    showDropZoneContainer: boolean;
}

const DropZoneContainer = styled.div<IDropZoneContainerProp>`
    border: ${(props) => (props.showDropZoneContainer ? "2px dashed #eaeaea" : "")};
    background-color: ${(props) => (props.showDropZoneContainer ? "#f9f9f9" : "none")};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${(props) => (props.showDropZoneContainer ? "10px" : "")};
`;

export const FileUploaderView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(() => new FileUploaderViewModel());
    //region constants

    const DEFAULT_ALLOWED_FILE_TYPES: Accept = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
        "image/eps": [".eps"],

        //extend default values
    };

    //endregion constants

    //region properties

    const allowedFileTypes: Accept = props.allowedFileTypes !== undefined ? props.allowedFileTypes : DEFAULT_ALLOWED_FILE_TYPES;
    const multiple = props.multiple !== undefined ? props.multiple : true;
    const disabled = props.disabled !== undefined ? props.disabled : true;
    const maxFiles = props.maxFiles !== undefined ? props.maxFiles : 1;
    const allowDraggingFiles = props.allowDraggingFiles !== undefined ? props.allowDraggingFiles : true;
    const hasUploadButton = props.hasUploadButton !== undefined ? props.hasUploadButton : true;
    const showPlaceholderText = props.showPlaceholderText !== undefined ? props.showPlaceholderText : true;
    const uploadButtonStyles = props.uploadButtonStyles !== undefined ? props.uploadButtonStyles : {};
    const showDropZoneContainer = props.showDropZoneContainer !== undefined ? props.showDropZoneContainer : true;
    const uploadButtonText = props.uploadButtonText !== undefined ? props.uploadButtonText : "Open file dialog";

    const placeholderText = (): string => {
        if (props.placeholderText) {
            return props.placeholderText;
        } else {
            if (allowDraggingFiles && hasUploadButton) {
                return "Drag 'n' drop some files here, or click to select files";
            } else if (allowDraggingFiles && !hasUploadButton) {
                return "Drag 'n' drop some files here to select files";
            } else {
                return "Click to select files";
            }
        }
    };

    const allowedFilesPlaceholderText = (): string => {
        let allowedFileTypesSuffixes: string = "";

        const formatFileTypeSuffix = (val: string, prefix?: string) => {
            const retVal = "*".concat(val);

            if (prefix) {
                return prefix.concat(retVal);
            }

            return retVal;
        };

        const allowedFileTypesArr: string[][] = Object.values(allowedFileTypes);

        allowedFileTypesArr.forEach((val: string[], index) => {
            if (index === 0) {
                allowedFileTypesSuffixes = allowedFileTypesSuffixes.concat(formatFileTypeSuffix(val[0]));
            } else {
                if (index === allowedFileTypesArr.length - 1) {
                    allowedFileTypesSuffixes = allowedFileTypesSuffixes.concat(formatFileTypeSuffix(val[0], " and "));
                } else {
                    allowedFileTypesSuffixes = allowedFileTypesSuffixes.concat(formatFileTypeSuffix(val[0], ", "));
                }
            }
        });

        return `Only ${allowedFileTypesSuffixes} images will be accepted`;
    };

    const validateFile = (file: File): boolean => {
        const maxSize = 20 * 1024 * 1024;
        if (file.size > maxSize) {
            // console.log("File size exceeds the limit.");
            return false;
        }
        return true;
    };

    const onDrop = useCallback((acceptedFiles: any[]) => {
        acceptedFiles.forEach((file) => {
            if (validateFile(file)) {
                viewModel.model.fileName = file.name;
                const reader = new FileReader();
                reader.onload = () => {
                    const imageUrl = reader.result as string;
                    viewModel.uploadedImage = imageUrl;
                    viewModel.saveImageCommand.execute(file);
                    viewModel.imageCropperPopup = true;
                };
                reader.readAsDataURL(file);
            } else {
                viewModel.imageCropperPopup = false;
            }
        });
    }, []);

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone({
        accept: allowedFileTypes,
        maxFiles: maxFiles,
        noDrag: !allowDraggingFiles || !showDropZoneContainer,
        noClick: hasUploadButton,
        noKeyboard: hasUploadButton,
        onDrop,
        multiple: false,
    });

    //endregion functions

    const renderImageCropperContent = () => {
        return (
            <Modal isOpen={viewModel.canImageCropper} onDismiss={() => viewModel.closeImageCropperPopupCommand.execute()}>
                <ImageCropperView
                    model={props.viewModel!}
                    imageFile={viewModel.uploadedImage}
                    id={props.id!}
                    closeModel={viewModel.closeImageCropperPopupCommand}
                    fileName={viewModel.model.fileName}
                    isBuildStageAdmin={props.isBuildStageAdmin}
                />
            </Modal>
        );
    };

    return (
        <>
            <section>
                <DropZoneContainer {...getRootProps({ showDropZoneContainer: showDropZoneContainer })}>
                    <input {...getInputProps()} multiple={multiple} disabled={disabled} />
                    {hasUploadButton && (
                        <button type="button" onClick={open} style={uploadButtonStyles}>
                            {uploadButtonText}
                        </button>
                    )}
                    {showPlaceholderText && <p>{placeholderText()}</p>}
                    {showPlaceholderText && <em>({allowedFilesPlaceholderText()})</em>}
                </DropZoneContainer>
            </section>
            {viewModel.canImageCropper && renderImageCropperContent()}
        </>
    );
});
