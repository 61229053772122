import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedText, ThemedEditText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { ChoiceViewModel } from "./ChoiceViewModel";

interface Props {
    viewModel: ChoiceViewModel;
}
export const ChoiceView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const lookupStore = container.resolve(LookupStore);
    useEffect(() => {
        setPageTitle("Choice");
    }, []);

    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Choice</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}>
                <ThemedText>{viewModel.getValue("title")}</ThemedText>
                <ThemedText>{viewModel.getValue("choicesMade")}</ThemedText>
            </Box>
        </Box>
    );
});
