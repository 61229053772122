import { action, makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { ChoicesModel, ChoicesModelValidator } from "./ChoicesModel";
import { Logger } from "index";
import { ChoiceModel } from "./Choice/ChoiceModel";
import { ChoiceViewModel } from "./Choice/ChoiceViewModel";
import sittingRoom from "Assets/Choices/SittingRoom.png";
import reception from "Assets/Choices/Reception.png";
import downstairs from "Assets/Choices/DownstairsHall.png";
import wcdownstairs from "Assets/Choices/WCDownstairs.png";
import diningArea from "Assets/Choices/DiningArea.png";
import kitchen from "Assets/Choices/Kitchen.png";
import mainBedroom from "Assets/Choices/MainBedroom.png";
import mainBedroomEnsuite from "Assets/Choices/MainBedroomEnSuite.png";
import bedroom2 from "Assets/Choices/Bedroom2.png";
import bedroom2Ensuite from "Assets/Choices/Bedroom2EnSuite.png";
import bedroom3 from "Assets/Choices/Bedroom3.png";
import bedroom4 from "Assets/Choices/Bedroom4.png";
import bathroom from "Assets/Choices/Bathroom.png";
import garage from "Assets/Choices/Garage.png";

export class ChoicesViewModel extends ViewModelBase<ChoicesModel> {
    public apiClient = new APIClient();
    public choicesAndExtrasActive: boolean = true;
    public selectedChoiceExtrasActive: boolean = false;
    public choiceViewModels = observable<ChoiceViewModel>([]);

    constructor(model = new ChoicesModel()) {
        super(model);
        this.setValidator(new ChoicesModelValidator());
        makeObservable(this, {
            choicesAndExtrasActive: observable,
            selectedChoiceExtrasActive: observable,
            setChoicesAndExtrasActive: action,
            setSelectedChoiceExtrasActive: action,
        });
        console.log(reception);
        this.addFakeData("Sitting Room", 0, 2, sittingRoom, "sittingRoom");
        this.addFakeData("Reception", 0, 2, reception, "reception");
        this.addFakeData("Downstairs Hall", 0, 2, downstairs, "downstairsHall");
        this.addFakeData("WC Downstairs", 0, 2, wcdownstairs, "wcDownstairs");
        this.addFakeData("Dining Area", 0, 2, diningArea, "diningArea");
        this.addFakeData("Kitchen", 0, 2, kitchen, "kitchen");
        this.addFakeData("Main Bedroom", 0, 2, mainBedroom, "mainBedroom");
        this.addFakeData("Main Bedroom En-Suite", 0, 2, mainBedroomEnsuite, "mainBedroomEnSuite");
        this.addFakeData("Main Bedroom 2", 0, 2, bedroom2, "bedroom2");
        this.addFakeData("Main Bedroom 2 En-Suite", 0, 2, bedroom2Ensuite, "bedroom2EnSuite");
        this.addFakeData("Main Bedroom 3", 0, 2, bedroom3, "bedroom3");
        this.addFakeData("Main Bedroom 4", 0, 2, bedroom4, "bedroom4");
        this.addFakeData("Bathroom", 0, 2, bathroom, "bathroom");
        this.addFakeData("Garage", 0, 2, garage, "garage");
    }

    public addFakeData(title: string, numberChoicesMade: number, numberChoicesAvailable: number, asset: any, path: string) {
        const choiceModel = new ChoiceModel();
        choiceModel.title = title;
        choiceModel.numberChoicesMade = numberChoicesMade;
        choiceModel.numberChoicesAvailable = numberChoicesAvailable;
        choiceModel.asset = asset;
        choiceModel.path = path;
        this.model.choiceModels.push(choiceModel);
    }

    public dispose = (): void => {
        this.choiceModelsObserverDispose();
    };

    private choiceModelsObserverDispose = observe(this.model.choiceModels, (childModelChanges: any) => {
        Logger.logDebug(`ChoiceViewModel.childModelsObserverDispose`);
        for (const addedChildModel of childModelChanges.added) {
            this.choiceViewModels.push(new ChoiceViewModel(addedChildModel));
        }

        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.choiceViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);

            if (childViewModelToRemove) {
                this.choiceViewModels.remove(childViewModelToRemove);
            }
        }
    });

    public setChoicesAndExtrasActive = (active: boolean): void => {
        this.choicesAndExtrasActive = active;
        this.selectedChoiceExtrasActive = !active;
    };
    public setSelectedChoiceExtrasActive = (active: boolean): void => {
        this.selectedChoiceExtrasActive = active;
        this.choicesAndExtrasActive = !active;
    };

    //#region Update Commands
    public addChoiceCommand = new RelayCommand(() => {
        this.model.choiceModels.push(new ChoiceModel());
    });
    //#endregion Commands
}
