import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle?: boolean;
    colour?: string;
}
export const SalesProgressSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_8707" data-name="Group 8707" transform="translate(-416 -571)">
                <g id="Group_8264" data-name="Group 8264">
                    <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(416 571)" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="23.75" fill="none" />
                    </g>
                    <g id="noun_Key_2288" transform="translate(425.467 581.552)">
                        <path
                            id="Path_3682"
                            data-name="Path 3682"
                            d="M30.24,20.633A5.189,5.189,0,0,0,35.906,21.8l.435.294.817-.113-.759,1,.935.936,1.73-.118-.332,1.315.8.833,1.42-.063-.263,1.1L41.711,28l1.107-.472.594.476-.29.657.07.051,3.471.206.265-1.256-8.464-8.6a4.884,4.884,0,0,0-1.147-5.5,5,5,0,1,0-7.077,7.063Zm.895-4a1.593,1.593,0,1,1,2.291.043A1.6,1.6,0,0,1,31.135,16.631Z"
                            transform="translate(-18.395 -7.728)"
                            fill="#fefefe"
                            fill-rule="evenodd"
                        />
                        <g id="Group_5553" data-name="Group 5553" transform="translate(8.071 7.751)">
                            <path
                                id="Path_3683"
                                data-name="Path 3683"
                                d="M23.305,30.935a6.465,6.465,0,0,1-1.915-4.481,6.273,6.273,0,0,1,.19-1.555,5.135,5.135,0,0,0-.915,4.847,5.189,5.189,0,0,0,4.5,3.634l.249.459.778.275-1.131.544.4,1.258,1.592.688-.9,1.018.333,1.106,1.29.6-.738.855.439,1.375,1.2.088.309.7-.559.449.038.075,2.99,1.776.812-.993L28.9,32.777a6.253,6.253,0,0,1-.989.081A6.471,6.471,0,0,1,23.305,30.935Z"
                                transform="translate(-20.415 -24.899)"
                                fill="#fefefe"
                            />
                        </g>
                        <g id="Group_5554" data-name="Group 5554" transform="translate(3.533 1.448)">
                            <path
                                id="Path_3684"
                                data-name="Path 3684"
                                d="M9.009,1.448a5.445,5.445,0,0,0-1.9,10.558c-.022-.17-.044-.339-.06-.516-.017-.2-.015-.388-.014-.578A4.474,4.474,0,1,1,13.5,6.925a4.24,4.24,0,0,1-.1.868.525.525,0,0,0,.456.441.46.46,0,0,0,.524-.287,5.343,5.343,0,0,0,.1-1.021A5.482,5.482,0,0,0,9.009,1.448Z"
                                transform="translate(-3.533 -1.448)"
                                fill="#fefefe"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
