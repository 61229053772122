import styled from "@emotion/styled";

interface Props {
    padding?: string;
}
export const DashedLine = styled.hr<Props>`
    border: none;
    border-top: 2px dashed #4a4a4a;
    color: #4a4a4a;
    background-color: transparent;
    height: 1px;
    width: 100%;
    margin-top: ${(p) => (p.padding ? p.padding : "30px")};
    margin-bottom: ${(p) => (p.padding ? p.padding : "30px")};
`;
