import { singleton } from "tsyringe";
import { action, makeObservable, observable } from "mobx";

@singleton()
export class DomainStore {
    public isSideBarClosed: boolean = false;

    constructor() {
        makeObservable(this, {
            isSideBarClosed: observable,
            setSideBarClosed: action,
        });
    }

    public setSideBarClosed = (isOpen: boolean) => {
        this.isSideBarClosed = isOpen;
    };
}
