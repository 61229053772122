import { makeObservable, observable, observe } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { BuildStageAdminModel, BuildStageAdminModelValidator } from "./BuildStageAdminModel";
import { GETBuildStageAdminEndpoint } from "./Endpoints/GETBuildProgressEndpoint";
import { BuildStagesViewModel } from "./BuildStage/BuildStagesViewModel";
import { POSTBuildStageAdminEndpoint } from "./Endpoints/POSTBuildStageAdminEndpoint";

export class BuildStageAdminViewModel extends ViewModelBase<BuildStageAdminModel> {
    public apiClient = new APIClient();
    public isCancel = false;
    public isSave = false;
    public buildViewModels = observable<BuildStagesViewModel>([]);

    constructor(model = new BuildStageAdminModel()) {
        super(model);
        this.setValidator(new BuildStageAdminModelValidator());
        makeObservable(this, { isCancel: observable, isSave: observable, buildViewModels: observable });
        this.apiClient.sendAsync(new GETBuildStageAdminEndpoint(this), this.model);
    }

    public reSetCommand: ICommand = new RelayCommand(() => {
        this.isCancel = true;
        this.apiClient.sendAsync(new GETBuildStageAdminEndpoint(this), this.model);
    });

    public updateCommand: ICommand = new RelayCommand(() => {
        this.isSave = true;
        this.apiClient.sendAsync(new POSTBuildStageAdminEndpoint(this), this.model);
    });

    public dispose = (): void => {
        this.stagesModelsObserverDispose();
    };

    public get getStagesSelected() {
        return this.buildViewModels.filter((buildViewModels) => {
            return buildViewModels.getValue("isVisible");
        }).length;
    }

    public get IsEdit() {
        return this.buildViewModels.find((buildViewModels) => {
            return buildViewModels.isEdit;
        });
    }

    private stagesModelsObserverDispose = observe(this.model.stagesModels, (childModelChanges: any) => {
        for (const addedChildModel of childModelChanges.added) {
            this.buildViewModels.push(new BuildStagesViewModel(addedChildModel));
        }
        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.buildViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);
            if (childViewModelToRemove) {
                this.buildViewModels.remove(childViewModelToRemove);
            }
        }
    });
}
