import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { PanelModel } from "./PanelModel";
import { Logger } from "index";

export class PanelViewModel extends ViewModelBase<PanelModel> {
    public apiClient = new APIClient();

    constructor(model = new PanelModel()) {
        super(model);
        makeObservable(this, {});
    }

    public navigateCommand = new RelayCommand((path: string) => {
        this.history.push(path);
    });
}
