import React, { useState, Children } from "react";
import { motion, useSpring } from "framer-motion";
import styled from "@emotion/styled";
import { container } from "tsyringe";
import { DomainStore } from "../../Stores/Domain/DomainStore";
import { Logger } from "../../index";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";

const domainStore = container.resolve(DomainStore);

const Container = styled(motion.div)`
    position: fixed;
    width: 100vw;
    //pointer-events: ${domainStore.isSideBarClosed ? "all" : "none"};
`;

const SidebarContainer = styled(motion.div)`
    position: fixed;
    background-color: #4a4a4a;
    width: ${({ width }: { width: number }) => `${width}px`};
    height: 100%;
    box-sizing: border-box;
    transform: ${domainStore.isSideBarClosed ? `translateX(0%)` : `translateX(-100%)`};
    transition: all 0.5s ease !important;
    top: 50px;
    height: calc(100vh - 50px);
    overflow: auto;
    & > div > div {
        padding: 4px 15px;
        border-bottom: 1px solid #fff;
        min-height: 70px;
        label {
            color: #fff !important;
            font-weight: 700 !important;
        }
    }
`;

const HamburgerContainer = styled(motion.div)`
    border-radius: 32px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0;
    //margin: 24px;
    cursor: pointer;
    z-index: 2;
    pointer-events: all;
    /* -webkit-tap-highlight-color: transparent; */
`;
const Line = styled(motion.div)`
    background-color: #505050;
    width: ${({ width }: { width: any }) => `${width}px`};
    height: 2px;
    margin: 2px 14px;
    border-radius: 8px;
`;

const closeMenu = () => {
    domainStore.setSideBarClosed(false);
};

const HamburgerButton = observer(({ x, width }: { x: number; width: number }) => {
    reaction(
        () => domainStore.isSideBarClosed,
        (sidebarOpen) => {
            domainStore.isSideBarClosed ? (x as any).set(-width) : (x as any).set(0);
        },
    );

    return (
        <HamburgerContainer
            onTap={() => {
                Logger.logDebug("Sidebar is open ", domainStore.isSideBarClosed);
                domainStore.setSideBarClosed(!domainStore.isSideBarClosed);
            }}
        >
            <Line width={15} />
            <Line width={15} />
            <Line width={15} />
        </HamburgerContainer>
    );
});

export const Sidebar = observer(({ width = 320, color = "#1c2022", children, left = true }: { width: number; color: string; children: any; left: boolean }) => {
    const x = useSpring(0, { stiffness: 400, damping: 40 });

    return (
        <Container>
            <HamburgerButton x={x as any} width={width} />
            <SidebarContainer
                color={color}
                width={width}
                transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                }}
                // initial={{ x: -width }}
                style={{ transform: domainStore.isSideBarClosed ? "translateX(0) translateZ(0px)" : "translateX(-100%) translateZ(0px)" }}
            >
                {children}
            </SidebarContainer>
        </Container>
    );
});
