import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ChoiceModel extends ModelBase<ChoiceModel> {
    public title: string = "Dummy Text Data";
    public choicesMade: string = "Dummy Text Data";

    constructor() {
        super();
        makeObservable(this, {
            title: observable,
            choicesMade: observable,
        });
    }
}

export class ChoiceModelValidator extends Validator<ChoiceModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
