import React from "react";
import { observer } from "mobx-react-lite";
import { EditableCopyStore } from "../Stores";
import { container } from "tsyringe";
import { Link, MessageBar, MessageBarType } from "@fluentui/react";

export const CommonInjects = observer(() => {
    const domainStores = container.resolve(EditableCopyStore);

    return <></>;
});
