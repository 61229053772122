import * as React from "react";
import clsx from "clsx";
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { ICommand, theme } from "Application";
import Select, { OptionsOrGroups } from "react-select";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FieldLabel } from "../../../Styles/BaseStyles";
// #region View Props

const SelectContainer = styled.div`
    .editselect__placeholder {
        font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    }
`;

interface IProps {
    canClear?: boolean;
    command: ICommand;
    className?: string;
    displayName?: string;
    optionRenderer?: (item: any) => void;
    options: KeyValuePair[];
    labelColor?: string;
    placeholder?: string;
    style?: any;
    value: () => string;
    isMulti?: boolean;
    validationMessage?: () => string;
    ref?: React.Ref<any>;
}

// #endregion View Props

export const EditSelect: React.FC<IProps> = (props) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    // #region Code Behind
    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value());
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                let arr = [];
                for (const item of values) {
                    const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                    if (initValue) {
                        arr.push({ value: initValue.key, label: initValue.text });
                    }
                }
                setSelectedOption(arr);
            }
        }
    }, []);

    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        props.command.execute({ key: option.value, text: option.label } as KeyValuePair);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };

    const isValidationMessageNotEmpty = (): boolean => {
        return true;
        //?return !isEmptyOrWhitespace(props.validationMessage!());
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePair) => {
            return {
                label: item.text,
                value: item.key,
            };
        });
    };
    // #endregion Code Behind
    return (
        <SelectContainer className={getClassName()}>
            {canDisplayName() && (
                <FieldLabel
                    style={{
                        color: props.labelColor,
                        font: "normal normal 300 14px/17px Lato",
                        letterSpacing: "0.7px",
                        // color: "#4a4a4a",
                        textTransform: "uppercase",
                        margin: "0 0 5px",
                        display: "block",
                    }}
                    className="select-label"
                >
                    {props.displayName}
                </FieldLabel>
            )}
            <div>
                {/*<Select options={options} />*/}
                <Select
                    ref={props.ref}
                    isClearable={getCanClear()}
                    className="virtualized-select-validation"
                    classNamePrefix={"editselect"}
                    isDisabled={isDisabled()}
                    onChange={onChange}
                    isMulti={props.isMulti}
                    //optionRenderer={props.optionRenderer}
                    //optionHeight={({ option }) => (option.disabled ? 0 : 25)}
                    /* options={props.options.map((option) => {
                        return {
                            className: option.className,
                            value: option.value,
                            label: option.displayName,
                            disabled: option.disabled,
                        };
                    })}*/
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    styles={{ ...props.style }}
                    value={selectedOption}
                />
                {/* {isValidationMessageNotEmpty() && <p>{props.validationMessage!()}</p>}*/}
            </div>
        </SelectContainer>
    );
    // #endregion Public Interface
};
