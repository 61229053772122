import { makeObservable, observable } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, RelayCommand, RelayCommandAsync } from "Application";
import { CustomercareModel, CustomercareModelValidator } from "./CustomercareModel";
import { GETCustomerCareEndpoint } from "./Endpoints/GETCustomerCareEndpoint";
import { GETYourSnagsEndpoint } from "./Endpoints/GETYourSnagsEndpoint";
import { SnagModel } from "./SnagModel";

export class CustomercareViewModel extends ViewModelBase<CustomercareModel> {
    public apiClient = new APIClient();
    public reportASnagActive: boolean = false;
    public yourSnagsActive: boolean = true;
    public completedIssuesActive: boolean = false;
    public houseAreaOptions = [] as KeyValuePair[];
    public snagTypeOptions = [] as KeyValuePair[];
    public yourSnags: SnagModel[] = [];

    constructor(model = new CustomercareModel()) {
        super(model);
        this.setValidator(new CustomercareModelValidator());
        makeObservable(this, {
            reportASnagActive: observable,
            yourSnagsActive: observable,
            completedIssuesActive: observable,
            yourSnags: observable,
        });
        this.apiClient.sendAsync(new GETCustomerCareEndpoint(this), this.model);
        this.apiClient.sendAsync(new GETYourSnagsEndpoint(this), this.model);
    }

    public snags(category: string) {
        if (category === "open") {
            return this.openSnags;
        } else if (category === "closed") {
            return this.closedSnags;
        } else {
            return this.rejectedSnags;
        }
    }

    public get openSnags() {
        return this.yourSnags.filter((x) => x.status === "TASK" && x.category !== "NA");
    }
    public get lastUpdatedDateOpenSnags() {
        return this.openSnags.length > 0 ? this.openSnags[0].dateClosed : "";
    }
    public get closedSnags() {
        return this.yourSnags.filter((x) => x.status === "CLOSED" && x.category !== "NA");
    }
    public get rejectedSnags() {
        return this.yourSnags.filter((x) => x.status === "REJECTED" && x.category !== "NA");
    }

    //#region Update Commands
    public setReportASnagActiveCommand = new RelayCommand(() => {
        this.reportASnagActive = true;
        this.yourSnagsActive = false;
        this.completedIssuesActive = false;
    });
    public setYourSnagsActiveCommand = new RelayCommand(() => {
        this.yourSnagsActive = true;
        this.reportASnagActive = false;
        this.completedIssuesActive = false;
    });

    public setCompletedIssuesActiveCommand = new RelayCommand(() => {
        this.yourSnagsActive = false;
        this.reportASnagActive = false;
        this.completedIssuesActive = true;
    });

    public uploadImageCommand = new RelayCommandAsync(async () => {});

    public updateDetailsCommand = new RelayCommand((value: string) => {
        this.setValue("details", value);
    });

    public viewDetailCommand = new RelayCommand((snag: SnagModel) => {
        this.history.push(`/customercare/currentsnags/detail/${snag.coinsReference}`);
    });
    //#endregion Commands
}
