import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { ButtonBase, IButtonBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/ButtonBase";
import { theme } from "../../AppTheme";
import { getThemePaletteOption, getThemeShapeOption, getThemeSizeOption, pxToRem } from "../../../Application/Helpers";
import { space, SpaceProps } from "styled-system";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

const InternalButton = styled(ButtonBase)<SpaceProps>`
    {
    ... props
    } ${space};
    `;

/**
 * A styled button. The styling can be adjusted using the size and paletteColor props.
 */
export const ThemedButton = observer((props: PropsWithChildren<IButtonBaseProps & SpaceProps>) => {
    const size = getThemeSizeOption(props.size);
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const shape = getThemeShapeOption(props.shape);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <InternalButton
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        backgroundColor: paletteColor.main,
                        borderColor: paletteColor.main,
                        borderRadius: shape,
                        color: paletteColor.text,
                        height: size,
                        padding: "0 30px",
                    },
                    rootFocused: {
                        backgroundColor: paletteColor.light,
                        color: paletteColor.text,
                        "::after": {
                            // Fix issues with square outlines on rounded buttons with focus.
                            outline: "none !important",
                            inset: "1px !important",
                            border: `1px solid ${paletteColor.text} !important`,
                            borderRadius: shape,
                        },
                    },
                    rootHovered: {
                        backgroundColor: paletteColor.dark,
                        borderColor: paletteColor.dark,
                        color: paletteColor.text,
                    },
                    rootPressed: {
                        backgroundColor: paletteColor.light,
                        borderColor: paletteColor.light,
                        color: paletteColor.text,
                    },
                    rootDisabled: {
                        backgroundColor: theme.palette.disabled.main,
                        borderColor: theme.palette.disabled.main,
                        color: theme.palette.disabled.text,
                    },
                    label: {
                        fontFamily: theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.button.fontSize,
                        fontWeight: theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.button.letterSpacing,
                        textTransform: theme.fontStyles.button.textTransform,
                    },
                    icon: {
                        fontSize: pxToRem(14),
                    },
                    iconDisabled: {
                        stroke: theme.palette.disabled.text,
                        g: {
                            stroke: theme.palette.disabled.text,
                        },
                        line: {
                            stroke: theme.palette.disabled.text,
                        },
                        path: {
                            fill: theme.palette.disabled.text,
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});

// #region Directly-styled buttons

/**
 * A large default-styled button.
 */
export const ShootDefaultButton = observer((props: PropsWithChildren<IButtonBaseProps>) => (
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    <ButtonBase
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    backgroundColor: theme.palette.default.light,
                    borderColor: theme.palette.default.light,
                    color: theme.palette.default.text,
                    height: theme.sizes.large,
                },
                rootFocused: {
                    backgroundColor: theme.palette.default.light,
                    color: theme.palette.default.text,
                    "::after": {
                        outlineColor: `${theme.palette.default.text} !important`,
                    },
                },
                rootHovered: {
                    backgroundColor: theme.palette.default.main,
                    borderColor: theme.palette.default.main,
                    color: theme.palette.default.text,
                },
                rootPressed: {
                    backgroundColor: theme.palette.default.dark,
                    borderColor: theme.palette.default.dark,
                    color: theme.palette.default.text,
                },
                rootDisabled: {
                    backgroundColor: theme.palette.disabled.main,
                    borderColor: theme.palette.disabled.main,
                    color: theme.palette.disabled.text,
                },
                label: {
                    fontFamily: theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.button.fontSize,
                    fontWeight: theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.button.letterSpacing,
                    textTransform: theme.fontStyles.button.textTransform,
                },
                icon: {
                    fontSize: pxToRem(14),
                },
                iconDisabled: {
                    stroke: theme.palette.disabled.text,
                    g: {
                        stroke: theme.palette.disabled.text,
                    },
                    line: {
                        stroke: theme.palette.disabled.text,
                    },
                    path: {
                        fill: theme.palette.disabled.text,
                    },
                },
            },
            props.styles,
        )}
    />
));

// #endregion
