import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PanelModel } from "./Panel/PanelModel";

export class DashboardModel extends ModelBase<DashboardModel> {
    public coinsPlotId: string | null = null;
    public developmentName: string | null = null;
    public houseTypeName: string | null = null;
    public panelModel: PanelModel = new PanelModel();
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public address4: string | null = null;
    public postcode: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public buildCompletedCount: number | null = 0;
    public buildOverallCount: number | null = 0;
    public salesCompletedCount: number | null = 0;
    public salesOverallCount: number | null = 0;
    public documentCount: number | null = 0;
    public snagsCount: number | null = 0;
    public reportedSnagsCount: number | null = 0;
    public buildProgressModifiedDateUTC: string = "5th April 2023   @ 11:33";
    public salesProgressModifiedDateUTC: string = "5th April 2023   @ 11:33";
    public documentStoreModifiedDateUTC: string = "5th April 2023   @ 11:33";
    public customerCareModifiedDateUTC: string = "5th April 2023   @ 11:33";
    constructor() {
        super();
        makeObservable(this, {
            coinsPlotId: observable,
            developmentName: observable,
            houseTypeName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
            firstName: observable,
            lastName: observable,
            buildCompletedCount: observable,
            buildOverallCount: observable,
            salesCompletedCount: observable,
            salesOverallCount: observable,
            documentCount: observable,
            snagsCount: observable,
            reportedSnagsCount: observable,
        });
    }
}

export class DashboardModelValidator extends Validator<DashboardModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
