import { mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { PropsWithChildren } from "react";

import { theme } from "../../AppTheme";
import { observer } from "mobx-react-lite";
import { DataTableBase, IDetailsListProps } from "../../../Components";
import useMediaQuery from "Application/Hooks/useMediaQuery";

/**
 * A styled datalist control. The styling can be adjusted using the size and paletteColor props.
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedDataTable = observer((props: PropsWithChildren<IDetailsListProps>) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                //EN: More styling props needed for the datalist.
                {
                    check: {
                        ".ms-Check": {
                            backgroundColor: "green",
                        },
                    },
                    root: {
                        /*".ms-Check.is-checked::before": {
                            backgroundColor: "green",
                        },*/
                        background: "#fff !important",
                        ".ms-DetailsHeader-cellName": {
                            color: theme.palette.tableHeader.text,
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.tableHeader.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                        },
                        ".ms-DetailsHeader-cell": {
                            background: "#E3E3E3",
                            padding: "10px",
                            borderRight: "1px solid #fff",
                            width: "100% !important",
                            "&:hover": {
                                background: "#E3E3E3",
                            },
                        },
                        ".ms-DetailsHeader": {
                            borderBottom: "none",
                            paddingTop: "0",
                            paddingBottom: "15px",
                            display: "grid",
                            gridTemplateColumns: isMobile ? "150px 150px 250px 150px 100px" : "15% 15% 40% 15% 15%",
                        },
                        ".ms-DetailsRow-cell": {
                            font: "normal normal normal 14px/20px Lato",
                            letterSpacing: "0px",
                            color: "#4A4A4A",
                            padding: "10px",
                            width: "100% !important",
                        },
                        ".ms-DetailsRow-fields": {
                            display: "grid",
                            gridTemplateColumns: isMobile ? "150px 150px 250px 150px 100px" : "15% 15% 40% 15% 15%",
                            width: "100% !important",
                        },
                        ".ms-DetailsRow-fields > div": {
                            width: "100% !important",
                            font: "normal normal normal 14px/20px Lato",
                            letterSpacing: "0px",
                            color: "#4A4A4A",
                            padding: "10px",
                        },
                        ".ms-List-cell": {
                            ".ms-DetailsRow": {
                                borderBottom: "1px dashed #E3E3E3",
                                width: "100% !important",
                            },
                        },
                        ".ms-List-cell:last-child .ms-DetailsRow": {
                            borderBottom: "1px solid #E3E3E3",
                        },
                    },
                    headerWrapper: {},
                    subComponentStyles: {},
                },
                props.styles,
            )}
        />
    );
});

// #endregion Directly-styled buttons
