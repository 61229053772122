import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { SalesprogressViewModel } from "../SalesprogressViewModel";
import { StagesModel } from "../Stages/StagesModel";

class Response {
    public salesProgressItems: SalesProgressResponse[] = [];
    public plotNumber: string = "";
    public developmentName: string = "";
    public address1: string | null = "";
    public address2: string | null = "";
    public address3: string | null = "";
    public address4: string | null = "";
    public postcode: string | null = "";
    public salesOverallCount: number = 0;
}
class SalesProgressResponse {
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public stageDateUTC: string | null = "";
    public description: string | null = "";
    public number: number = 0;
    public title: string | null = "";
    public id: string = "";
}

export class GETSalesProgressEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: SalesprogressViewModel;
    constructor(viewModel: SalesprogressViewModel, coinsPlotId: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/getsalesprogress/" + coinsPlotId);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.setValue("plotNumber", response.plotNumber);
        this.viewModel.setValue("developmentName", response.developmentName);
        this.viewModel.setValue("address1", response.address1);
        this.viewModel.setValue("address2", response.address2);
        this.viewModel.setValue("address3", response.address3);
        this.viewModel.setValue("address4", response.address4);
        this.viewModel.setValue("postcode", response.postcode);
        this.viewModel.setValue("salesOverallCount", response.salesOverallCount);
        runInAction(() => {
            for (const item of response.salesProgressItems) {
                let m = new StagesModel();
                m.isCompleted = item.isCompleted;
                m.isVisible = item.isVisible;
                m.stageDateUTC = new Date(item.stageDateUTC!);
                m.description = item.description!;
                m.number = item.number;
                m.title = item.title!;
                this.viewModel.model.stagesModels.push(m);
            }
        });
        //Return your model
        return {};
    }
}
