import { sortByString, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

import { AppUrls } from "AppUrls";
import { ICommandAsync, RelayCommandAsync, APIClient, ICommand, RelayCommand } from "../../../../Application";
import { GetAllUsersEndpoint } from "../Endpoints/PortalUsers/GetlAllUsersEndpoint";
import { Logger } from "../../../../index";
import { DeleteUserEndpoint } from "../Endpoints/PortalUsers/DeleteUserEndpoint";
import { PortalUsersModel } from "./PortalUsersModel";
import { UnlockUserEndpoint } from "../Endpoints/PortalUsers/UnlockUserEndpoint";

export default class PotalUsersViewModel extends ViewModelBase<PortalUsersModel> {
    public users: PortalUsersModel[] = [];
    public apiClient = new APIClient();
    public userToDelete: PortalUsersModel | null = null;
    public userToUnlock: PortalUsersModel | null = null;
    public showUnlockDialog: boolean = false;
    public showLockDialog: boolean = false;
    public showDialog: boolean = false;
    public userCount: number = 0;
    public ascOrder = true;
    public resetLoginAttemptsError = "";
    public isTableLoading: boolean = false;
    public selectedRows: string[] = [];
    public searchKeyword: string = "";
    public fiterDevelopmentId: string = "";
    constructor() {
        super(new PortalUsersModel(null));
        makeObservable(this, {
            userCount: observable,
            users: observable,
            ascOrder: observable,
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            showDialog: observable,
            showUnlockDialog: observable,
            showLockDialog: observable,
            set: action,
            loadUsersAsync: action,
            getUsers: computed,
            setIsTableLoading: action,
            getUserCount: computed,
            setOrderAsc: action,
            getOrderAsc: action,
            resetFailedLoginAttempts: action,
            getUserName: action,
        });
        let _ = this.loadUsersAsync();
    }

    public updateDevelopmentIdCommand = new RelayCommand((value: string) => {
        this.setValue("filterId", value);
        /*if(this.model.id) {
            this.accountStore.CoinsDevelopmentId = value;
        }*/
    });
    public updateSearchKeyword = new RelayCommand((value: string) => {
        this.setValue("searchKeyword", value);
        /*if(this.model.id) {
            this.accountStore.CoinsDevelopmentId = value;
        }*/
    });

    public copyEmailToClipboardCommand: ICommand = new RelayCommand((portalUserModel: PortalUsersModel) => {
        console.log("TODO");
    });

    public newUserCommand: ICommand = new RelayCommand(() => {
        this.history.push(`/admin/portaluserslist/user/new`);
    });

    public editUserCommand: ICommand = new RelayCommand((portalUserModel: PortalUsersModel) => {
        this.history.push(`/admin/portaluserslist/user/${portalUserModel.id}`);
    });

    public deleteUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (portalUserModel: PortalUsersModel) => {
        this.userToDelete = portalUserModel;
        this.showDialog = true;
    });

    public hideDialogCommand: ICommand = new RelayCommand(async () => {
        this.showDialog = false;
    });

    public deleteUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new DeleteUserEndpoint(this.userToDelete!.id));
        if (this.apiClient.IsRequestSuccessful) {
            // CoreStoreInstance.ShowInfoBar("User was successfully deleted", "success");
            // this.userToDelete!.setValue("isDeleted", true);
            this.hideDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToDelete = null;
        }
    });

    public unlockUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (portalUserModel: PortalUsersModel) => {
        this.userToUnlock = portalUserModel;
        this.showUnlockDialog = true;
    });

    public lockUserWithConfirmDialogCommand: ICommand = new RelayCommand(async (portalUserModel: PortalUsersModel) => {
        this.userToUnlock = portalUserModel;
        this.showLockDialog = true;
    });

    public hideUnlockDialogCommand: ICommand = new RelayCommand(async () => {
        this.showUnlockDialog = false;
    });

    public unlockUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new UnlockUserEndpoint(this.userToUnlock!.id, false));
        if (this.apiClient.IsRequestSuccessful) {
            this.hideUnlockDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToUnlock = null;
        }
    });

    public hideLockDialogCommand: ICommand = new RelayCommand(async () => {
        this.showLockDialog = false;
    });

    public LockUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new UnlockUserEndpoint(this.userToUnlock!.id, true));
        if (this.apiClient.IsRequestSuccessful) {
            this.hideLockDialogCommand.execute();
            let _ = this.loadUsersAsync();
            this.userToUnlock = null;
        }
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public async loadUsersAsync(): Promise<void> {
        const getAllUsersEndpoint = new GetAllUsersEndpoint(this);
        await this.apiClient.sendAsync(getAllUsersEndpoint);
        Logger.logDebug("loadUsersAsync: ", this.apiClient.Response<[]>());
    }

    public setUsers = (users: any) => {
        this.users = users;
    };

    public async resetFailedLoginAttempts(): Promise<void> {
        await this.apiClient.command.Post(
            AppUrls.Server.Admin.ResetFailedLoginAttemptsCount,
            {
                id: this.getValue("id"),
            },
            async (response) => {
                this.resetLoginAttemptsError = "";
            },
            () => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            },
        );
    }

    get getUsers(): PortalUsersModel[] {
        this.users.slice().sort((a: PortalUsersModel, b: PortalUsersModel) => {
            if (this.ascOrder) {
                return sortByString(a.firstName, b.firstName);
            } else {
                return sortByString(b.firstName, a.firstName);
            }
        });
        return this.users.filter(
            (a: any) =>
                (this.model.filterId !== null
                    ? a.coinsDevelopmentId == this.model.filterId
                    : a.coinsDevelopmentId !== ""
                    ? a.coinsDevelopmentId !== this.model.filterId
                    : a.coinsDevelopmentId === "") &&
                (a.emailAddress.toLowerCase().includes(this.model.searchKeyword.toLowerCase()) || a.firstName.toLowerCase().includes(this.model.searchKeyword.toLowerCase())) &&
                !a.isDeleted &&
                !a.isAdminUser,
        );
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    get getUserCount(): number {
        return this.userCount;
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    public getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public getUserName = (id: string | null) => {
        if (id) {
            const userDetails = this.users.find((i) => i.id == id);
            const userName = userDetails ? userDetails.firstName + " " + userDetails.lastName : "";
            return userName;
        } else {
            return "-";
        }
    };
}
