import React from "react";
import { SVGProps } from "react";
const WriteIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g id="Group_8308" data-name="Group 8308" transform="translate(-201 -176)">
                <circle id="Ellipse_214" data-name="Ellipse 214" cx="12.5" cy="12.5" r="12.5" transform="translate(201 176)" fill="#7DD34C" />
                <g id="noun_tick_162332" transform="translate(208.5 183.322)">
                    <path
                        id="Path_3708"
                        data-name="Path 3708"
                        d="M8.658,17.765a.656.656,0,0,1-.467-.189l-2.914-3a.684.684,0,1,1,.945-.99l2.136,1.524a.279.279,0,0,0,.412-.044L13.83,8.811a.683.683,0,1,1,1.09.823L9.2,17.487a.682.682,0,0,1-.489.267A.1.1,0,0,1,8.658,17.765Z"
                        transform="translate(-4.061 -7.537)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

export default WriteIcon;
