import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import UserDetailsViewModel from "../UserDetail/UserDetailsViewModel";
import { isNullOrEmpty, KeyValuePair } from "@shoothill/core";
import { runInAction } from "mobx";

class Response {
    public plots: PlotResponse[] = [];
}
class PlotResponse {
    public plotId: string = "";
    public plotAddress: string = "";
}

export class GETPlotEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: UserDetailsViewModel;
    constructor(coinsDevelopmentId: string, viewModel: UserDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(`/api/plot/getplots/${coinsDevelopmentId}`);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        this.viewModel.plotOptions = [];
        runInAction(() => {
            for (const item of response.plots) {
                if (!isNullOrEmpty(item.plotId)) {
                    this.viewModel.plotOptions.push({
                        key: item.plotId,
                        text: isNullOrEmpty(item.plotAddress) ? "Plot " + item.plotId : item.plotAddress,
                    } as KeyValuePair);
                }
            }
        });
        //Return your model
        return {};
    }
}
