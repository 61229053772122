import React, { useEffect } from "react";
import { RolesList } from "./Roles/RolesList";
import { UsersList } from "./Users/UsersList";
import { observer } from "mobx-react-lite";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { mergeStyleSets } from "@fluentui/react";
import { Box, Label, theme } from "../../Application";

import styled from "@emotion/styled/dist/emotion-styled.cjs";

import { BlankViewModel } from "Application/ViewModels/BlankViewModel";
import { GlobalHistory } from "index";
import { PortalUsersList } from "./Users/PortalUsers/PortalUsersList";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";

interface TabButtonProps {
    active: boolean;
}
const TabButton: any = styled(Box)<TabButtonProps>`
    display: flex;
    flex: 25% 0 0;
    width: 200px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${(props) => (props.active ? "#C2602F" : "#F47E44")};
    box-shadow: ${(props) => (props.active ? "1px 1px 3px #00000040" : "none")};
    @media only screen and (max-width: 600px) {
        flex: 33% 0 0;
    }
    label {
        cursor: pointer;
        font: normal normal bold 18px/30px Lato;
        letter-spacing: -0.18px;
        color: #ffffff;
        text-transform: capitalize;
        margin: 0;
    }
`;

const MobileTabButton: any = styled(TabButton)`
    width: 165px;
    height: 36px;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

//TODO: Turn into base component
const styles: any = mergeStyleSets({
    root: {
        textDecoration: "none",
        color: theme.palette.primary.text,
        ...theme.fontStyles.h2,
        marginLeft: "30px",
    },

    current: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
});

export const Admin: React.FC = observer(() => {
    const [adminUsersActive, setAdminUsersActive] = React.useState(true);
    const [portalUsersActive, setPortalUsersActive] = React.useState(false);
    const accountStore = container.resolve(AccountStore);
    const viewModel = new BlankViewModel();
    useEffect(() => {
        if (GlobalHistory.location.pathname === "/admin/dashboard/portaluserslist") {
            setAdminUsersActive(false);
            setPortalUsersActive(true);
            viewModel.history.push(`/admin/dashboard/portaluserslist`);
        }
        if (isSalesAdmin()) {
            setAdminUsersActive(false);
            setPortalUsersActive(true);
            viewModel.history.push(`/admin/dashboard/portaluserslist`);
        }
    }, []);

    const isSalesAdmin = () => accountStore.isInRole("sales admin");
    const isAdmin = () => accountStore.isInRole("admin");

    const tabs = () => {
        return (
            <Box display={"flex"} style={{ gap: "1px" }} mb={"15px"}>
                {!isSalesAdmin() && (
                    <TabButton
                        active={adminUsersActive}
                        onClick={() => {
                            setAdminUsersActive(true);
                            setPortalUsersActive(false);
                            viewModel.history.push(`/admin/dashboard/userslist`);
                        }}
                    >
                        <Label color={"white"} fontStyle={"h3"}>
                            Admin Users
                        </Label>
                    </TabButton>
                )}
                {(isSalesAdmin() || isAdmin()) && (
                    <TabButton
                        active={portalUsersActive}
                        onClick={() => {
                            setAdminUsersActive(false);
                            setPortalUsersActive(true);
                            viewModel.history.push(`/admin/dashboard/portaluserslist`);
                        }}
                    >
                        <Label color={"white"} fontStyle={"h3"}>
                            Portal Users
                        </Label>
                    </TabButton>
                )}
            </Box>
        );
    };

    const tabsMobile = () => {
        return (
            <Box display={"flex"} style={{ gap: "1px" }} mb={"15px"}>
                {!isSalesAdmin() && (
                    <MobileTabButton
                        active={adminUsersActive}
                        onClick={() => {
                            setAdminUsersActive(true);
                            setPortalUsersActive(false);
                            viewModel.history.push(`/admin/dashboard/userslist`);
                        }}
                    >
                        <Label
                            color={"white"}
                            fontStyle={"h3"}
                            style={{ font: "normal normal 300 12px/14px Lato", textAlign: "center", letterSpacing: "-0.12px", color: "#FFFFFF", textTransform: "uppercase" }}
                        >
                            Admin <span style={{ font: "normal normal 300 10px/14px Lato", letterSpacing: "-0.1px", display: "block" }}>Users</span>
                        </Label>
                    </MobileTabButton>
                )}
                {(isSalesAdmin() || isAdmin()) && (
                    <MobileTabButton
                        active={portalUsersActive}
                        onClick={() => {
                            setAdminUsersActive(false);
                            setPortalUsersActive(true);
                            viewModel.history.push(`/admin/dashboard/portaluserslist`);
                        }}
                    >
                        <Label
                            color={"white"}
                            fontStyle={"h3"}
                            style={{ font: "normal normal 300 12px/14px Lato", textAlign: "center", letterSpacing: "-0.12px", color: "#FFFFFF", textTransform: "uppercase" }}
                        >
                            Portal <span style={{ font: "normal normal 300 10px/14px Lato", textAlign: "center", letterSpacing: "-0.1px", display: "block" }}>Users</span>
                        </Label>
                    </MobileTabButton>
                )}
            </Box>
        );
    };

    return (
        <>
            <Box background={"#4A4A4A"} padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"0"}
                        style={{
                            letterSpacing: "-0.24px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            opacity: 1,
                            font: "normal normal bold 24px/30px Lato",
                        }}
                    >
                        {adminUsersActive ? "Admin - Admin Users" : "Admin - Portal Users"}
                    </Label>
                </Container>
            </Box>
            <Box padding={"15px 15px 30px"}>
                <Container id={"rootcontainer"}>
                    <Box>{viewModel.isMobile ? tabsMobile() : tabs()}</Box>
                    <Routes>
                        <Route path={"userslist"} element={<UsersList />} />
                        <Route path={"roleslist"} element={<RolesList />} />
                        <Route path={"portaluserslist"} element={<PortalUsersList />} />
                    </Routes>
                </Container>
            </Box>
        </>
    );
});
