import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedText, ThemedEditText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { PanelViewModel } from "./PanelViewModel";

interface Props {
    viewModel: PanelViewModel;
}
export const PanelView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const lookupStore = container.resolve(LookupStore);
    useEffect(() => {
        setPageTitle("Panel");
    }, []);

    return (
        <Box p={3}>
            <ThemedText fontStyle="h3">Page Name: Panel</ThemedText>
            <Box grid dc="1fr 1fr 1fr" mt={3}>
                <ThemedEditText
                    displayName={"Title"}
                    command={viewModel.updateTitleCommand}
                    value={() => viewModel.getValue("title")}
                    validationMessage={() => viewModel.getError("title")}
                />
                <ThemedEditText
                    displayName={"Description"}
                    command={viewModel.updateDescriptionCommand}
                    value={() => viewModel.getValue("description")}
                    validationMessage={() => viewModel.getError("description")}
                />
                <ThemedEditDate
                    command={viewModel.updateLastupdatedCommand}
                    displayName={"LastUpdated"}
                    value={() => viewModel.getValue("lastUpdated")}
                    validationMessage={() => viewModel.getError("lastUpdated")}
                />
            </Box>
        </Box>
    );
});
