/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Modal } from "@fluentui/react";
import { LogoutModelView } from "./ModelViews/LogoutModelView";

export interface ILogoutProps {
    isShow?: boolean;
    onClose: () => void;
}
const LogoutView: React.FC<ILogoutProps> = (props) => {
    const renderLogoutModalContent = () => {
        return (
            <Modal isOpen={props.isShow}>
                <LogoutModelView onClose={props.onClose} />
            </Modal>
        );
    };

    return <>{renderLogoutModalContent()}</>;
};
export default LogoutView;
