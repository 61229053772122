import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Box, setPageTitle, Label } from "Application";
import { DocumentListViewModel } from "./DocumentListViewModel";
import { DocumentView } from "./DocumentView";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
    & > div:last-child {
        border-bottom: none;
    }
`;

export const DocumentListView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new DocumentListViewModel());

    useEffect(() => {
        setPageTitle("Documents");
    }, []);
    return (
        <>
            <Box background={"#4A4A4A"} padding={isMobile ? "10px 15px 40px" : "25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{ letterSpacing: "-0.24px", color: "#FFFFFF", textTransform: "capitalize", opacity: 1, fontSize: "24px", lineHeight: "30px", fontWeight: "bold" }}
                    >
                        Documents
                    </Label>
                    <Label
                        fontStyle={"h3"}
                        style={{
                            letterSpacing: isMobile ? "-0.24px" : "0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "400",
                        }}
                    >
                        Plot {viewModel.getValue("plotNumber")} {viewModel.getValue("developmentName")}
                    </Label>
                    <FieldLabel
                        fontStyle={"h4"}
                        style={{ letterSpacing: isMobile ? "-0.15px" : "0", color: "#f47e44", textTransform: "capitalize", fontSize: "10px", lineHeight: "18px", marginTop: "4px" }}
                    >
                        {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                            viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                        } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                    </FieldLabel>
                </Container>
            </Box>
            <Box px={"15px"}>
                <Container id={"rootcontainer"}>
                    {viewModel.model.documentModels.map((documentListModel, index) => {
                        return (
                            <React.Fragment key={documentListModel.KEY}>
                                <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"0"} borderBottom={"1px dashed #BEBEBE"}>
                                    <DocumentView
                                        viewModel={viewModel}
                                        id={documentListModel.documentId}
                                        title={documentListModel.documentTypeLongDescription}
                                        fileName={documentListModel.fileName}
                                    />
                                </Box>
                            </React.Fragment>
                        );
                    })}
                </Container>
            </Box>
        </>
    );
});
