import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../../../AppUrls";

export class Response {}
export class UnlockUserEndpoint extends EndpointWithoutRequest<Response> {
    private userId: Guid | null;
    private lockUser: boolean;
    constructor(userId: Guid | null, lockUser: boolean) {
        super();
        this.userId = userId;
        this.lockUser = lockUser;
        this.verb(Http.Put);
        this.path(AppUrls.Server.Account.User.UnlockUser + "/" + userId + "/" + lockUser);
    }
    public async HandleResponseAsync(response: Response[]): Promise<void> {}
}
