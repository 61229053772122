import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ThemedText } from "../../Styles/ThemedPrimitives/Style1/ThemedText";
import { ThemedEditText, theme } from "../../Styles";
import { remToPx } from "../Helpers";
import { Box } from "../../Components/StyleFrameworks/FluentUI";
import { NullCommand } from "../Commands";
import { ThemedButton } from "../../Styles/ThemedPrimitives/Style1";
import { TestViewModel } from "./TestViewModel";
import { AutoGrid } from "../index";

export const StyleSheetView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    // @ts-ignore
    return (
        <Box p={5}>
            <Box display={"flex"} alignContent={"center"} justifyContent={"center"}>
                <ThemedText styles={{ root: { fontSize: "50px", textDecoration: "underline" } }} fontStyle={"h1"}>
                    Style Sheet 1
                </ThemedText>
            </Box>
            <Box>
                <ThemedText pt={2} color={"black"} fontStyle="h1">
                    h1. Heading ({theme.fontStyles.h1.fontSize}, {remToPx(theme.fontStyles.h1.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h2">
                    h2. Heading ({theme.fontStyles.h2.fontSize}, {remToPx(theme.fontStyles.h2.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h3">
                    h3. Heading ({theme.fontStyles.h3.fontSize}, {remToPx(theme.fontStyles.h3.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h4">
                    h4. Heading ({theme.fontStyles.h4.fontSize}, {remToPx(theme.fontStyles.h4.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h5">
                    h5. Heading ({theme.fontStyles.h5.fontSize}, {remToPx(theme.fontStyles.h5.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h6">
                    h6. Heading ({theme.fontStyles.h6.fontSize}, {remToPx(theme.fontStyles.h6.fontSize)})
                </ThemedText>
                <ThemedText pt={2} color={"black"} fontStyle="h7">
                    h7. Heading ({theme.fontStyles.h7.fontSize}, {remToPx(theme.fontStyles.h7.fontSize)})
                </ThemedText>
                <ThemedText pt={50} color={"black"} fontStyle="p">
                    p. Heading ({theme.fontStyles.p.fontSize}, {remToPx(theme.fontStyles.p.fontSize)})
                </ThemedText>
            </Box>
            <Box pt={100}>
                <ThemedText pt={2} color={"black"} fontStyle="h1">
                    Buttons
                </ThemedText>
                <Box p={2} backgroundColor={"default"} display={"flex"} alignContent={"center"} justifyContent={"space-between"}>
                    <ThemedText color={"white"} fontStyle="h2">
                        Title
                    </ThemedText>
                    <ThemedButton paletteColor={"primary"} command={new NullCommand()} displayName={"Add Something"} />
                </Box>
                <Box pt={5}>
                    <ThemedText>Button width is determined by the text within it in most cases. 30px either side of the button text.</ThemedText>
                    <br />
                    <ThemedText>However in the case of two buttons next to each other they default to the widest button width.</ThemedText>
                </Box>
                <Box mt={2} display={"flex"}>
                    <ThemedButton mr={2} paletteColor={"primary"} command={new NullCommand()} displayName={"Save"} />
                    <ThemedButton mr={2} paletteColor={"primary"} command={new NullCommand()} displayName={"Save Changes"} />
                    <ThemedButton mr={2} paletteColor={"default"} command={new NullCommand()} displayName={"Cancel"} />
                    <ThemedButton paletteColor={"delete"} command={new NullCommand()} displayName={"Delete"} />
                </Box>
                <Box pt={5}>
                    <ThemedText>But there are always exceptions</ThemedText>
                </Box>
                <AutoGrid mt={5} rowGap={"20px"} dc={"162px 162px 162px"}>
                    <ThemedButton paletteColor={"primary"} command={new NullCommand()} displayName={"Save"} />
                    <ThemedButton paletteColor={"default"} command={new NullCommand()} displayName={"Cancel"} />
                    <ThemedButton paletteColor={"delete"} command={new NullCommand()} displayName={"Delete"} />
                </AutoGrid>
            </Box>
            <Box pt={100}>
                <ThemedText pt={2} color={"black"} fontStyle="h1">
                    Form elements
                </ThemedText>
                <Box pt={5} display={"flex"} alignItems={"center"}>
                    <ThemedText mr={2} fontStyle={"h3"}>
                        Search
                    </ThemedText>

                    <Box flexGrow={1}>
                        <ThemedEditText
                            displayName=""
                            command={viewModel.updateFirstNameCommand}
                            placeholder="Keyword search - Please start typing what you wish to find"
                            value={() => viewModel.getValue("firstName")}
                            validationMessage={() => "Validation Error Message Sample"}
                        />
                    </Box>
                </Box>
                <Box pt={1}>
                    <ThemedEditText
                        displayName=""
                        command={new NullCommand()}
                        placeholder="Keyword search - Please start typing what you wish to find"
                        value={() => viewModel.getValue("firstName")}
                        validationMessage={() => "Validation Error Message Sample"}
                    />
                </Box>
            </Box>
        </Box>
    );
});
