import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Label } from "Application";
import { SnagModel } from "./SnagModel";
import { CustomercareViewModel } from "./CustomercareViewModel";

import { BackPageSVG } from "Assets/SVGs/BackPage";
import useMediaQuery from "Application/Hooks/useMediaQuery";
interface Props {
    viewModel: CustomercareViewModel;
    viewDetails: any;
    snagDetail: SnagModel | undefined;
}
export const SnagDetailView: React.FC<Props> = observer((props: Props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const snag = useRef<SnagModel>();
    const [title, setTitle] = useState("");

    useEffect(() => {
        if (props.snagDetail) {
            snag.current = props.snagDetail;
            setTitle(`${decodeURIComponent(escape(snag.current.description))}`);
        }
    }, []);

    return (
        <Box mt={"30px"}>
            <Box
                flexBox
                mb={"25px"}
                alignItems={"center"}
                width={"100%"}
                height={"30px"}
                onClick={props.viewDetails}
                style={{
                    cursor: "pointer",
                    gap: "8px",
                    font: isMobile ? "normal normal 300 12px/30px Lato" : "normal normal bold 18px/30px Lato",
                    letterSpacing: "-0.18px",
                    color: "#2A71CD",
                    textTransform: isMobile ? "uppercase" : "capitalize",
                }}
            >
                <BackPageSVG /> Back to Current Issues
            </Box>
            <Box>
                <Label
                    mb={isMobile ? "8px" : "24px"}
                    mt={"0"}
                    style={{ font: isMobile ? "normal normal normal 14px/19px Lato" : "normal normal normal 14px/17px Lato", letterSpacing: "-0.21px", color: "#4A4A4A" }}
                >
                    {title}
                </Label>
            </Box>
        </Box>
    );
});
