import { IButtonStyles, IIconProps, ITextFieldStyles, PrimaryButton, TextField } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrEmpty, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { ICommand } from "../../../../../Application/Commands";
import { reaction } from "mobx";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles";
import { IKeyState } from "../../../IKeyState";

/**
 * Button interface.
 */
export interface IEditTextBaseProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * An icon to display on the button.
     */
    iconName?: string;
    /**
     * Treats the component as a textarea.
     */
    isMultiline?: boolean;
    /**
     * Number of rows in a multiline box
     */
    rows?: number;
    /**
     * Forces browsers to not fill the text
     */
    autoFill?: boolean;
    /**
     * Styling of the control.
     */
    styles?: Partial<ITextFieldStyles>;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * The color of the label.
     */
    labelColor?: themeColourOptions;
    /**
     * The description text to show under the text.
     */
    description?: string;
    /**
     * Called when description is rendered
     */
    onRenderDescription?: (isInError: boolean) => JSX.Element;
    /**
     * Called when label is rendered
     */
    onRenderLabel?: (isInError: boolean) => JSX.Element;
    /**
     * The color of the label.
     */
    prefix?: JSX.Element | string;
    /**
     * The color of the label.
     */
    suffix?: JSX.Element | string;
    /**
     * The control type - password or text.
     */
    type?: "password" | "text" | "email" | "number";
    maxLength?: number;
}

export const EditTextBase: React.FC<IEditTextBaseProps> = observer((props) => {
    // #region Code Behind
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });
    const [value, setValue] = useState<string>("");
    useEffect(() => {
        reaction(
            () => props.value(),
            (value) => {
                setValue(value);
            },
        );
        setValue(props.value());
    }, [props.value]);

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (vent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (props.type === "number") {
            newValue = newValue?.replace(/\D/g, "");
        }
        if (newValue!.length > props.maxLength!) {
            return;
        }
        setValue(newValue as string);
        props.command.execute(newValue, keyState.current);
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getIcon = (): IIconProps | undefined => {
        return !isEmptyOrWhitespace(props.iconName) ? { iconName: props.iconName } : undefined;
    };

    const getStyles = (): Partial<ITextFieldStyles> | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getType = (): string => {
        return isEmptyOrWhitespace(props.type) ? "text" : props.type!;
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    const onRenderDescription = (isInError: boolean): JSX.Element => {
        return props.onRenderDescription?.(isInError) ?? <>{props.description}</>;
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let keyId: number = event.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            await props.command.execute(value, keyState.current);
        }
    };
    // #endregion Code Behind

    return (
        <TextField
            id={props.id}
            autoAdjustHeight={props.isMultiline}
            canRevealPassword={true}
            className={getClassNames()}
            disabled={isDisabled()}
            autoComplete={getAutoFill()}
            errorMessage={getValidationMessage()}
            iconProps={getIcon()}
            prefix={props.prefix as any}
            suffix={props.suffix as any}
            label={props.displayName}
            multiline={props.isMultiline}
            description={props.description}
            onRenderLabel={() => onRenderLabel(getValidationMessage() !== "")}
            onRenderDescription={() => onRenderDescription(getValidationMessage() !== "")}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={props.placeholder}
            resizable={false}
            rows={props.rows ? props.rows : 5}
            styles={getStyles()}
            type={getType()}
            value={props.value()}
        />
    );
});
EditTextBase.defaultProps = {
    maxLength: 10000,
};
