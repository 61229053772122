import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ChoiceModel } from "./Choice/ChoiceModel";

export class ChoicesModel extends ModelBase<ChoicesModel> {
    public choiceModels = observable<ChoiceModel>([]);

    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class ChoicesModelValidator extends Validator<ChoicesModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
