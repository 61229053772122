import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { Box, setPageTitle, Label, theme, DashedLine } from "Application";
import { ChoiceViewModel } from "./ChoiceViewModel";
import styled from "@emotion/styled";
import { useParams } from "react-router";
import { CoinsPageContainer, CoinsPageNoTitleContainer, Paragraph } from "../../../Styles/BaseStyles";
import { LargePlus } from "../../../Components/LargePlus";
import { AnimatePresence, motion } from "framer-motion";
import Basin from "Assets/Choices/basin.png";
import { ChoicePanel } from "./ChoicePanelView";

const Container = styled(Box)`
    background: #efefef;
    padding-bottom: 24px;
    cursor: pointer;
    box-shadow: 0 3px 6px #00000040;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
`;

interface Props {
    viewModel: ChoiceViewModel;
}
export const ChoiceView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const lookupStore = container.resolve(LookupStore);
    let { selection } = useParams();

    useEffect(() => {
        setPageTitle("Choice");
    }, []);

    const tile = (
        <Container onClick={() => viewModel.history.push("/choices/" + viewModel.getValue("path") + "/selection")}>
            <img width={"100%"} src={props.viewModel.model.asset} alt={"image"} />
            <Box ml={3} mt={3}>
                <Label fontStyle={"h3"}>{viewModel.getValue("title")}</Label>
                <Box mt={3}>
                    <Label fontStyle={"h4"}>{`${viewModel.getValue("numberChoicesMade")} of ${viewModel.getValue("numberChoicesAvailable")} choices made`}</Label>
                </Box>
            </Box>
        </Container>
    );

    const tileMobile = (
        <Container onClick={() => viewModel.history.push("/choices/" + viewModel.getValue("path") + "/selection")}>
            <img width={"100%"} src={props.viewModel.model.asset} alt={"image"} />
            <Box ml={3} mt={3}>
                <Label fontStyle={"h3"} style={{ fontSize: "16px" }}>
                    {viewModel.getValue("title")}
                </Label>
                <Box mt={3}>
                    <Label fontStyle={"h4"} style={{ fontSize: "11px" }}>{`${viewModel.getValue("numberChoicesMade")} of ${viewModel.getValue(
                        "numberChoicesAvailable",
                    )} choices made`}</Label>
                </Box>
            </Box>
        </Container>
    );

    return (
        <>
            <Box showIf={selection === undefined}>{viewModel.isMobile ? tileMobile : tile}</Box>
            <Box showIf={selection !== undefined} /* height={"90vh"} */>
                <div style={{ marginTop: viewModel.isMobile ? "20px" : "30px" }}>
                    <CoinsPageContainer
                        style={{ minHeight: "50px" }}
                        title={viewModel.getValue("title")}
                        ml={viewModel.isMobile ? "10px" : "30px"}
                        mr={viewModel.isMobile ? "10px" : "20px"}
                    >
                        <Paragraph>
                            Select an option, then choose to upgrade and add any extras. Once you have finished, your selected items will be automatically saved for you.
                        </Paragraph>
                    </CoinsPageContainer>
                </div>
                <CoinsPageNoTitleContainer ml={"0"} mr={"0"} style={{ padding: "0", backgroundColor: "unset" }}>
                    <ChoicePanel viewModel={viewModel} />
                </CoinsPageNoTitleContainer>
            </Box>
        </>
    );
});
