import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";

export const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.239" height="25" viewBox="0 0 19.239 25">
        <g id="Group_8791" data-name="Group 8791" transform="translate(0 0)">
            <path
                id="Subtraction_4"
                data-name="Subtraction 4"
                d="M17.58,22.47H11.123v-.016H0V-.146H12.046V4.871H17.58v17.6Zm-13.443-4.5a.619.619,0,1,0,0,1.237h9.95a.619.619,0,1,0,0-1.237Zm0-3.4a.619.619,0,1,0,0,1.237h9.95a.619.619,0,1,0,0-1.237Zm0-3.4a.618.618,0,1,0,0,1.237h9.95a.618.618,0,1,0,0-1.237Zm0-3.4A.619.619,0,1,0,4.136,9h9.95a.619.619,0,1,0,0-1.237Zm0-3.4a.619.619,0,1,0,0,1.238H8.49a.619.619,0,1,0,0-1.238Z"
                transform="translate(0.405 1.345)"
                fill="#f47e44"
            />
            <path
                id="Subtraction_6"
                data-name="Subtraction 6"
                d="M17.416,25H1.832A1.826,1.826,0,0,1,.009,23.176V20.833H0V19.551H.009V1.824A1.826,1.826,0,0,1,1.832,0h10.51A1.814,1.814,0,0,1,13.58.487l2.93,2.931L18.7,5.612A1.794,1.794,0,0,1,19.239,6.9V23.176A1.826,1.826,0,0,1,17.416,25ZM1.832,1.282a.542.542,0,0,0-.542.542V23.176a.542.542,0,0,0,.542.542H17.416a.542.542,0,0,0,.542-.542V7.051H14.011a1.826,1.826,0,0,1-1.824-1.824V1.282Zm11.637.907V5.228a.542.542,0,0,0,.542.542H17.05Z"
                transform="translate(0 0)"
                fill="#c2602f"
            />
        </g>
    </svg>
);
