import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Moment } from "moment";
import { Validator } from "../../../../Application/Validation";

// App
export class PortalUsersModel extends ModelBase<PortalUsersModel> {
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public password: string = "";
    public developmentId: string = "";
    public plotId: string = "";
    public userPlotId: string | null = null;
    public concurrencyToken: string = "";
    public lastLoginDate: Moment | null = null;
    public userRoles = observable<string>([]);
    public plotNumber: string = "";
    public developmentName: string = "Harriers Rest";
    public address1: string | null = "221B Harriers Rest";
    public address2: string | null = "off Eagle Lane";
    public address3: string | null = "Wittering";
    public address4: string | null = "Lincs";
    public postcode: string | null = "TE5 EST";
    public searchKeyword: string = "";
    public filterId: string | null = null;
    public coinsPlotId: string | null = null;
    public isAdminUser: boolean = false;
    public lockoutEnabled: boolean = false;
    public accessFailedCount: number = 0;
    public hasFailedCoinsUserCheck: boolean = false;
    constructor(id: Guid) {
        super();
        this.id = id;
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            password: observable,
            concurrencyToken: observable,
            lastLoginDate: observable,
            userRoles: observable,
            developmentId: observable,
            plotId: observable,
            plotNumber: observable,
            developmentName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            address4: observable,
            postcode: observable,
            searchKeyword: observable,
            filterId: observable,
            coinsPlotId: observable,
            isAdminUser: observable,
            lockoutEnabled: observable,
            accessFailedCount: observable,
            hasFailedCoinsUserCheck: observable,
        });
    }
}

export class PortalUsersModelValidator extends Validator<PortalUsersModel> {
    constructor() {
        super();
        this.ruleFor("emailAddress").emailAddress();
        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a firstName")
            .notEmpty()
            .withMessage("Please enter a firstName")
            .maxLength(50)
            .withMessage("Please enter a firstName less than 50 characters");
        this.ruleFor("lastName")
            .notNull()
            .withMessage("Please enter a LastName")
            .notEmpty()
            .withMessage("Please enter a LastName")
            .maxLength(50)
            .withMessage("Please enter a LastName less than 50 characters");
        this.ruleFor("developmentId").notEmpty().withMessage("Please select a development");
        this.ruleFor("plotId").notEmpty().withMessage("Please select a plot");
    }
}
