import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle } from "Application";
import { DashboardViewModel } from "../DashboardViewModel";
import { PanelViewModel } from "./PanelViewModel";
import styled from "@emotion/styled";
import { Label } from "../../../Components/Primitives/Labels";
import { HorizontalBlock } from "../../../Components/HorizontalBlock";

interface PanelProps {
    image: string;
}
const Panel = styled(Box)<PanelProps>`
    width: 100%;
    background-color: #4a4a4a;
    background-image: ${(p) => `url(${p.image})`};
    background-repeat: no-repeat;
    border-right: 5px solid #f47e44;
    border-radius: 2px;
    cursor: pointer;
    padding: 30px 18px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WelcomeLabelTitle = styled(Label)`
    letter-spacing: -0.26px;
    display: block;
    font-family: "Lato", sans-serif !important;
    font: normal normal bold 24px/24px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 0 8px;
`;

const WelcomeLabelTitleBold = styled(Label)`
    display: block;
    font-family: "Lato", sans-serif !important;
    font: normal normal normal 12px/24px Lato;
    color: #fff;
    margin: 0;
    letter-spacing: 0px;
`;

const WelcomeLabelSmall = styled(Label)`
    display: block;
    font: normal normal bold 14px/14px Lato;
    letter-spacing: 0px;
    color: #f47e44;
    text-transform: capitalize;
`;

const WelcomeLabelSmallAddress = styled(Label)`
    font: normal normal bold 14px/14px Lato;
    letter-spacing: 0px;
    color: #f47e44;
    text-transform: capitalize;
`;

const WelcomeLabelBold = styled(Label)`
    display: block;
    font-family: "Lato", sans-serif !important;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 8px;
`;

const WelcomeLabelBoldAddress = styled(Label)`
    display: block;
    font-family: "Lato", sans-serif !important;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 8px;
`;

interface Props {
    icon: JSX.Element;
    viewModel: PanelViewModel;
    delay: number;
    path: string;
    description: string;
    title: string;
    subTitle?: string;
    lastUpdatedDate: string;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
    desktopViewModel: DashboardViewModel;
    image?: string;
}
export const WelcomePanelView: React.FC<Props> = observer((props: Props) => {
    const { desktopViewModel, viewModel } = props;
    const [isHover, setIsHover] = useState(false);
    useEffect(() => {
        setPageTitle("Panel");
    }, []);

    const PanelForDesktop = () => {
        return (
            <Panel
                style={props.style}
                onClick={() => viewModel.navigateCommand.execute(props.path)}
                image={""}
                transition={{ type: "tween", duration: 0.1, delay: props.delay }}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
            >
                <Box>
                    <Box mb={"25px"}>
                        <WelcomeLabelTitle>Welcome</WelcomeLabelTitle>
                        <WelcomeLabelTitleBold>{`${desktopViewModel.getValue("firstName") ? desktopViewModel.getValue("firstName") : ""}`}</WelcomeLabelTitleBold>
                    </Box>
                    <Box mb={"25px"}>
                        <WelcomeLabelSmall>My new home</WelcomeLabelSmall>
                        <WelcomeLabelBold>
                            {`Home ${desktopViewModel.getValue("coinsPlotId") ? parseInt(desktopViewModel.getValue("coinsPlotId"), 10) : ""}`}
                            <br />
                            {`${desktopViewModel.getValue("developmentName") ? desktopViewModel.getValue("developmentName") : ""}`}
                            <br />
                            {`${desktopViewModel.getValue("houseTypeName") ? desktopViewModel.getValue("houseTypeName") : ""}`}
                        </WelcomeLabelBold>
                    </Box>
                    <Box mb={"25px"}>
                        <WelcomeLabelSmallAddress>My new address</WelcomeLabelSmallAddress>
                        <WelcomeLabelBoldAddress>{`${desktopViewModel.getValue("address1") ? desktopViewModel.getValue("address1") : ""} ${
                            desktopViewModel.getValue("address2") ? desktopViewModel.getValue("address2") : ""
                        } ${desktopViewModel.getValue("address3") ? desktopViewModel.getValue("address3") : ""} ${
                            desktopViewModel.getValue("address4") ? desktopViewModel.getValue("address4") : ""
                        } ${desktopViewModel.getValue("postcode") ? decodeURIComponent(escape(desktopViewModel.getValue("postcode"))) : ""}`}</WelcomeLabelBoldAddress>
                    </Box>
                    <Box flexBox style={{ gap: 0 }} justifyContent={"space-between"}>
                        <Label style={{ fontWeight: 600 }} fontStyle={"h4"}>
                            {props.title}
                        </Label>
                        <Box
                            mr={"15px"}
                            mt={"5px"}
                            animate={{ x: isHover ? ["0px", "-5px", "0px"] : ["0px", "0px"] }}
                            transition={{ type: "tween", duration: 1, repeat: Infinity, delay: props.delay }}
                        ></Box>
                    </Box>
                </Box>
            </Panel>
        );
    };

    const PanelForMobile = () => {
        return (
            <Box grid dc={"1fr"} mr={"30px"} ml={"30px"} style={{ width: "100%", maxWidth: "80vw" }}>
                <HorizontalBlock
                    title={props.title}
                    image={props.image}
                    subTitle={props.subTitle}
                    description={props.description}
                    lastUpdated={props.lastUpdatedDate}
                    icon={props.icon}
                    path={props.path}
                    view={props.view}
                />
            </Box>
        );
    };

    return <>{viewModel.isMobile ? PanelForMobile() : PanelForDesktop()}</>;
});
