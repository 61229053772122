import * as React from "react";
import { SVGProps } from "react";

export const DashboardSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
        <g id="Group_8738" data-name="Group 8738" transform="translate(-0.166 0)">
            <g id="Group_5654" data-name="Group 5654" transform="translate(0 0)">
                <g id="Group_5613" data-name="Group 5613">
                    <g id="Ellipse_209" data-name="Ellipse 209" transform="translate(0.166 0)" fill="#f47e44" stroke="#c2602f" stroke-width="2.5">
                        <circle cx="26" cy="26" r="26" stroke="none" />
                        <circle cx="26" cy="26" r="24.75" fill="none" />
                    </g>
                </g>
            </g>
            <g id="noun_dashboard_3846734" transform="translate(16.175 16.285)">
                <g id="Group_5836" data-name="Group 5836" transform="translate(0 0)">
                    <path
                        id="Path_3844"
                        data-name="Path 3844"
                        d="M11.666,6.006v7.547A1.826,1.826,0,0,1,9.84,15.378H6.006A1.826,1.826,0,0,1,4.18,13.553V6.006A1.826,1.826,0,0,1,6.006,4.18H9.84A1.826,1.826,0,0,1,11.666,6.006ZM9.84,16.766H6.006A1.826,1.826,0,0,0,4.18,18.592v4.017a1.826,1.826,0,0,0,1.826,1.826H9.84a1.826,1.826,0,0,0,1.826-1.826V18.592A1.826,1.826,0,0,0,9.84,16.766Zm12.769-4.492H14.879A1.826,1.826,0,0,0,13.054,14.1v8.508a1.826,1.826,0,0,0,1.826,1.826h7.729a1.826,1.826,0,0,0,1.826-1.826V14.1A1.826,1.826,0,0,0,22.609,12.275Zm0-8.095H14.879a1.826,1.826,0,0,0-1.826,1.826V9.061a1.826,1.826,0,0,0,1.826,1.826h7.729a1.826,1.826,0,0,0,1.826-1.826V6.006A1.826,1.826,0,0,0,22.609,4.18Z"
                        transform="translate(-4.18 -4.18)"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
);
