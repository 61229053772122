import React from "react";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxOptionStyles, IComboBoxStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { ICommand, RelayCommand } from "../../../../../Application/Commands";
import { themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";
import { Box } from "Application";
import { ClearText } from "Components/General/ClearText";
import styled from "@emotion/styled";

/**
 * Single-selection Combo box interface.
 */
export interface IComboBoxBaseProps {
    /**
     * An optional class name for use with the combobox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the combobox. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * A collection of options to display in the combox dropdown.
     */
    options: IComboBoxOption[];
    /**
     * Text content to display on the combox.
     */
    displayName?: string;
    /**
     * Styling of the combobox.
     */
    styles?: IComboBoxStyles;
    /**
     * Styling of the combobox options.
     */
    optionStyles?: IComboBoxOptionStyles;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;

    canClearText?: boolean | undefined;
}

export const ComboBoxBase: React.FC<IComboBoxBaseProps> = observer((props) => {
    const ClearComboBoxText = styled(Box)`
        .clearComboBoxText {
            width: 30px;
            height: 30px;
            position: absolute;
            z-index: 1;
            right: 30px;
            bottom: 1px;
            background: none !important;
        }
        .clearComboBoxText:hover {
            background-color: rgb(243, 242, 241);
            cursor: pointer;
        }
    `;

    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const getStyles = (): IComboBoxStyles | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getComboBoxOptionStyles = (): IComboBoxOptionStyles | undefined => {
        return !isNullOrUndefined(props.optionStyles) ? props.optionStyles : undefined;
    };

    const getOptions = (): IComboBoxOption[] => {
        return props.options.map((option) => option);
    };

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        props.command.execute(option?.key);
    };

    const canDisplayClear = (): boolean => {
        if (props.canClearText === false || props.canClearText === undefined) {
            return false;
        } else {
            if (!isNullOrUndefined(props.value())) {
                return !isEmptyOrWhitespace(props.value().toString()) && !isDisabled();
            }
            return false;
        }
    };

    const clearTextCommand = new RelayCommand(() => {
        props.command.execute(null);
    });

    return (
        <>
            <Box style={{ width: "100%", position: "relative" }}>
                <ComboBox
                    className={getClasseNames()}
                    disabled={isDisabled()}
                    errorMessage={getValidationMessage()}
                    label={props.displayName}
                    onChange={onChange}
                    options={getOptions()}
                    placeholder={props.placeholder}
                    selectedKey={props.value()}
                    styles={getStyles()}
                    comboBoxOptionStyles={getComboBoxOptionStyles()}
                    useComboBoxAsMenuWidth={true}
                />
                {canDisplayClear() && (
                    <ClearComboBoxText>
                        <Box className="clearComboBoxText">
                            <Box minWidth={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} padding={"5px 0px"}>
                                <ClearText command={clearTextCommand} />
                            </Box>
                        </Box>
                    </ClearComboBoxText>
                )}
            </Box>
        </>
    );
});
