import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { ChoicesModel, ChoicesModelValidator } from "./ChoicesModel";
import { Logger } from "index";
import { ChoiceModel } from "./Choice/ChoiceModel";
import { ChoiceViewModel } from "./Choice/ChoiceViewModel";

export class ChoicesViewModel extends ViewModelBase<ChoicesModel> {
    public apiClient = new APIClient();
    public choiceViewModels = observable<ChoiceViewModel>([]);

    constructor(model = new ChoicesModel()) {
        super(model);
        this.setValidator(new ChoicesModelValidator());
        makeObservable(this, {});
    }

    public dispose = (): void => {
        this.choiceModelsObserverDispose();
    };

    private choiceModelsObserverDispose = observe(this.model.choiceModels, (childModelChanges: any) => {
        Logger.logDebug(`ChoiceViewModel.childModelsObserverDispose`);
        for (const addedChildModel of childModelChanges.added) {
            this.choiceViewModels.push(new ChoiceViewModel(addedChildModel));
        }

        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.choiceViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);

            if (childViewModelToRemove) {
                this.choiceViewModels.remove(childViewModelToRemove);
            }
        }
    });

    //#region Update Commands
    public addChoiceCommand = new RelayCommand(() => {
        this.model.choiceModels.push(new ChoiceModel());
    });

    //#endregion Commands
}
