import { makeObservable, observable } from "mobx";
import { FieldType, HttpClient, KeyValuePair, Validation, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { TodoModel } from "./TodoModel";
import { ICommandAsync, RelayCommandAsync } from "../../Application/Commands";
import { APIClient, IKeyState } from "../../Application";
import { TodoItemModel } from "./TodoItemModel";

export class TodoItemViewModel extends ViewModelBase<TodoItemModel> {
    public apiClient = new APIClient();
    public errorMessage: string = "";
    public validMessage: string = "";

    constructor(jsonTodoItem?: any) {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TodoItemModel(), false);
        //this.setValidator(new TestModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });
        this.model.toModel(jsonTodoItem);
    }

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("title", value);
    });

    private async updateField(fieldName: keyof FieldType<TodoItemModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    private async getToDoList() {
        await this.apiClient.query.Get("https://jsonplaceholder.typicode.com/todos", async (response) => {});
    }
}
