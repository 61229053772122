import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { BuildprogressModel, BuildprogressModelValidator } from "./BuildprogressModel";
import { Logger } from "index";
import { StagesModel } from "./Stages/StagesModel";
import { StagesViewModel } from "./Stages/StagesViewModel";

export class BuildprogressViewModel extends ViewModelBase<BuildprogressModel> {
    public apiClient = new APIClient();
    public stagesViewModels = observable<StagesViewModel>([]);

    constructor(model = new BuildprogressModel()) {
        super(model);
        this.setValidator(new BuildprogressModelValidator());
        makeObservable(this, {});
    }

    public dispose = (): void => {
        this.stagesModelsObserverDispose();
    };

    private stagesModelsObserverDispose = observe(this.model.stagesModels, (childModelChanges: any) => {
        Logger.logDebug(`StagesViewModel.childModelsObserverDispose`);
        for (const addedChildModel of childModelChanges.added) {
            this.stagesViewModels.push(new StagesViewModel(addedChildModel));
        }

        for (const removedChildModel of childModelChanges.removed) {
            const childViewModelToRemove = this.stagesViewModels.find((vm) => vm.model.KEY === removedChildModel.KEY);

            if (childViewModelToRemove) {
                this.stagesViewModels.remove(childViewModelToRemove);
            }
        }
    });

    //#region Update Commands
    public addStagesCommand = new RelayCommand(() => {
        this.model.stagesModels.push(new StagesModel());
    });

    //#endregion Commands
}
