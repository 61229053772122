import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { FileModel, ImageCropperModel, ImageCropperModelValidator } from "./ImageCropperModel";
import { action, makeObservable, observable } from "mobx";
import { ReactCrop } from "react-image-crop";
import { POSTSaveImageEndpoint } from "./POSTSaveImageEndpoint";
import { BuildStagesViewModel } from "Views/Admin/BuildStageAdmin/BuildStage/BuildStagesViewModel";
import { PurchaseStagesViewModel } from "Views/Admin/YourPurchase/PurchaseStages/PurchaseStagesViewModel";
import { POSTSaveSalesImageEndpoint } from "./POSTSaveSalesImageEndpoint";

export class ImageCropperViewModel extends ViewModelBase<ImageCropperModel> {
    public apiClient = new APIClient();
    public crop: ReactCrop.Crop;
    public imageDataURL: string | null = null;

    constructor(model = new ImageCropperModel()) {
        super(model);
        this.setValidator(new ImageCropperModelValidator());
        makeObservable(this, {
            //observables
            crop: observable,
            imageDataURL: observable,
            //actions
            handleCropChanged: action,
            //computed values
        });
        //set default crop
        this.crop = {
            aspect: this.model.aspectOrDefault,
            width: 100,
            height: 100,
            x: 0,
            y: 0,
        };
    }

    public handleCropChanged = (crop: ReactCrop.Crop) => {
        this.crop = crop;
    };

    public saveCropperImageCommand = new RelayCommand(
        async (files: File, id: string, closModel: ICommand, model: BuildStagesViewModel | PurchaseStagesViewModel, isBuildStageAdmin: boolean) => {
            if (files) {
                const fileModel = new FileModel();
                fileModel.file = files;
                fileModel.fileName = files.name;
                fileModel.fileSizeBytes = files.size;
                fileModel.mimeType = files.type;
                fileModel.documentUrl = "";
                this.model.files.push(fileModel);
            }
            isBuildStageAdmin
                ? await this.apiClient.sendAsync(new POSTSaveImageEndpoint(this, id, model))
                : await this.apiClient.sendAsync(new POSTSaveSalesImageEndpoint(this, id, model));

            if (this.apiClient.IsRequestSuccessful) {
                closModel.execute();
            }
        },
    );
}
