import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Label, setPageTitle } from "Application";
import { YourhomeViewModel } from "./YourhomeViewModel";
import styled from "@emotion/styled";
import { CompletionGridView } from "./SubViews/CompletionGridView";
import { OverviewGridView } from "./SubViews/OverviewGridView";
import { PaymentView } from "./SubViews/PaymentView";
import { PricesGridView } from "./SubViews/PricesGridView";
import { GalleryView } from "./SubViews/GalleryView";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

export const YourhomeView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new YourhomeViewModel());

    useEffect(() => {
        setPageTitle("Your home");
    }, []);

    return (
        <div>
            <Box background={"#4a4a4a"} padding={isMobile ? "10px 15px 40px" : "25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{ letterSpacing: "-0.24px", color: "#FFFFFF", textTransform: "capitalize", opacity: 1, fontSize: "24px", lineHeight: "30px", fontWeight: "bold" }}
                    >
                        Your Home Details
                    </Label>
                    <Label
                        fontStyle={"h3"}
                        style={{
                            letterSpacing: isMobile ? "-0.24px" : "0",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "400",
                        }}
                    >
                        Home {Number.isNaN(parseInt(viewModel.getValue("plotNumber"), 10)) ? "" : parseInt(viewModel.getValue("plotNumber"), 10)}{" "}
                        {viewModel.getValue("developmentName")}
                    </Label>
                    <FieldLabel
                        fontStyle={"h4"}
                        style={{ letterSpacing: isMobile ? "-0.15px" : "0", color: "#f47e44", textTransform: "capitalize", fontSize: "10px", lineHeight: "18px", marginTop: "4px" }}
                    >
                        {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                            viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                        } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                    </FieldLabel>
                </Container>
            </Box>
            <Box px={"15px"}>
                <Container id={"rootcontainer"}>
                    <OverviewGridView viewModel={viewModel} />
                    <PricesGridView viewModel={viewModel} />
                    <CompletionGridView viewModel={viewModel} />
                    {/* <HRLine />
                    <PaymentView viewModel={viewModel} /> */}
                    <GalleryView viewModel={viewModel} />
                </Container>
            </Box>
        </div>
    );
});
