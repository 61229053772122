import { Logger } from "../../../../index";
import { Endpoint, Http } from "../../../../Application/Helpers/BaseEndpoint";
import { RegisterUserViewModel } from "../RegisterUserViewModel";
import { RegisterUserModel } from "../RegisterUserModel";
import { AppUrls } from "../../../../AppUrls";

class RegisterRequest {
    public lastName: string = "";
    public firstName: string = "";
    public phoneNumber: number | string = "";
    public emailAddress: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public userDetails: UserDetailsRequest = new UserDetailsRequest();
    public terms: boolean = false;
    public tod: Date = new Date();
}
class UserDetailsRequest {
    public age: number | string = "";
    public companyName: string = "";
    public position: string = "";
    public companyWebsite: string = "";
    public companyRegNo: string = "";
    public companyVatNo: string = "";
    public address1: string = "";
    public address2: string = "";
    public city: string = "";
    public county: string = "";
    public postCode: string = "";
    public gender: string = "";
    public dob: Date = new Date();
    public saveAddress: boolean = false;
}

class RegisterResponse {
    public jwt: string = "";
}

export class RegisterEndpointPOST extends Endpoint<RegisterRequest, RegisterResponse> {
    private viewModel: RegisterUserViewModel;

    constructor(viewModel: RegisterUserViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Account.Register);
    }

    public async HandleRequestAsync(model: RegisterUserModel): Promise<any> {
        //EN: We have a direct one to one name mapping so we can just call fromModel
        //which will strip out the model base and give us a clean request object back
        let request = new RegisterRequest();
        model.toRequest<RegisterRequest>(request);
        return request;
    }

    public async HandleResponseAsync(response: RegisterResponse): Promise<string> {
        Logger.logInformation("Response", response);
        await this.viewModel.accountStore.getLoginState(response.jwt as string);
        return response.jwt as string;
    }
}
