import { Endpoint, Http } from "Application";
import { runInAction } from "mobx";
import { PurchaseStagesModel } from "../PurchaseStages/PurchaseStagesModel";
import { YourPurchaseViewModel } from "../YourPurchaseViewModel";
class YourPurchaseRequest {
    public id: string | null = null;
    public title: string = "";
    public sequence: string = "";
    public number: string = "";
    public description: string = "";
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";
}
class Request {
    public putUpsertSalesProgressRequests: YourPurchaseRequest[] = [];
}
class Response {
    public salesProgressItems: YourPurchaseResponse[] = [];
}
class YourPurchaseResponse {
    public id: string | null = null;
    public title: string = "";
    public sequence: string = "";
    public number: string = "";
    public description: string = "";
    public stageDateUTC: string | null = "";
    public isCompleted: boolean = false;
    public isVisible: boolean = false;
    public imageUrl: string = "";
}
export class POSTPurchaseStageAdminEndpoint extends Endpoint<Request, Response> {
    private viewModel: YourPurchaseViewModel;
    constructor(viewModel: YourPurchaseViewModel) {
        super();
        this.verb(Http.Put);
        this.path("/api/SalesProgress/upsert");
        this.viewModel = viewModel;
    }

    HandleRequestAsync(): Promise<Request> {
        const serviceModels: YourPurchaseRequest[] = [];
        this.viewModel.stagesViewModels.forEach((item) => {
            const m = new YourPurchaseRequest();
            m.id = item.model.id;
            m.title = item.model.title;
            m.sequence = item.model.sequence;
            m.number = item.model.stageCode;
            m.description = item.model.description;
            m.isCompleted = item.model.isCompleted;
            m.isVisible = item.model.isVisible;
            m.imageUrl = item.model.imageUrl;
            serviceModels.push(m);
        });

        const request = new Request();
        request.putUpsertSalesProgressRequests = serviceModels;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            let responseData: PurchaseStagesModel[] = [];
            for (const item of response.salesProgressItems) {
                let m = new PurchaseStagesModel();
                m.id = item.id;
                m.isCompleted = item.isCompleted;
                m.isVisible = item.isVisible;
                m.description = item.description;
                m.stageCode = item.number;
                m.sequence = item.sequence;
                m.title = item.title;
                m.imageUrl = item.imageUrl;
                responseData.push(m);
            }
            this.viewModel.model.stagesModels.replace(responseData);
            this.viewModel.isCancel = false;
            this.viewModel.isSave = false;
        });
        return {};
    }
}
