// Libs
import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class RoleModel extends ModelBase<RoleModel> {
    public name: string = "";

    constructor(id: Guid) {
        super();
        this.id = id;
        makeObservable(this, {
            name: observable,
        });
    }
}

export class RoleModelValidator extends Validator<RoleModel> {
    constructor() {
        super();
        this.ruleFor("name").notEmpty();
    }
}
