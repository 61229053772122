import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { StagesModel } from "./Stages/StagesModel";

export class SalesprogressModel extends ModelBase<SalesprogressModel> {
    public stagesModels = observable<StagesModel>([]);

    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class SalesprogressModelValidator extends Validator<SalesprogressModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
