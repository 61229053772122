import { isNullOrEmpty } from "@shoothill/core";
import { config } from "../config";
import moment from "moment";
import { theme } from "../Styles";

export const setPageTitle = (title: string): void => {
    // const currentPageTitle = document.getElementsByTagName("title")[0].innerHTML as string;
    // if (!isNullOrEmpty(title)) {
    //     const newPageTitle = title + " | " + config.rootPageTitle;
    //     if (currentPageTitle !== newPageTitle) {
    //         document.title = newPageTitle;
    //     }
    // }
};

export const formatDate = (date: Date, format?: string): string => {
    if (date !== null) {
        return moment(date).format(format === undefined ? theme.dates.default : format);
    }
    return "-";
};
