import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { BuildStageAdminViewModel } from "../BuildStageAdminViewModel";
import { BuildStageModel } from "../BuildStage/BuildStagesModel";
import { AppUrls } from "AppUrls";

class Response {
    public plotId: string = "";
    public plotNumber: string = "";
    public buildProgressCoinsItems: BuildStageAdminResponse[] = [];
}
class BuildStageAdminResponse {
    public id: string | null = "";
    public sequence: string = "";
    public stageCode: string = "";
    public description: string = "";
    public isVisible: boolean = false;
    public imageUrl: string = "";
}

export class GETBuildStageAdminEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: BuildStageAdminViewModel;
    constructor(viewModel: BuildStageAdminViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.BuildStageAdmin.GetBuildStagesAdmin);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.setValue("plotNumber", response.plotNumber);
        this.viewModel.setValue("plotId", response.plotId);
        runInAction(() => {
            let responseData: BuildStageModel[] = [];
            for (const item of response.buildProgressCoinsItems) {
                let m = new BuildStageModel();
                m.id = item.id;
                m.sequence = item.sequence;
                m.stageCode = item.stageCode;
                m.description = item.description;
                m.isVisible = item.isVisible;
                m.imageUrl = item.imageUrl;
                responseData.push(m);
            }
            this.viewModel.model.stagesModels.replace(responseData);
        });
        return {};
    }
}
