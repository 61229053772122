import { makeObservable, observable } from "mobx";

export class SnagModel {
    public category: string = "";
    public dateClosed: null | Date = null;
    public description: string = "";
    public loggedBy: string = "";
    public reference: string = "";
    public coinsReference: string = "";
    public status: string = "";
    public timeClosed: string = "";
    constructor() {
        makeObservable(this, {
            category: observable,
            dateClosed: observable,
            description: observable,
            loggedBy: observable,
            reference: observable,
            coinsReference: observable,
            status: observable,
            timeClosed: observable,
        });
    }
}
