import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { KeyValuePair } from "@shoothill/core";
import PotalUserDetailsViewModel from "../../PortalUsers/PortalUserDetail/PortalUserDetailsViewModel";
import { runInAction } from "mobx";

class Response {
    public developments: DevelopmentResponse[] = [];
}
class DevelopmentResponse {
    public developmentId: string = "";
    public developmentName: string = "";
    public developmentNumber: string = "";
}

export class GETDevelopemntsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PotalUserDetailsViewModel;
    constructor(viewModel: PotalUserDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path("/api/development/getall");
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        runInAction(() => {
            for (let item of response.developments) {
                this.viewModel.developmentOptions.push({
                    key: item.developmentId,
                    text: item.developmentNumber + " " + item.developmentName,
                } as KeyValuePair);
            }
        });
        //Return your model
        return {};
    }
}
