import { ModelBase } from "@shoothill/core";
import { TodoItemViewModel } from "./TodoItemViewModel";
import { makeObservable, observable } from "mobx";
import { TodoItemModel } from "./TodoItemModel";

export class TodoModel extends ModelBase<TodoModel> {
    public name: string = "";
    public todoItems = observable<TodoItemModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            // todoItems: observable,
        });
    }
}
