import { ApiResult, CoreStoreInstance, HttpClient, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { container, singleton } from "tsyringe";
import { AppUrls } from "../../AppUrls";
import { Logger } from "../../index";
import { Endpoint, EndpointWithoutRequest, Http } from "./BaseEndpoint";
import { CacheStore } from "../../Stores/Domain/CacheStore";
import * as Axios from "axios";

type ProtectedObservables = "isRequestSuccessful" | "isBusy" | "isSubmitted" | "validationMessage";
export class APIClient {
    private httpClient = container.resolve(HttpClient);
    private cacheStore = container.resolve(CacheStore);
    private sendBearer: boolean = true;
    private response: any | null = null;
    private endPoint: Endpoint<any, any> = {} as Endpoint<any, any>;

    // #region Defaults
    public static readonly DEFAULT_ISBUSY = false;
    public static readonly DEFAULT_ISREQUESTSUCCESSFUL = false;
    public static readonly DEFAULT_ISSUBMITTED = false;
    public static readonly DEFAULT_VALIDATIONMESSAGE = "";

    constructor() {
        makeObservable<APIClient, ProtectedObservables>(this, {
            isBusy: observable,
            isSubmitted: observable,
            validationMessage: observable,
            isRequestSuccessful: observable,
            IsBusy: computed,
            ValidationMessage: computed,
            HaveValidationMessage: computed,
            IsSubmitted: computed,
            IsRequestSuccessful: computed,
            resetIsBusy: action,
            setIsBusy: action,
            resetValidationMessage: action,
            setValidationMessage: action,
            resetIsSubmitted: action,
            setIsSubmitted: action,
            resetRequestSuccessful: action,
            setRequestSuccessful: action,
        });
    }
    // #endregion Defaults

    // #region Reset

    public reset = (): void => {
        this.resetIsBusy();
        this.resetIsSubmitted();
        this.resetRequestSuccessful();
        this.resetValidationMessage();
    };

    // #endrgion Reset

    // #region Busy

    private isBusy = APIClient.DEFAULT_ISBUSY;

    public get IsBusy(): boolean {
        return this.isBusy;
    }

    public resetIsBusy = (): void => {
        this.isBusy = APIClient.DEFAULT_ISBUSY;
    };

    public setIsBusy = (value: boolean): void => {
        this.isBusy = value;
    };

    // #endregion Busy

    // #region Error

    private validationMessage = APIClient.DEFAULT_VALIDATIONMESSAGE;

    public get ValidationMessage(): string {
        return this.validationMessage;
    }

    public resetValidationMessage = (): void => {
        this.validationMessage = APIClient.DEFAULT_VALIDATIONMESSAGE;
    };

    public get HaveValidationMessage(): boolean {
        return !isEmptyOrWhitespace(this.validationMessage);
    }

    public setValidationMessage = (value: string): void => {
        this.validationMessage = value;
    };

    // #endregion Error

    // #region Submitted

    private isSubmitted = APIClient.DEFAULT_ISSUBMITTED;

    public get IsSubmitted(): boolean {
        return this.isSubmitted;
    }

    public Response = <TModel>(): any => {
        return this.response as TModel;
    };

    public resetIsSubmitted = (): void => {
        this.isSubmitted = APIClient.DEFAULT_ISSUBMITTED;
    };

    public setIsSubmitted = (value: boolean): void => {
        this.isSubmitted = value;
    };

    // #endregion Submitted

    // #region Request Succeeded

    private isRequestSuccessful = APIClient.DEFAULT_ISREQUESTSUCCESSFUL;

    public get IsRequestSuccessful(): boolean {
        return this.isRequestSuccessful;
    }

    public resetRequestSuccessful = (): void => {
        this.isRequestSuccessful = APIClient.DEFAULT_ISREQUESTSUCCESSFUL;
    };

    public setRequestSuccessful = (value: boolean): void => {
        this.isRequestSuccessful = value;
    };

    // #endregion Request Succeeded
    public query = {
        Get: async <TPayload>(url: string, successfulResult: (data: TPayload) => void, failedResult?: (data: []) => void, errorMessage: string = ""): Promise<boolean> => {
            return await this.internalCommand<TPayload>("Get", url, {}, successfulResult, failedResult, errorMessage);
        },
    };

    public sendAsync = async <TRequest, TResponse, TModel = any>(endpoint: Endpoint<TRequest, TResponse> | EndpointWithoutRequest<TResponse>, model?: TModel) => {
        let request: TRequest = {} as TRequest;
        this.sendBearer = endpoint.GetSendBearer;
        this.endPoint = endpoint as Endpoint<any, any>;

        //EN: Check if the endpoint is a request endpoint
        if ((endpoint as Endpoint<TRequest, TResponse>).HandleRequestAsync) {
            request = await (endpoint as Endpoint<TRequest, TResponse>).HandleRequestAsync(model);
        }
        if (endpoint.Verb === Http.Get) {
            await this.query.Get<TResponse>(
                endpoint.Path,
                async (response: TResponse) => {
                    this.response = await endpoint.HandleResponseAsync(response);
                },
                async (errors: any) => {},
            );
        } else {
            await this.command[endpoint.Verb]<TResponse>(
                endpoint.Path,
                request,
                async (response: TResponse) => {
                    this.response = await endpoint.HandleResponseAsync(response);
                },
                async (errors: any) => {},
            );
        }
        return this.response;
    };

    public command = {
        Post: async <TPayload>(
            url: string,
            body: any,
            successfulResult: (data: TPayload) => void,
            failedResult?: (data: []) => void,
            errorMessage: string = "",
        ): Promise<boolean> => {
            return await this.internalCommand<TPayload>("Post", url, body, successfulResult, failedResult, errorMessage);
        },
        Put: async <TPayload>(
            url: string,
            body: any,
            successfulResult: (data: TPayload) => void,
            failedResult?: (data: []) => void,
            errorMessage: string = "",
        ): Promise<boolean> => {
            return await this.internalCommand<TPayload>("Put", url, body, successfulResult, failedResult, errorMessage);
        },
        Patch: async <TPayload>(
            url: string,
            body: any,
            successfulResult: (data: TPayload) => void,
            failedResult?: (data: []) => void,
            errorMessage: string = "",
        ): Promise<boolean> => {
            return await this.internalCommand<TPayload>("Patch", url, body, successfulResult, failedResult, errorMessage);
        },
        Delete: async <TPayload>(
            url: string,
            body: any,
            successfulResult: (data: TPayload) => void,
            failedResult?: (data: []) => void,
            errorMessage: string = "",
        ): Promise<boolean> => {
            return await this.internalCommand<TPayload>("Delete", url, body, successfulResult, failedResult, errorMessage);
        },
    };

    private internalCommand = async <TPayload>(
        commandAction: "Get" | "Post" | "Put" | "Patch" | "Delete",
        url: string,
        body: any,
        successfulResult: (data: TPayload) => void,
        failedResult?: (data: []) => void,
        errorMessage: string = "",
    ): Promise<boolean> => {
        const DEFAULT_SERVERVALIDATIONMESSAGE = isEmptyOrWhitespace(errorMessage) ? "There was an error trying to process the request." : errorMessage;
        this.setRequestSuccessful(false);
        //CoreStoreInstance.HideInfoBar();

        try {
            this.reset();
            this.setIsSubmitted(true);

            this.setIsBusy(true);

            let apiResult = {} as ApiResult<TPayload>;
            if (commandAction === "Get") {
                if (this.cacheStore.get(url) === null) {
                    apiResult = await this.httpClient.Get<TPayload>(url, this.sendBearer, this.getAxiosConfig() as any);
                    if (this.endPoint.Expiry > 0) {
                        if (this.IsRequestSuccessful) {
                            Logger.logDebug(`Cache Miss: Setting cache for ${url} for ${this.endPoint.Expiry} seconds`);
                            this.cacheStore.set(url, apiResult, this.endPoint.Expiry);
                        }
                    }
                } else {
                    apiResult = this.cacheStore.get(url) as ApiResult<TPayload>;
                    Logger.logDebug(`Cache Hit: Retrieving cache hit for ${url}`);
                }
            } else {
                apiResult = await this.httpClient[commandAction]<TPayload>(url, body, this.sendBearer, this.getAxiosConfig() as any);
            }

            if (this.endPoint.ResponseIsBlob) {
                successfulResult(apiResult as any);
                this.setRequestSuccessful(true);
            }
            if (apiResult.wasSuccessful) {
                if (successfulResult) {
                    successfulResult(apiResult.payload);
                }
                this.setRequestSuccessful(true);
            } else {
                if (!isNullOrUndefined(apiResult.errors) && apiResult.errors.length > 0) {
                    Logger.logWarning("Error returned from endpoint Url:", url, apiResult.errors);
                    this.setValidationMessage(apiResult.errors[0].message);
                    //CoreStoreInstance.ShowInfoBar(this.validationMessage, "error");
                } else {
                    this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
                }
                if (failedResult) {
                    failedResult(apiResult.errors as any);
                }
                this.setRequestSuccessful(false);
            }
        } catch (exception) {
            Logger.logError("Unhandled exception in API Client. Url:", url, exception);
            this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
        } finally {
            this.resetIsBusy();
        }
        return this.isRequestSuccessful;
    };

    private getAxiosConfig = (): Axios.AxiosRequestConfig<any> | undefined => {
        return this.endPoint.ResponseIsBlob ? { responseType: "blob" } : undefined;
    };
}
