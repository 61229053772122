import React, { MouseEventHandler, useEffect, useReducer, useState } from "react";
import "date-fns";
import { AutoGrid } from "Components/Grid";
import { setTheme, theme } from "Styles/AppTheme";
import { Box } from "Components";

import { IChoiceGroupOption } from "@fluentui/react/lib/components/ChoiceGroup/ChoiceGroup.types";
import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { ThemedButton, ThemedCheckBox, ThemedRadioButtons, ThemedText } from "../../Styles";
import { ICommand, NullCommand, RelayCommand } from "../Commands";
import { TestViewModel } from "./TestViewModel";

const newTheme = {
    palette: {
        primary: {
            light: "#fbe900",
            dark: "#7986cb",
            main: "#ffdc00",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff4081",
            dark: "#c51162",
            main: "#f50057",
            contrastText: "#fff",
        },
        error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff", text: "#fff" },
        warning: { light: "#ffb74d", main: "#ff9800", dark: "#f57c00", contrastText: "rgba(0, 0, 0, 0.87)", text: "#fff" },
        info: { light: "#64b5f6", main: "#2196f3", dark: "#1976d2", contrastText: "#fff", text: "#fff" },
        success: { light: "#81c784", main: "#4caf50", dark: "#388e3c", contrastText: "rgba(0, 0, 0, 0.87)", text: "#fff" },
        disabled: { light: "#6d6d6d", dark: "#1b1b1b", main: "#424242", contrastText: "#fff", text: "#000" },
        hint: { light: "#6d6d6d", dark: "#1b1b1b", main: "#424242", contrastText: "#fff", text: "#000" },
        ///***************
        ///Add your Custom Colours below and in IShoothillTheme.ts file
        ///!***************
        purpleAccent: { light: "rgb(198, 51, 255)", main: "#b800ff", dark: "rgb(128, 0, 178)", contrastText: "rgba(255, 255, 255, 0.87)", text: "pink" }, //This is a custom colour
    },
};

export const ColoursFluentUI: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    const [value, setValue] = React.useState<string>("female");
    const [oldTheme, setOldTheme] = React.useState(theme);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    /* const handleChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        setValue(option!.text);
    };*/
    const handleChangeCommand: ICommand = new RelayCommand((event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setValue(option!.text);
    });

    const handleThemeChangeCommand: ICommand = new RelayCommand(() => {
        let oldTheme = theme;
        setTheme(newTheme);
        forceUpdate();
    });

    return (
        <>
            <Box p={"20px"}>
                <Link href={"https://shoothilltfs.visualstudio.com/Shoothill.Web.Template/_wiki/wikis/Shoothill.Web.Template.wiki/269/User-Guide"} target={"_blank"}>
                    <ThemedText color={"secondary"} fontStyle={"h2"}>
                        Important!! Read the User Guide
                    </ThemedText>
                </Link>
                <ThemedText color={"secondary"} fontStyle={"h3"}>
                    Home Page
                </ThemedText>
                <ThemedText fontStyle={"h4"}>
                    <a target={"_blank"} href={"https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/"}>
                        Theme Generator
                    </a>
                </ThemedText>
                <br />

                <br />
                <ThemedButton command={handleThemeChangeCommand} displayName={"Change Theme"} />
                <ThemedText fontStyle={"h3"}>Text Colours</ThemedText>
                <Box mt={2}>
                    <ThemedText>primary.main</ThemedText>
                    <ThemedText color={"secondary"}>secondary.main</ThemedText>
                    <ThemedText color={"tertiary"}>text.custom</ThemedText>
                </Box>
                <br />
                <ThemedText fontStyle="h4">BG Colours</ThemedText>
                <AutoGrid rowGap={"10px"} columnGap={"10px"} desktopColumns={"1fr 1fr 1fr"}>
                    <Box backgroundColor={"primary.light"} p={2}>
                        primary.light
                    </Box>
                    <Box backgroundColor={"primary.main"} p={2}>
                        primary.main
                    </Box>
                    <Box backgroundColor={"primary.dark"} p={2}>
                        primary.dark
                    </Box>

                    <Box backgroundColor={"secondary.light"} p={2}>
                        secondary.light
                    </Box>
                    <Box backgroundColor={"secondary.main"} p={2}>
                        secondary.main
                    </Box>
                    <Box backgroundColor={"secondary.dark"} p={2}>
                        secondary.dark
                    </Box>
                    <Box backgroundColor={"error.main"} p={2}>
                        error.main
                    </Box>
                    <Box backgroundColor={"warning.main"} p={2}>
                        warning.main
                    </Box>
                    <Box backgroundColor={"info.main"} p={2}>
                        info.main
                    </Box>
                    <Box backgroundColor={"success.main"} p={2}>
                        success.main
                    </Box>
                    <Box backgroundColor={"primary.text"} color={"secondary.contrastText"} p={2}>
                        text.primary
                    </Box>
                    <Box backgroundColor={"secondary.text"} color={"secondary.contrastText"} p={2}>
                        text.secondary
                    </Box>
                    <Box backgroundColor={"disabled.text"} p={2}>
                        text.disabled
                    </Box>
                    <Box backgroundColor={"purpleAccent.main"} p={2}>
                        text.custom
                    </Box>
                    <Box backgroundColor={"primary.main"} p={2}>
                        text.custom
                    </Box>
                </AutoGrid>
                <br />
                <ThemedText fontStyle="h4">Control Colours</ThemedText>
                <AutoGrid rowGap={"10px"} columnGap={"10px"} desktopColumns={"1fr 1fr 1fr"}>
                    <Box>
                        <ThemedText>Primary</ThemedText>
                        <ThemedRadioButtons
                            aria-label="gender"
                            options={[
                                { key: "female", text: "Female" },
                                { key: "male", text: "Male" },
                                { key: "other", text: "Other" },
                                { key: "disabled", text: "Disabled", disabled: true },
                            ]}
                            value={() => viewModel.getValue("gender")}
                            command={handleChangeCommand}
                        />
                    </Box>
                    <Box>
                        <ThemedText>Secondary</ThemedText>
                        <ThemedRadioButtons
                            aria-label="gender"
                            options={[
                                { key: "female", text: "Female" },
                                { key: "male", text: "Male" },
                                { key: "other", text: "Other" },
                                { key: "disabled", text: "Disabled", disabled: true },
                            ]}
                            value={() => viewModel.getValue("gender")}
                            command={handleChangeCommand}
                        />
                    </Box>
                    <Box>
                        <ThemedText>Custom</ThemedText>
                        <ThemedRadioButtons
                            aria-label="gender"
                            options={[
                                { key: "female", text: "Female" },
                                { key: "male", text: "Male" },
                                { key: "other", text: "Other" },
                                { key: "disabled", text: "Disabled", disabled: true },
                            ]}
                            value={() => viewModel.getValue("gender")}
                            command={handleChangeCommand}
                        />
                    </Box>
                </AutoGrid>
                <Box mt={3}>
                    <AutoGrid rowGap={"10px"} columnGap={"10px"} desktopColumns={"1fr 1fr 1fr"}>
                        <ThemedCheckBox command={new NullCommand()} value={() => viewModel.getValue("terms")} displayName="Primary" />
                        <ThemedCheckBox command={new NullCommand()} value={() => viewModel.getValue("terms")} paletteColor={"secondary"} displayName="Secondary" />
                        <ThemedCheckBox command={new NullCommand()} value={() => viewModel.getValue("terms")} paletteColor={"purpleAccent"} displayName="Custom" />
                    </AutoGrid>
                </Box>
            </Box>
        </>
    );
});
