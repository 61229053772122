import { makeObservable, observable, observe, runInAction } from "mobx";
import { FieldType, HttpClient, KeyValuePair, Validation, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { TodoModel } from "./TodoModel";
import { ICommandAsync, RelayCommandAsync } from "../../Application/Commands";
import { APIClient, IKeyState } from "../../Application";
import { TodoItemModel } from "./TodoItemModel";
import { TodoItemViewModel } from "./TodoItemViewModel";
import axios from "axios";
import { Logger } from "../../index";

//extend viewmodel base and passing your model as the generic type
export class TodoViewModel extends ViewModelBase<TodoModel> {
    public httpClient = container.resolve(HttpClient);
    public errorMessage: string = "";
    public validMessage: string = "";

    public todoItemsViewModel = observable<TodoItemViewModel>([]);

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TodoModel(), false);
        //this.setValidator(new TestModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });

        const _ = this.getToDoList();
    }

    private childModelsObserverDispose = observe(this.model.todoItems, (childModelChanges: any) => {
        try {
            for (const addedChildModel of childModelChanges.added) {
                this.todoItemsViewModel.push(new TodoItemViewModel(addedChildModel));
            }

            for (const removedChildModel of childModelChanges.removed) {
                const childViewModelToRemove = this.todoItemsViewModel.find((vm) => vm.model.KEY === removedChildModel.KEY);

                if (childViewModelToRemove) {
                    this.todoItemsViewModel.remove(childViewModelToRemove);
                }
            }
        } catch (e: any) {
            Logger.logError("AddressListViewModel", "childModelsObserverDispose", e);
        }
    });

    private async getToDoList() {
        let response = await axios.get<[]>("https://jsonplaceholder.typicode.com/todos");
        for (const item of response.data) {
            runInAction(() => {
                let model = new TodoItemModel();
                model.setValue("title", (item as any).title);
                this.getModel.todoItems.push(model);
            });
        }
    }
}
