import React from "react";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
import { AdminRoutes } from "./Views/Admin/AdminRoutes";
import { PrivacyPolicyView } from "./Views/Legal/PrivacyPolicy/PrivacyPolicyView";
import { TermsAndConditions } from "./Views/Legal/Terms/TermsAndConditions";
import { HomeView } from "./Views/Home/HomeView";
import { Error } from "Views/Error/Error";
import { LayoutNoHeaderOrFooter } from "Views/Layouts/LayoutNoHeaderOrFooter";
import { CommonInjects } from "./Application/CommonInjects";
import { AppUrls } from "./AppUrls";
import { ColoursFluentUI } from "./Application/Examples/ColoursFluentUI";
import { Blank } from "./Components/Blank";
import { Todo } from "./Views/Todos/Todo";
import { Route, Route as ReactRoute, Routes } from "react-router-dom";
import { DebugRouter } from "./Components/DebugRouter";
import { ThemedLoader } from "./Styles/ThemedPrimitives/Style1/ThemedLoader";
import LoginHomeView from "./Views/Login/LoginHomeView";
import { DefaultLayout, DefaultLayoutNoFooter } from "./Views/Layouts";
import { SplitLayout } from "./Views/Layouts/SplitLayout";
import { PreviewView } from "./Views/Generated/PreviewPage";
import { BlankView } from "./Views/BlankView";
import { DashboardView } from "Views/Dashboard/DashboardView";
import { YourhomeView } from "./Views/Yourhome/YourhomeView";
import { BuildprogressView } from "./Views/Buildprogress/BuildprogressView";
import { SalesprogressView } from "./Views/Salesprogress/SalesprogressView";
import { ChoicesView } from "./Views/Choices/ChoicesView";
import { DocumentListView } from "./Views/Documents/DocumentListView";
import { CustomercareView } from "./Views/Customercare/CustomercareView";
import { StyleSheetView } from "./Application/Examples/StyleSheetView";
import { YourPurchaseView } from "Views/Admin/YourPurchase/YourPurchaseView";
import { BuildStageAdminView } from "Views/Admin/BuildStageAdmin/BuildStageAdminView";
import { IdleTimerContainer } from "Components/IdleContainer/IdleTimerView";
const SamplesView = React.lazy(() => import("./Views/Samples/SamplesView"));
const TestPageView = React.lazy(() => import("./Application/Examples/TestPage"));
const LogoutView = React.lazy(() => import("Views/Logout/LogoutView"));
const PageBuilderView = React.lazy(() => import("Views/PageBuilder/PageBuilderView"));

export const AppRoutes: React.FC = () => {
    return (
        <>
            <IdleTimerContainer>
                <DebugRouter>
                    <Routes>
                        <Route path={"/"} element={<DefaultLayoutNoFooter />}>
                            <Route index element={<DashboardView />} />
                            <Route path={AppUrls.Client.YourHome} element={<YourhomeView />} />
                            <Route path={AppUrls.Client.BuildProgress} element={<BuildprogressView />} />
                            <Route path={AppUrls.Client.SalesProgress} element={<SalesprogressView />} />
                            <Route path={AppUrls.Client.Documents} element={<DocumentListView />} />
                            <Route path={AppUrls.Client.Choices + "*"} element={<ChoicesView />} />
                            <Route path={AppUrls.Client.CustomerCare + "*"} element={<CustomercareView />} />
                            <Route path={AppUrls.Client.SalesStageAdmin + "*"} element={<YourPurchaseView />} />
                            <Route path={AppUrls.Client.BuildStageAdmin + "*"} element={<BuildStageAdminView />} />
                            <Route path={"/stylesheet"} element={<StyleSheetView />} />
                            <Route path={"/admin/*"} element={<AdminRoutes />} />

                            <Route
                                path={"/samples/*"}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <SamplesView />
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path={AppUrls.Client.PrivacyPolicy}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <PrivacyPolicyView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Terms}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <TermsAndConditions />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Test}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <TestPageView />
                                    </React.Suspense>
                                }
                            />

                            <Route path={AppUrls.Client.Todo} element={<Todo />} />
                            <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                            <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />
                            <Route path={AppUrls.Client.Theme} element={<ColoursFluentUI />} />
                        </Route>
                        <Route element={<SplitLayout />}>
                            <Route path={"/account/*"} element={<LoginHomeView />} />
                            {/* <Route
                            path={AppUrls.Client.Account.Logout}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <LogoutView onClose={function (): void {
                                        throw new Error("Function not implemented.");
                                    } } />
                                </React.Suspense>
                            }
                        /> */}
                        </Route>

                        <Route element={<LayoutNoHeaderOrFooter />}>
                            <Route path="preview/*" element={<PreviewView />} />
                            <Route path="blank" element={<BlankView />} />
                        </Route>

                        <Route element={<DefaultLayoutNoFooter />}>
                            <Route path={"/application/*"} element={<DashboardView />} />
                            <Route
                                path={AppUrls.Client.PageBuilder}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <PageBuilderView />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        {/*404 Route*/}
                        <ReactRoute
                            path={"*"}
                            element={
                                <main style={{ padding: "1rem" }}>
                                    <p>There's nothing here!</p>
                                </main>
                            }
                        />
                    </Routes>
                </DebugRouter>
            </IdleTimerContainer>
            <CommonInjects />
        </>
    );
};
